import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import { EyeOutlined } from '@ant-design/icons'
import { message, Button, Pagination, Table } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'

import globalStore from 'store/index'
import WebService from 'services/admin-users/web-services.service'

const WebServiceIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.adminUsers.webServices.index
	const [webServices, setWebServices] = useState(null)
	const { organizationId } = useParams()

	const [tableDataLoading, setTableDataLoading] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.adminUsers.webServices.index
			setTableDataLoading(true)
			await WebService.index({ pageState, organizationId })
			setTableDataLoading(false)
			const { web_services } = pageState
			if (web_services) {
				setWebServices(web_services)
				return
			}

			message.error('Failed to load web Service')
		}

		if (organizationId) fetchData()
	}, [organizationId])

	const {
		webServicesMeta,
		// displayType = "table",
	} = pageState

	const repaginate = async (page, pageSize) => {
		const tempState = {}

		setTableDataLoading(true)
		if (organizationId)
			await WebService.index({ pageState: tempState, page, pageSize, organizationId })

		setTableDataLoading(false)

		const { web_services, webServicesMeta } = tempState

		if (web_services) {
			setWebServices(web_services)
			pageState.webServicesMeta = webServicesMeta
			return
		}
	}

	const { total, page: currentPage, page_size: pageSize } = webServicesMeta || {}

	const pagination = (
		<Pagination
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const handleFitmentClick = (webServicesId) => {
		if (!webServicesId) return null
		history.push(`/admin-users/organizations/${organizationId}/api-web-services/${webServicesId}`)
	}

	const renderWebServices = () => {
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Type',
				dataIndex: 'type',
				key: 'type',
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'right',
				render: (action, row) => {
					return (
						<div className='icon-box'>
							<Button type='link' onClick={() => handleFitmentClick(row.id)}>
								<EyeOutlined />
							</Button>
						</div>
					)
				},
			},
		]
		return (
			<div style={{ margin: '10px 0px' }}>
				<Table
					columns={columns}
					dataSource={webServices}
					loading={tableDataLoading}
					pagination={false}
				/>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div
						className='action-buttons top'
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '20px',
						}}></div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
					}}>
					{pagination}
				</div>
				{renderWebServices()}
				{pagination}
			</div>
		)
	}

	const { API_USERS_WEB_SERVICE_INDEX_STATUS } = pageState

	// if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('offer-view'))
	// 	return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_WEB_SERVICE_INDEX_STATUS,
					data: webServices,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(WebServiceIndexPage)
