import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'

import { Table, message, Select, Button, DatePicker, Switch } from 'antd'

import BudgetHeadsService from 'services/users/budget-heads.service'
import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import FilterForm from 'components/budget-analysis/FilterForm'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const { Column, ColumnGroup } = Table

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const showOnlyColumnsOptions = ['monthly', 'quartely', 'halfyearly', 'yearly']

const costComparisonIndex = [
	{
		dataIndex: 'planned_value',
		label: 'Planned',
	},
	{
		dataIndex: 'actual_value',
		label: 'Actual',
	},
	{
		dataIndex: 'unutilized',
		label: 'Unutilized',
	},
	{
		dataIndex: 'utilized_percentage',
		label: 'Utilized %',
	},
]

const countComparisonIndex = [
	{
		dataIndex: 'planned_unit',
		label: 'Planned',
	},
	{
		dataIndex: 'actual_unit',
		label: 'Actual',
	},
	{
		dataIndex: 'unutilized_unit',
		label: 'Unutilized',
	},
	{
		dataIndex: 'utilized_unit_percentage',
		label: 'Utilized %',
	},
]

let budgetHeadNames = []

const BudgetUtilizationsDataPage = (props) => {
	const [tableLoading, setTableLoading] = useState(false)
	const [showOnlyColumns, setShowOnlyColumns] = useState(showOnlyColumnsOptions)
	const [tableDataSource, setTableDataSource] = useState([])
	const [loadingExportButton, setLoadingExportButton] = useState(false)
	const [filterValue, setFilterValue] = useState({})
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [checked, setCheckedValue] = useState(true)
	const pageState = globalStore.ui.configuration.budgetHeads.budgetUtilizationsData

	let comparisonIndex = checked ? costComparisonIndex : countComparisonIndex

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		globalStore.siderCollapsed = true
		fetchBudgetUtilizationData(dateRange)
		return () => {
			globalStore.siderCollapsed = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const { data } = pageState

		if (!data) return
		setTableDataSource(getTableDataSource(data, null, dateRange[0].format('YYYY')))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checked])

	const { data, API_USERS_BUDGET_UTILIZATIONS_DATA_STATUS } = pageState

	const fetchBudgetUtilizationData = async (dateRange) => {
		const { criteria } = filterValue
		const postData = {
			criteria: criteria?.criteria,
			start_date: dateRange[0].startOf('month').format('DD-MM-YYYY'),
			end_date: dateRange[1].endOf('month').format('DD-MM-YYYY'),
		}

		const pageState = globalStore.ui.configuration.budgetHeads.budgetUtilizationsData

		setTableLoading(true)
		await BudgetHeadsService.budgetUtilizationData({ pageState, postData })
		setTableLoading(false)

		const { data, serverStatus, dataErrors } = pageState

		if (serverStatus.status !== 200) {
			message.error('Oops!! something went wrong')
			return
		}

		if (dataErrors) {
			message.error(dataErrors[0] || "Budget utilization data couldn't be fetched.")
			return
		}

		setTableDataSource(getTableDataSource(data, null, dateRange[0].format('YYYY')))
	}

	useEffect(() => {
		const { data } = pageState

		if (!data) return

		setTableDataSource(getTableDataSource(data, null, dateRange[0].format('YYYY')))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageState])

	const handlePeriodChange = (range) => {
		if (range) {
			setDateRange(range)
			fetchBudgetUtilizationData(range)
		}
	}

	// TODO: Optimise this method
	const getTableDataSource = (
		data,
		parentBudgetHead,
		selectedStartYear = dateRange[0].format('YYYY')
	) => {
		if (!Array.isArray(data)) return []
		let dataSource = []

		data.forEach((d) => {
			const { children, data } = d
			const getBudgetHead = (index) => {
				// if (index !== 0) return null
				if (parentBudgetHead) return `${parentBudgetHead} > ${data.name}`

				return data.name
			}

			const rows = comparisonIndex.map((ci, index) => {
				if (index === 0) {
					budgetHeadNames.push({
						text: data.name,
						value: data.name,
					})
				}
				return {
					key: data.id,
					budgetHead: getBudgetHead(index),
					comparisonIndex: ci.label,
					apr: parseFloat(data[selectedStartYear]?.apr[ci.dataIndex] || 0)?.toFixed(2),
					may: parseFloat(data[selectedStartYear]?.may[ci.dataIndex] || 0)?.toFixed(2),
					jun: parseFloat(data[selectedStartYear]?.jun[ci.dataIndex] || 0)?.toFixed(2),
					q1: (
						parseFloat(
							!checked
								? data[selectedStartYear]?.jun[ci.dataIndex]
								: data[selectedStartYear]?.apr[ci.dataIndex] +
										data[selectedStartYear]?.may[ci.dataIndex] +
										data[selectedStartYear]?.jun[ci.dataIndex]
						) || 0
					)?.toFixed(2),
					jul: parseFloat(data[selectedStartYear]?.jul[ci.dataIndex] || 0)?.toFixed(2),
					aug: parseFloat(data[selectedStartYear]?.aug[ci.dataIndex] || 0)?.toFixed(2),
					sep: parseFloat(data[selectedStartYear]?.sep[ci.dataIndex] || 0)?.toFixed(2),

					q2: (
						parseFloat(
							!checked
								? data[selectedStartYear]?.sep[ci.dataIndex]
								: data[selectedStartYear]?.jul[ci.dataIndex] +
										data[selectedStartYear]?.aug[ci.dataIndex] +
										data[selectedStartYear]?.sep[ci.dataIndex]
						) || 0
					)?.toFixed(2),
					firstHalfYear: (
						parseFloat(
							!checked
								? data[selectedStartYear]?.sep[ci.dataIndex]
								: data[selectedStartYear]?.apr[ci.dataIndex] +
										data[selectedStartYear]?.may[ci.dataIndex] +
										data[selectedStartYear]?.jun[ci.dataIndex] +
										data[selectedStartYear]?.jul[ci.dataIndex] +
										data[selectedStartYear]?.aug[ci.dataIndex] +
										data[selectedStartYear]?.sep[ci.dataIndex]
						) || 0
					)?.toFixed(2),
					oct: parseFloat(data[selectedStartYear]?.oct[ci.dataIndex] || 0)?.toFixed(2),
					nov: parseFloat(data[selectedStartYear]?.nov[ci.dataIndex] || 0)?.toFixed(2),
					dec: parseFloat(data[selectedStartYear]?.dec[ci.dataIndex] || 0)?.toFixed(2),
					q3: (
						parseFloat(
							!checked
								? data[selectedStartYear]?.dec[ci.dataIndex]
								: data[selectedStartYear]?.oct[ci.dataIndex] +
										data[selectedStartYear]?.nov[ci.dataIndex] +
										data[selectedStartYear]?.dec[ci.dataIndex]
						) || 0
					)?.toFixed(2),
					jan: parseFloat(data[selectedStartYear + 1]?.jan[ci.dataIndex] || 0)?.toFixed(2),
					feb: parseFloat(data[selectedStartYear + 1]?.feb[ci.dataIndex] || 0)?.toFixed(2),
					mar: parseFloat(data[selectedStartYear + 1]?.mar[ci.dataIndex] || 0)?.toFixed(2),
					q4: (
						parseFloat(
							!checked
								? data[selectedStartYear + 1]?.mar[ci.dataIndex]
								: data[selectedStartYear + 1]?.jan[ci.dataIndex] +
										data[selectedStartYear + 1]?.feb[ci.dataIndex] +
										data[selectedStartYear + 1]?.mar[ci.dataIndex]
						) || 0
					)?.toFixed(2),
					secondHalfYear: (
						parseFloat(
							!checked
								? data[selectedStartYear]?.mar[ci.dataIndex]
								: data[selectedStartYear]?.oct[ci.dataIndex] +
										data[selectedStartYear]?.nov[ci.dataIndex] +
										data[selectedStartYear]?.dec[ci.dataIndex] +
										data[selectedStartYear]?.jan[ci.dataIndex] +
										data[selectedStartYear]?.feb[ci.dataIndex] +
										data[selectedStartYear]?.mar[ci.dataIndex]
						) || 0
					)?.toFixed(2),
					year: (
						parseFloat(
							!checked
								? data[selectedStartYear + 1]?.mar[ci.dataIndex]
								: data[selectedStartYear]?.apr[ci.dataIndex] +
										data[selectedStartYear]?.may[ci.dataIndex] +
										data[selectedStartYear]?.jun[ci.dataIndex] +
										data[selectedStartYear]?.jul[ci.dataIndex] +
										data[selectedStartYear]?.aug[ci.dataIndex] +
										data[selectedStartYear]?.sep[ci.dataIndex] +
										data[selectedStartYear]?.oct[ci.dataIndex] +
										data[selectedStartYear]?.nov[ci.dataIndex] +
										data[selectedStartYear]?.dec[ci.dataIndex] +
										data[selectedStartYear + 1]?.jan[ci.dataIndex] +
										data[selectedStartYear + 1]?.feb[ci.dataIndex] +
										data[selectedStartYear + 1]?.mar[ci.dataIndex]
						) || 0
					)?.toFixed(2),
				}
			})

			dataSource.push(
				...rows,
				...getTableDataSource(children, data.name, dateRange[0].format('YYYY'))
			)
		})

		return dataSource
	}

	const handleFilterChange = ({ criteria, start_date, end_date }) => {
		setFilterValue({ criteria, start_date, end_date })
	}

	const handleExport = async () => {
		const tempState = {}

		setLoadingExportButton(true)
		await BudgetHeadsService.export({
			pageState: tempState,
			postData: filterValue,
		})
		setLoadingExportButton(false)

		const { dataErrors } = tempState

		if (Array.isArray(dataErrors)) {
			message.error(dataErrors[0])
			return
		}

		message.success(
			'Export started. Kindly check export page in Report Center to see exported budget head'
		)
	}
	const handleSwitchChange = (checkedValue) => {
		setCheckedValue(checkedValue)
	}

	const renderContents = () => {
		const renderTable = () => {
			if (showOnlyColumns.length <= 0) {
				return
			}

			return (
				<Table
					dataSource={tableDataSource}
					pagination={{
						defaultPageSize: 12,
					}}
					bordered
					loading={tableLoading}
					scroll={{
						x: 'max-content',
					}}
					className='budget-utilizations-table'
					style={{
						margin: '20px 0px',
					}}>
					<Column
						title='Budget Head'
						dataIndex='budgetHead'
						key='budgetHead'
						fixed={true}
						width={220}
						filters={budgetHeadNames}
						onFilter={(value, record) => record.budgetHead.includes(value)}
						render={(value, record) => {
							if (record.comparisonIndex === 'Planned') {
								return value
							}
							return null
						}}
					/>
					<Column
						title='Comparison Index'
						dataIndex='comparisonIndex'
						key='comparisonIndex'
						fixed={true}
						width={130}
						filters={comparisonIndex.map((ci) => ({
							text: ci.label,
							value: ci.label,
						}))}
						onFilter={(value, record) => {
							return record.comparisonIndex.includes(value)
						}}
					/>
					{(showOnlyColumns.includes('monthly') || showOnlyColumns.includes('quartely')) && (
						<ColumnGroup title='H1(Apr-Sep)'>
							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q1(AMJ)'>
									<Column title='Apr' dataIndex='apr' key='apr' style={{ background: 'red' }} />
									<Column title='May' dataIndex='may' key='may' />
									<Column title='Jun' dataIndex='jun' key='jun' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q1' key='q1' className='colored-bg' />
							)}

							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q2(JAS)'>
									<Column title='Jul' dataIndex='jul' key='jul' />
									<Column title='Aug' dataIndex='aug' key='aug' />
									<Column title='Sep' dataIndex='sep' key='sep' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q2' key='q2' className='colored-bg' />
							)}
						</ColumnGroup>
					)}
					{showOnlyColumns.includes('halfyearly') && (
						<Column
							title='H1 Yearly Total'
							dataIndex='firstHalfYear'
							key='firstHalfYear'
							className='colored-bg'
						/>
					)}
					{(showOnlyColumns.includes('monthly') || showOnlyColumns.includes('quartely')) && (
						<ColumnGroup title='H2(Oct-Mar)'>
							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q3(OND)'>
									<Column title='Oct' dataIndex='oct' key='oct' />
									<Column title='Nov' dataIndex='nov' key='nov' />
									<Column title='Dec' dataIndex='dec' key='dec' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q3' key='q3' className='colored-bg' />
							)}

							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q4(JFM)'>
									<Column title='Jan' dataIndex='jan' key='jan' />
									<Column title='Feb' dataIndex='feb' key='feb' />
									<Column title='Mar' dataIndex='mar' key='mar' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q4' key='q4' className='colored-bg' />
							)}
						</ColumnGroup>
					)}
					{showOnlyColumns.includes('halfyearly') && (
						<Column
							title='H2 Yearly Total'
							dataIndex='secondHalfYear'
							key='secondHalfYear'
							className='colored-bg'
						/>
					)}
					{showOnlyColumns.includes('yearly') && (
						<Column title='Year' dataIndex='year' key='year' className='colored-bg-dark' />
					)}
				</Table>
			)
		}

		return (
			<div>
				{networkOnly({
					status: API_USERS_BUDGET_UTILIZATIONS_DATA_STATUS,
					data: data,
					render: renderTable,
				})}
			</div>
		)
	}

	const renderPageContent = () => {
		const filteredOptions = showOnlyColumnsOptions.filter((o) => !showOnlyColumns.includes(o))

		return (
			<StyledPageContent>
				<div>
					<FilterForm
						dateRange={dateRange}
						setDateRange={setDateRange}
						type='budget-utilization-data'
						onFilterChange={handleFilterChange}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 20,
						marginTop: 20,
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Period</div>
						<DatePicker.RangePicker
							picker='month'
							format='YYYY MMM'
							defaultValue={dateRange}
							onChange={handlePeriodChange}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Count</div>
						<Switch defaultChecked onChange={handleSwitchChange} />
						<div>Cost</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-between',
							alignItems: 'center',
							gap: 20,
						}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: 10,
							}}>
							<div>Show only</div>
							<div>
								<Select
									mode='multiple'
									value={showOnlyColumns}
									style={{ minWidth: 350 }}
									onChange={setShowOnlyColumns}>
									{filteredOptions.map((item) => (
										<Select.Option key={item} value={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</div>
						</div>
						<div>
							<Button type='primary' ghost loading={loadingExportButton} onClick={handleExport}>
								Export
							</Button>
						</div>
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures?.length &&
		!['smart-budget'].every((featureKey) => accessibleFeatures.includes(featureKey))
	)
		return <Redirect to='/' />

	return (
		<div className='page-content budget_utilizations-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_UTILIZATIONS_DATA_STATUS,
					data: data,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetUtilizationsDataPage)
