import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import { EyeOutlined } from '@ant-design/icons'
import { message, Button, Pagination, Table } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'

import globalStore from 'store/index'
import APiLogsService from 'services/users/api-logs.service'

const APILogsIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.apiLogs.index
	const [apiLogs, setApiLogs] = useState(null)

	const [tableDataLoading, setTableDataLoading] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.apiLogs.show
			setTableDataLoading(true)
			await APiLogsService.index({ pageState })
			setTableDataLoading(false)
			const { api_logs } = pageState
			if (api_logs) {
				setApiLogs(api_logs)
				return
			}

			message.error('Failed to load apiLogs')
		}

		fetchData()
	}, [])

	const {
		apiLogsMeta,
		// displayType = "table",
	} = pageState

	const repaginate = async (page, pageSize, email) => {
		const tempState = {}

		setTableDataLoading(true)

		await APiLogsService.index({ pageState: tempState, page, pageSize })

		setTableDataLoading(false)

		const { apiLogs, apiLogsMeta } = tempState

		if (apiLogs) {
			setApiLogs(apiLogs)
			pageState.apiLogsMeta = apiLogsMeta
			return
		}
	}

	const { total, page: currentPage, page_size: pageSize } = apiLogsMeta || {}

	const pagination = (
		<Pagination
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const handleFitmentClick = (apiLogsId) => {
		if (!apiLogsId) return null
		history.push(`/api-log/${apiLogsId}`)
	}

	const renderApiLogs = () => {
		const columns = [
			{
				title: 'Name',
				dataIndex: 'api_service_name',
				key: 'api_service_name',
			},
			{
				title: 'Log Type',
				dataIndex: 'loggable_type',
				key: 'loggable_type',
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'right',
				render: (action, row) => {
					return (
						<div className='icon-box'>
							<Button type='link' onClick={() => handleFitmentClick(row.id)}>
								<EyeOutlined />
							</Button>
						</div>
					)
				},
			},
		]
		return (
			<div style={{ margin: '10px 0px' }}>
				<Table
					columns={columns}
					dataSource={apiLogs}
					loading={tableDataLoading}
					pagination={false}
				/>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div
						className='action-buttons top'
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '20px',
						}}></div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
					}}>
					{pagination}
				</div>
				{renderApiLogs()}
				{pagination}
			</div>
		)
	}

	const { API_USERS_API_LOGS_INDEX_STATUS } = pageState

	// if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('offer-view'))
	// 	return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_API_LOGS_INDEX_STATUS,
					data: apiLogs,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(APILogsIndexPage)
