import React, { useEffect, useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import { toPng } from 'html-to-image'
import download from 'downloadjs'
import { InboxOutlined, UploadOutlined, CameraOutlined, DownOutlined } from '@ant-design/icons'

import {
	message,
	Button,
	Alert,
	Upload,
	Popconfirm,
	Select,
	Modal,
	Input,
	Dropdown,
	Menu,
	Table,
} from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import StyledFitmentDetails from 'components/common/styled-components/StyledFitmentDetails'
import SystemValidationBox from 'components/SystemValidationBox'
import SystemValidationCollapse from 'components/SystemValidationCollapse'
import ApprovalChainStatusesBox from 'components/ApprovalChainStatusesBox'
//import IncentiveDetails from "components/IncentiveDetails"
import SSConfiguration from 'components/ss/Configuration'
import Comments from 'components/Comments'
import FieldsWithCTC from 'components/FieldsWithCTC'
import Address from 'components/Address'
// import HikeCard from "components/HikeCard"
import CompensationTable from 'components/CompensationTable'
import ApplicableApprovalChains from 'components/ApplicableApprovalChains'
import Guidelines from 'components/Guidelines'
import FitmentDocumentsList from 'components/FitmentDocumentsList'
import FitmentCharts from 'components/FitmentCharts'
import DocusignSettings from 'components/DocusignSettings'

import FitmentsService from 'services/users/fitments.service'
import ApprovalChainsService from 'services/users/approval-chains.service'
import { staleWhileRevalidate } from 'utils/render-strategies'

import globalStore from 'store/index'
import CandidateCard from 'components/CandidateCard'
import JobDetails from 'components/JobDetails'
import RefreshButton from 'components/RefreshButton'

import StyledFitmentItemCard from 'components/common/styled-components/StyledFitmentItemCard'

const FitmentsShowPage = () => {
	const pageState = globalStore.ui.fitments.show
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	const currentOrganization = globalStore.currentOrganization

	const { fitmentId } = useParams()
	const [fitment, setFitment] = useState(null)
	const [cancelLoading, setCancelLoading] = useState(false)
	const [validateSalaryLoading, setValidateSalaryLoading] = useState(false)
	const [fileList, setFileList] = useState([])
	const [documentType, setDocumentType] = useState(null)
	const [fileUploading, setFileUploading] = useState(false)
	const [assignLoading, setAssignLoading] = useState(false)
	const [assignSalaryLoading, setAssignSalaryLoading] = useState(false)
	const [commentButtonLoading, setCommentButtonLoading] = useState(false)
	const [generateOfferLetterLoading, setGenerateOfferLetterLoading] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const [popConfirmVisible, setPopConfirmVisible] = useState(false)
	const [comment, setComment] = useState(null)
	const [exportAuditLog, setExportAuditLog] = useState(false)
	// const [syncingOfferLetter, setSyncingOfferLetter] = useState(false)
	// const [documentSyncing, setDocumentSyncing] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.fitments.show

			await FitmentsService.show({ pageState, fitmentId })

			const { fitment } = pageState

			if (!fitment) {
				message.error('Failed to load fitments')
				return
			}

			setFitment(fitment)
		}

		fetchData()
	}, [fitmentId])

	const getOfferDraftFrontendURL = async () => {
		const tempState = {}
		await FitmentsService.downloadOfferDraft({
			pageState: tempState,
			fitmentId,
		})

		const { redirectUrlErrors } = tempState
		if (redirectUrlErrors) {
			const errorMessage = redirectUrlErrors[0] || 'Oops!! something went wrong'
			message.error(errorMessage)
			return
		}

		const url = `${process.env.REACT_APP_FRONTEND_HOST}/fitments/${fitment.id}/download-draft-letter`

		window.open(url, '_blank', 'noopener,noreferrer')
	}

	const handleGenerateDraftLetter = async () => {
		const tempState = {}
		await FitmentsService.generateDraftLetter({ pageState: tempState, fitmentId })
		const { message: messages, fitmentErrors } = tempState
		if (fitmentErrors) {
			message.error(fitmentErrors[0])
			return
		}
		message.success(messages)
	}

	const refresh = async () => {
		await FitmentsService.refresh({ pageState, fitmentId })
		const { fitment } = pageState

		if (!fitment) return
		setFitment(fitment)
	}

	const openCommentModal = () => {
		setModalVisible(true)
		setPopConfirmVisible(false)
	}

	const cancelFitment = async () => {
		if (!comment) {
			message.error("Reason couldn't be blank")
			return
		}

		const pageState = {}
		const postData = {
			comment,
		}

		setCancelLoading(true)
		await FitmentsService.cancel({ pageState, fitmentId, values: postData })
		setCancelLoading(false)

		const { fitment } = pageState

		if (!fitment || fitment.status !== 'cancelled') {
			message.error('Failed to cancel fitment')
			return
		}

		setModalVisible(false)
		message.success('Fitment cancelled.')
		setComment('')
		setFitment(fitment)
	}

	const assignApprovalChain = async () => {
		const pageState = {}

		setAssignLoading(true)
		await FitmentsService.assignApprovalChain({ pageState, fitmentId })
		setAssignLoading(false)

		const { fitment, fitmentErrors } = pageState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Oops!! something went wrong')
			return
		}

		setFitment(fitment)
		message.success('Approval chain assigned.')
	}

	const assignSalaryApprovalChain = async () => {
		const pageState = {}

		setAssignSalaryLoading(true)
		await FitmentsService.assignSalaryApprovalChain({ pageState, fitmentId })
		setAssignSalaryLoading(false)

		const { fitment, fitmentErrors } = pageState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Oops!! something went wrong')
			return
		}

		setFitment(fitment)
		message.success('Approval chain assigned.')
	}

	const validateSalary = async () => {
		const pageState = {}

		setValidateSalaryLoading(true)
		await FitmentsService.validateSalary({ pageState, fitmentId })
		setValidateSalaryLoading(false)

		const { fitment } = pageState

		if (!fitment) {
			message.error("Salary couldn't be validated")
			return
		}

		if (fitment.status !== 'validated') {
			message.error("Salary couldn't be validated. Please check errors")
			return
		}

		message.success("Fitment's salary validated.")
		setFitment(fitment)
	}

	const handleComment = async (comment) => {
		if (!comment) {
			message.error("Comment couldn't be blank")
			return
		}

		const tempState = {}

		const postData = {
			fitment: {
				comment,
			},
		}

		setCommentButtonLoading(true)
		await FitmentsService.comment({
			pageState: tempState,
			fitmentId,
			values: postData,
		})
		setCommentButtonLoading(false)

		const { fitment, fitmentErrors } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || "Comment couldn't be submitted.")
			return
		}

		message.success('Comment submitted.')
		globalStore.ui.fitments.show.fitment = fitment
		setFitment(fitment)
	}

	const uploadFiles = async () => {
		if (fileList.length !== 1) return
		if (!documentType) {
			message.error('Choose document type')
			return
		}

		const pageState = {}
		const formData = new FormData()

		formData.append('fitment_document[file]', fileList[0])
		formData.append('fitment_document[document_type]', documentType)
		setFileUploading(true)
		await FitmentsService.fitmentDocuments({ pageState, fitmentId, formData })
		setFileUploading(false)

		const { serverStatus, fitment } = pageState

		if (serverStatus.status !== 200) {
			message.error("Document couldn't be uploaded.")
			return
		}

		setFitment(fitment)
		setFileList([])
		setDocumentType(null)
		message.success('Document uploaded.')
	}

	const generateOfferLetter = async () => {
		const tempState = {}

		setGenerateOfferLetterLoading(true)
		await FitmentsService.generateOfferLetter({
			pageState: tempState,
			fitmentId,
		})
		setGenerateOfferLetterLoading(false)

		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Oops!! something went wrong.')
			return
		}

		message.success(messages)
	}

	const handleRefreshApprovalChain = async () => {
		const tempState = {}

		await ApprovalChainsService.refresh({ pageState: tempState, fitmentId })

		const { fitment, fitmentErrors } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || "Approval chains couldn't be refreshed.")
			return
		}

		globalStore.ui.fitments.show.fitment = fitment
		setFitment(fitment)
	}
	const handleRefreshSalaryApprovalChain = async () => {
		const tempState = {}

		await ApprovalChainsService.refreshSalaryApprovalChain({ pageState: tempState, fitmentId })

		const { fitment, fitmentErrors } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || "Salary Approval chains couldn't be refreshed.")
			return
		}

		globalStore.ui.fitments.show.fitment = fitment
		setFitment(fitment)
	}

	const handleAuditReport = async () => {
		const tempState = {}
		setExportAuditLog(true)
		await FitmentsService.auditReport({ pageState: tempState, fitmentId })
		setExportAuditLog(false)

		const { serverStatus } = tempState

		if (serverStatus?.status === 200) {
			message.destroy()
			message.info('Export audit report started')
			return
		}
	}

	// const handleSyncOfferLetter = async () => {
	// 	const tempState = {}

	// 	setSyncingOfferLetter(true)
	// 	await FitmentsService.syncOfferLetter({ pageState: tempState, fitmentId })
	// 	setSyncingOfferLetter(false)

	// 	const { serverStatus } = tempState

	// 	if (serverStatus?.status === 200) {
	// 		message.destroy()
	// 		message.info('Synced offer letter')
	// 		return
	// 	}
	// }

	// const handleDocumentSync = async () => {
	// const tempState = {}

	// setDocumentSyncing(true)
	// await FitmentsService.syncDocumentAddress({
	// 	pageState: tempState,
	// 	fitmentId: fitmentId,
	// })
	// setDocumentSyncing(false)

	// }

	const sendLetterTemplate = async () => {
		const tempState = {}
		await FitmentsService.sendLetter({ pageState: tempState, fitmentId })
		const { message: messages, fitmentErrors } = tempState
		if (fitmentErrors) {
			message.error(fitmentErrors[0])
			return
		}
		message.success(messages)
	}
	const generateLetterTemplate = async () => {
		const tempState = {}

		await FitmentsService.createLetter({
			pageState: tempState,
			fitmentId,
		})

		const { fitmentErrors, message: messages } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Oops!! something went wrong.')
			return
		}

		message.success(messages)
	}

	const getSalaryApprovalChainButton = (type) => (
		<Button
			key='submit-salary-approval-button'
			type={type}
			size='small'
			loading={assignSalaryLoading}
			onClick={assignSalaryApprovalChain}>
			Submit for Salary Approval
		</Button>
	)

	const getApprovalChainButton = (type) => (
		<Button
			key='submit-approval-button'
			type={type}
			size='small'
			disabled={
				fitment?.assignable_ids?.approval_chains_ids.length !== 1
			}
			loading={assignLoading}
			onClick={assignApprovalChain}>
			Submit for Approval
		</Button>
	)

	const renderTopActionButtons = () => {
		const { status, offer, applicable_letters_details, letters, assignable_ids } = fitment
		const additionalLetterDetails =
			letters &&
			applicable_letters_details &&
			applicable_letters_details.map((data) => {
				const getLetterWithSameId = letters?.find(
					(letterData) => letterData.letter_template_id === data.id
				)
				const returnList = {
					...data,
					frontend_download_url: getLetterWithSameId?.frontend_download_url,
				}
				return returnList
			})
		const assignable_approval_chain_ids = assignable_ids?.approval_chains_ids || []
		const viewButtonArray = []
		if (['validated', 'pending'].includes(status)) {
			viewButtonArray.push(
				<Menu.Item key='view-offer-letter-draft'>
					<Button
						key='offer-draft-view-button'
						type='link'
						onClick={getOfferDraftFrontendURL}
						ghost
						size='small'>
						Offer Letter Draft
					</Button>
				</Menu.Item>
			)
		}
		if (
			['approved', 'docusign_completed', 'docusign_inprogress'].includes(status) &&
			offer &&
			offer.frontend_download_url
		) {
			viewButtonArray.push(
				<Menu.Item key='view-offer-letter'>
					<a
						key='view-offer-letter-anchor'
						href={offer.frontend_download_url}
						target='_blank'
						rel='noopener noreferrer'>
						<Button type='link' size='small' ghost>
							Offer Letter
						</Button>
					</a>
				</Menu.Item>
			)

			if (offer.frontend_docusign_download_url) {
				viewButtonArray.push(
					<Menu.Item key='view-docusign-letter'>
						<a
							key='view-docusign-letter-anchor'
							href={offer.frontend_docusign_download_url}
							target='_blank'
							rel='noopener noreferrer'>
							<Button key='view-docusign-letter-button' type='link' size='small' ghost>
								Docusign Letter
							</Button>
						</a>
					</Menu.Item>
				)
			}
		}
		if (
			['approved', 'docusign_completed', 'docusign_inprogress'].includes(fitment?.status) &&
			Array.isArray(letters) &&
			letters.length
		) {
			viewButtonArray.push(
				<Menu.SubMenu
					key='additional-letter-template'
					title={
						<Button type='link' size='small' ghost>
							Additional Letter
						</Button>
					}>
					{additionalLetterDetails &&
						additionalLetterDetails.map((letterData, index) => (
							<Menu.Item key={index}>
								<a
									key={`additional-letter-data-${index}-anchor`}
									href={letterData.frontend_download_url}
									target='_blank'
									rel='noopener noreferrer'>
									<Button type='link' size='small' ghost>
										{letterData.name}
									</Button>
								</a>
							</Menu.Item>
						))}
				</Menu.SubMenu>
			)
		}
		const viewButton = <Menu>{viewButtonArray && viewButtonArray.map((data) => data)}</Menu>

		const actionButton = (
			<Menu>
				{['approved', 'docusign_completed', 'docusign_inprogress'].includes(fitment?.status) &&
					((Array.isArray(applicable_letters_details) && !!applicable_letters_details.length) ||
						(Array.isArray(letters) && !!letters.length)) && (
						<Menu.SubMenu
							key='additional-letter-template'
							title={
								<Button type='link' size='small' ghost>
									Additional Letter
								</Button>
							}>
							{Array.isArray(applicable_letters_details) && !!applicable_letters_details.length && (
								<Menu.Item key='generate-additional-letter-template'>
									<Button
										key='generate-additional-letter-template-button'
										type='link'
										ghost
										size='small'
										onClick={generateLetterTemplate}>
										Generate
									</Button>
								</Menu.Item>
							)}
							{Array.isArray(letters) && !!letters.length && (
								<Menu.Item key='send-additional-letter-template'>
									<Button
										key='send-additional-letter-template-button'
										type='link'
										ghost
										size='small'
										onClick={sendLetterTemplate}>
										Send
									</Button>
								</Menu.Item>
							)}
						</Menu.SubMenu>
					)}
				<Menu.SubMenu
					key='others'
					title={
						<Button type='link' size='small' ghost>
							Others
						</Button>
					}>
					{['validated', 'pending'].includes(status) && (
						<Menu.Item key='generate-offer-draft'>
							<Button
								key='generate-offer-draft-button'
								type='link'
								onClick={handleGenerateDraftLetter}
								ghost
								size='small'>
								Generate Offer Letter Draft
							</Button>
						</Menu.Item>
					)}

					{['approved', 'docusign_inprogress', 'docusign_completed'].includes(status) && (
						<Menu.Item key='generate-offer-letter'>
							<Button
								key='generate-offer-letter-button'
								type='link'
								ghost
								size='small'
								loading={generateOfferLetterLoading}
								onClick={generateOfferLetter}>
								Generate Offer Letter
							</Button>
						</Menu.Item>
					)}
					{['approved', 'docusign_completed'].includes(status) &&
						!(offer && offer.frontend_download_url) &&
						offer &&
						offer.frontend_download_url && (
							<Menu.Item key='re-generate-offer-letter'>
								<Button
									key='re-generate-offer-letter-button'
									type='link'
									ghost
									size='small'
									loading={generateOfferLetterLoading}
									onClick={generateOfferLetter}>
									Re-Generate Offer Letter
								</Button>
							</Menu.Item>
						)}
					<Menu.Item key='export-audit-log'>
						<Button
							key='export-audit-log-button'
							type='link'
							ghost
							size='small'
							onClick={handleAuditReport}
							loading={exportAuditLog}>
							{exportAuditLog ? 'Exporting Audit Log...' : 'Export Audit Log'}
						</Button>
					</Menu.Item>
					{['details_updated'].includes(status) && (
						<Menu.Item key='validate-salary'>
							<Button
								key='validate-salary-button'
								type='link'
								ghost
								size='small'
								loading={validateSalaryLoading}
								onClick={validateSalary}>
								Validate Salary
							</Button>
						</Menu.Item>
					)}
					{currentOrganization?.enable_fitment_approval &&
						['validated', 'in_progress'].includes(status) &&
						(fitment['approval_statuses'].length === 0 ||
							fitment['approval_statuses'][0]['status'] === 'pending') &&
						Object.keys(fitment['assignable_ids']).includes('offer_letter_templates_ids') &&
						fitment['assignable_ids']['offer_letter_templates_ids'].length !== 0 && (
							<Menu.Item
								key='submit-approval'
								disabled={
									assignable_approval_chain_ids.length !== 1 ||
									(fitment['fitment_documents'].length === 0 && fitment?.country === 'India')
								}>
								{getApprovalChainButton('link')}
							</Menu.Item>
						)}
					{currentOrganization?.enable_salary_approval && fitment.status === 'pending' && (
						<Menu.Item key='submit-salary-approval'>
							{getSalaryApprovalChainButton('link')}
						</Menu.Item>
					)}
					{accessibleFeatures.includes('fitment-cancellation') && (
						<Menu.Item key='cancel-fitment'>
							<Button
								key='cancel-fitment-button'
								type='link'
								size='small'
								loading={cancelLoading}
								onClick={() => setPopConfirmVisible(true)}
								ghost>
								Cancel Fitment
							</Button>
						</Menu.Item>
					)}
				</Menu.SubMenu>
			</Menu>
		)
		let buttons = [
			<Popconfirm
				key='pop-confirm'
				title='Are you sure you want to cancel this Fitment?'
				placement='bottomRight'
				onConfirm={openCommentModal}
				visible={popConfirmVisible}
				onCancel={() => setPopConfirmVisible(false)}
				okText='Yes'
				cancelText='No'>
				<Dropdown key='action' overlay={actionButton}>
					<Button>
						Actions <DownOutlined />
					</Button>
				</Dropdown>
			</Popconfirm>,
		]
		if (viewButtonArray.length) {
			buttons.unshift(
				<Dropdown key='view-action' overlay={viewButton}>
					<Button>
						View
						<DownOutlined />
					</Button>
				</Dropdown>
			)
		}
		if (
			![
				'in_progress',
				'approved',
				'docusign_inprogress',
				'docusign_completed',
				'docusign_declined',
			].includes(status)
		) {
			buttons.unshift(<RefreshButton key='refresh-button' type='primary' ghost refresh={refresh} />)
		}

		return buttons
	}

	const renderFlowErrors = () => {
		if (!fitment['flow_errors']) return null
		if (fitment['flow_errors'].length === 0) return null

		const issues = fitment['flow_errors'].map((fe, i) => (
			<Alert key={i} message={fe} type='error' showIcon style={{ margin: '10px 0px' }} />
		))

		return (
			<div className='card' style={{ marginBottom: 30 }}>
				{/* <h1>Issues</h1> */}
				{issues}
				{currentOrganization?.mandatory_documents_requied &&
					['validated'].includes(fitment.status) &&
					fitment['approval_statuses'].length === 0 &&
					fitment['fitment_documents'].length === 0 &&
					currentOrganization?.country_for_manadatory_document.includes(fitment?.country) && (
						<Alert
							message='Please submit documents to enable Submit for Approval'
							type='error'
							showIcon
							style={{ margin: '10px 0px' }}
						/>
					)}
			</div>
		)
	}

	const renderDocumentUploader = () => {
		const draggerProps = {
			accept: 'image/png,image/jpg,image/jpeg,application/pdf',
			onRemove: (file) => {
				const index = fileList.indexOf(file)
				const newFileList = fileList.slice()
				newFileList.splice(index, 1)

				setFileList(newFileList)
			},
			beforeUpload: (file) => {
				setFileList([file])
				// setFileList([...fileList, file])

				return false
			},
			fileList,
		}

		return (
			<div>
				<Upload.Dragger {...draggerProps}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>Click or drag file to this area to upload</p>
					<p className='ant-upload-hint'>
						Support for a single upload. Strictly prohibit from uploading non essential files.
					</p>
				</Upload.Dragger>
				<div className='upload-btn'>
					<Select
						style={{ width: 260, marginRight: 10 }}
						placeholder='Choose document type'
						onChange={setDocumentType}>
						{fitment.applicable_documents.map((dt, i) => (
							<Select.Option key={i} value={dt.name}>
								{dt.name}
							</Select.Option>
						))}
					</Select>

					<Button
						type='primary'
						disabled={fileList.length !== 1 || !documentType}
						loading={fileUploading}
						onClick={uploadFiles}>
						<UploadOutlined /> Upload Files
					</Button>
				</div>
			</div>
		)
	}

	const offeredBonus = () => {
		const { ss_configuration, incentive_configuration } = fitment
		if (!ss_configuration) return 'N/A'
		if (!incentive_configuration) return 'N/A'
		const { section_configurations } = ss_configuration

		const head_configurations = section_configurations
			.map((sc) => sc.head_configurations)
			//.flat() // flat method doesn't support in IE - https://stackoverflow.com/a/53022680/4650675
			.reduce((acc, val) => acc.concat(val), []) // Alternative of flat method that support IE
		const bonus_hc = head_configurations.find((hc) => hc.key === 'bonus')
		if (!bonus_hc) return 'N/A'
		return bonus_hc.computed_value
	}

	const captureScreenShot = (divId) => {
		const node = document.getElementById(divId)

		toPng(node)
			.then(function (dataUrl) {
				download(dataUrl, `${divId}.png`)
			})
			.catch(function (error) {
				console.error('oops, something went wrong while capturing screenshot!', error)
			})
	}

	const renderSSConfiguration = () => {
		const {
			ss_configuration,
			computed_salary_structure_for_display,
			incentive_configuration,
			disable_features,
		} = fitment
		if (globalStore.currentOrganization.payreview_salary_structure_enable)
			return (
				<StyledFitmentItemCard id='salary-structure' style={{ background: 'white' }}>
					<div className='header'>
						<h1>Salary Structure</h1>
						<Button shape='circle' onClick={() => captureScreenShot('salary-structure')}>
							<CameraOutlined />
						</Button>
					</div>
					<div style={{ textAlign: 'center' }}>
						<Table
							columns={[
								{
									title: 'Salary Head',
									dataIndex: 'salaryHead',
									key: 'salaryHead',
								},
								{
									title: 'Monthly',
									dataIndex: 'monthly',
									key: 'monthly',
									align: 'right',
								},
								{
									title: 'Annual',
									dataIndex: 'annual',
									key: 'annual',
									align: 'right',
								},
							]}
							dataSource={computed_salary_structure_for_display.map((data, index) => {
								const returnData = {
									key: index,
									salaryHead: data.display_name,
									monthly: data.monthly,
									annual: data.yearly,
								}
								return returnData
							})}
							pagination={false}
						/>
					</div>
					<br />
					<br />
				</StyledFitmentItemCard>
			)

		if (!ss_configuration) return null

		if (
			disable_features.includes('minimum_wage') &&
			disable_features.includes('salary_range') &&
			disable_features.includes('organization_minimum_salary') &&
			disable_features.includes('budget')
		) {
			return null
		}

		if (!incentive_configuration) {
			return (
				<StyledFitmentItemCard>
					<h1>Salary Structure</h1>
					<p>Cannot be calculated as incentive configuration has not been assigned</p>
					<br />
					<br />
				</StyledFitmentItemCard>
			)
		}

		return (
			<StyledFitmentItemCard id='salary-structure' style={{ background: 'white' }}>
				<div className='header'>
					<h1>Salary Structure</h1>
					<Button shape='circle' onClick={() => captureScreenShot('salary-structure')}>
						<CameraOutlined />
					</Button>
				</div>
				<SSConfiguration
					computedSalary={computed_salary_structure_for_display}
					ss_configuration={ss_configuration}
					mode='values'
				/>
				<br />
				<br />
			</StyledFitmentItemCard>
		)
	}

	const renderPageContent = () => {
		const salaryAdded = fitment.ctc !== 0

		const {
			approver_order,
			candidate_order,
			docusign_approver_emails,
			docusign_recipient_status,
			docusign_status,
			offer,
			password_require_for_docusign,
			candidate,
		} = fitment

		return (
			<StyledFitmentDetails>
				<div className='action-buttons top'>{renderTopActionButtons()}</div>
				{renderFlowErrors()}
				<CandidateCard
					{...fitment['candidate']}
					fitment={fitment}
					onSetFitment={setFitment}
					showTydy
				/>
				{offer?.frontend_download_url && fitment?.enable_features?.includes('docusign') && (
					<DocusignSettings
						approverOrder={approver_order}
						candidateOrder={candidate_order}
						docusignApproverEmails={docusign_approver_emails}
						docusignRecipientStatus={docusign_recipient_status}
						docusignStatus={docusign_status}
						fitmentId={fitmentId}
						onSetFitment={setFitment}
						passwordRequireForDocusign={password_require_for_docusign}
						candidate={candidate}
					/>
				)}
				<Guidelines fitment={fitment} onSetFitment={setFitment} />
				<FieldsWithCTC
					fitment={fitment}
					editable={
						accessibleFeatures.includes('offer-edit') &&
						fitment?.status !== 'approved' &&
						fitment?.status !== 'docusign_completed' &&
						fitment?.status !== 'docusign_inprogress'
					}
					incentiveConfigurationMsg={
						fitment['validation_status']?.incentive_configuration?.messages[0]
					}
					onSetFitment={setFitment}
				/>
				<Address
					fitment={fitment}
					editable={accessibleFeatures.includes('offer-edit') && fitment?.status !== 'approved'}
					onSetFitment={setFitment}
				/>
				{/* <HikeCard percentageHike={fitment.percentage_hike_fixed} /> */}
				{salaryAdded && <SystemValidationCollapse fitment={fitment} onSetFitment={setFitment} />}
				<FitmentCharts fitment={fitment} />
				{false && salaryAdded && (
					<SystemValidationBox
						validationStatus={fitment.fitment_validation}
						fitmentId={fitmentId}
						onSetFitment={setFitment}
					/>
				)}
				{salaryAdded && <CompensationTable fitment={fitment} offeredBonus={offeredBonus()} />}
				{/* <IncentiveDetails /> */}
				{/* {renderOfferLetterDetails()} */}
				{salaryAdded && renderSSConfiguration()}
				{currentOrganization?.enable_salary_approval && fitment.status === 'pending' && (
					<ApplicableApprovalChains
						fitmentId={fitmentId}
						salaryApprovalChains={fitment.applicable_salary_approval_chains_details}
					/>
				)}
				{currentOrganization?.enable_salary_approval && (
					<ApprovalChainStatusesBox
						approvalsId={fitment.id}
						approvalChainStatuses={fitment?.salary_approval_statuses}
						title='Salary Approval Chain'
						showRefresh
						refresh={handleRefreshSalaryApprovalChain}
						onSetData={setFitment}
						useApi='salary'>
						{Object.keys(fitment?.applicable_salary_approval_chains_details).length === 1
							? getSalaryApprovalChainButton('primary')
							: ''}
					</ApprovalChainStatusesBox>
				)}

				{currentOrganization?.enable_fitment_approval && salaryAdded && (
					<ApplicableApprovalChains
						fitmentId={fitmentId}
						approvalChains={fitment.applicable_approval_chains_details}
					/>
				)}
				{currentOrganization?.enable_fitment_approval && salaryAdded && (
					<ApprovalChainStatusesBox
						approvalsId={fitment.id}
						approvalChainStatuses={fitment?.approval_statuses}
						showRefresh
						refresh={handleRefreshApprovalChain}
						onSetData={setFitment}>
						{['validated', 'in_progress'].includes(fitment?.status) &&
						(fitment?.approval_statuses.length === 0 ||
							fitment['approval_statuses'][0]['status'] === 'pending') &&
						Object.keys(fitment['assignable_ids']).includes('offer_letter_templates_ids') &&
						fitment['assignable_ids']['offer_letter_templates_ids'].length !== 0 &&
						Object.keys(fitment?.applicable_approval_chains_details).length === 1
							? getApprovalChainButton('primary')
							: ''}
					</ApprovalChainStatusesBox>
				)}
				<JobDetails fitment={fitment} />
				<StyledFitmentItemCard>
					<div className='header'>
						<h1>
							Documents
							{/* <Button type="link" onClick={handleDocumentSync}>
            <RedoOutlined spin={documentSyncing} rotate={270} />
              </Button> */}
						</h1>
					</div>
					<FitmentDocumentsList documents={fitment.fitment_documents} setFitment={setFitment} />
					{renderDocumentUploader()}
				</StyledFitmentItemCard>
				<Comments
					commentAble
					comments={fitment.comments || []}
					commentButtonLoading={commentButtonLoading}
					onComment={handleComment}
				/>
			</StyledFitmentDetails>
		)
	}

	const { API_FITMENTS_SHOW_STATUS } = pageState

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('offer-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_FITMENTS_SHOW_STATUS,
					data: fitment,
					render: renderPageContent,
				})}
			</StyledBox>

			<Modal
				title='Reason'
				closable={false}
				maskClosable={false}
				visible={modalVisible}
				onOk={cancelFitment}
				okText='Submit'
				confirmLoading={cancelLoading}
				onCancel={() => setModalVisible(false)}>
				<Input.TextArea
					allowClear
					autoSize={{ minRows: 4 }}
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/>
			</Modal>
		</div>
	)
}

export default view(FitmentsShowPage)
