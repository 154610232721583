import React from 'react'
import { view } from '@risingstack/react-easy-state'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'

import ContentLoading from 'components/common/ContentLoading'

// Layouts
import AuthLayout from 'components/common/layouts/auth/Index'

// Error Pages
import Error404Page from 'pages/Error404'

// Auth Pages
import LoginPage from 'pages/Login'
import ChangePasswordPage from 'pages/ChangePassword'

// Super Admin Pages
import SuperAdminLoginPage from 'pages/admin-users/Login'
import SuperAdminDashboardPage from 'pages/admin-users/Dashboard'
import SuperAdminOrganizationIndexPage from 'pages/admin-users/organizations/Index'
import SuperAdminOrganizationShowPage from 'pages/admin-users/organizations/Show'
import SuperAdminOrganizationNewPage from 'pages/admin-users/organizations/New'
import SuperAdminOrganizationEditPage from 'pages/admin-users/organizations/Edit'
import SuperAdminOrganizationsUsersIndexPage from 'pages/admin-users/organizations/users/Index'
import SuperAdminOrganizationsUsersNewPage from 'pages/admin-users/organizations/users/New'
import SuperAdminOrganizationsUsersShowPage from 'pages/admin-users/organizations/users/Show'
import SuperAdminOrganizationsUsersEditPage from 'pages/admin-users/organizations/users/Edit'

// Pages
import DashboardPage from 'pages/Dashboard'

import OrganizationEditPage from 'pages/organization/Edit'
import OrganizationParametersPage from 'pages/organization/Parameters'

import AdditionalFitmentsDocumentIndexPage from 'pages/additional-fitment-document/Index'
import AdditionalFitmentsDocumentShowPage from 'pages/additional-fitment-document/Show'

import FitmentsIndexPage from 'pages/fitments/Index'
import FitmentsShowPage from 'pages/fitments/Show'
import FitmentsEditPage from 'pages/fitments/Edit'
import UpdateDesignationPage from 'pages/fitments/UpdateDesignation'

import FitmentFieldConfigurationsIndexPage from 'pages/fitment-fields-configurations/Index'
import FitmentFieldConfigurationsCreatePage from 'pages/fitment-fields-configurations/New'
import FitmentFieldConfigurationsShowPage from 'pages/fitment-fields-configurations/Show'
import FitmntFieldsConfigurationsEditPage from 'pages/fitment-fields-configurations/Edit'

import DocumentsIndexPage from 'pages/configuration/documents/Index'
import DocumentsCreatePage from 'pages/configuration/documents/New'
import DocumentsShowPage from 'pages/configuration/documents/Show'
import DocumentsEditPage from 'pages/configuration/documents/Edit'

import VendorsIndexPage from 'pages/configuration/vendors/Index'
import VendorsCreatePage from 'pages/configuration/vendors/New'
import VendorsShowPage from 'pages/configuration/vendors/Show'
import VendorsEditPage from 'pages/configuration/vendors/Edit'

import SalaryApprovalsApprovePage from 'pages/salary-approvals/Index'
import FitmentApprovalsApprovePage from 'pages/fitment-approvals/Approve'
// import FitmentApprovalsRejectPage from "pages/fitment-approvals/Reject"
// import FitmentApprovalsCommentsPage from "pages/fitment-approvals/Comments"

import RolesPage from 'pages/Roles'
import UsersIndexPage from 'pages/users/Index'
import UsersShowPage from 'pages/users/Show'
import UsersEditPage from 'pages/users/Edit'
import UsersNewPage from 'pages/users/New'
import FitmentConfigurationPage from 'pages/configuration/Fitment'
import IntegrationPage from 'pages/configuration/Integration'

import SSConfigurationsIndexPage from 'pages/configuration/ss-configurations/Index'
import SSConfigurationsShowPage from 'pages/configuration/ss-configurations/Show'
import SSConfigurationsEditPage from 'pages/configuration/ss-configurations/Edit'

import ControlFeaturesIndexPage from 'pages/configuration/control-features/Index'
import ControlFeaturesNewPage from 'pages/configuration/control-features/New'
import ControlFeaturesShowPage from 'pages/configuration/control-features/Show'
import ControlFeaturesEditPage from 'pages/configuration/control-features/Edit'

import OfferLetterTemplatesIndexPage from 'pages/configuration/offer-letter-templates/Index'
import OfferLetterTemplatesShowPage from 'pages/configuration/offer-letter-templates/Show'
import OfferLetterTemplatesEditPage from 'pages/configuration/offer-letter-templates/Edit'
import OfferLetterTemplatesNewPage from 'pages/configuration/offer-letter-templates/New'

import AdditionalLetterTemplatesIndexPage from 'pages/configuration/additional-letter-templates/Index'
import AdditionalLetterTemplatesShowPage from 'pages/configuration/additional-letter-templates/Show'
import AdditionalLetterTemplatesEditPage from 'pages/configuration/additional-letter-templates/Edit'
import AdditionalLetterTemplatesNewPage from 'pages/configuration/additional-letter-templates/New'

import EmailTemplatesIndexPage from 'pages/configuration/email-templates/Index'
import EmailTemplatesShowPage from 'pages/configuration/email-templates/Show'
import EmailTemplatesEditPage from 'pages/configuration/email-templates/Edit'
import EmailTemplatesNewPage from 'pages/configuration/email-templates/New'

import ApprovalChainsNewPage from 'pages/configuration/approval-chains/New'
import ApprovalChainsIndexPage from 'pages/configuration/approval-chains/Index'
import ApprovalChainsShowPage from 'pages/configuration/approval-chains/Show'
import ApprovalChainsEditPage from 'pages/configuration/approval-chains/Edit'

import CandidateEditPage from 'pages/candidates/Edit'

import SalaryRangesNewPage from 'pages/configuration/salary-ranges/New'
import SalaryRangesIndexPage from 'pages/configuration/salary-ranges/Index'
import SalaryRangesShowPage from 'pages/configuration/salary-ranges/Show'
import SalaryRangesEditPage from 'pages/configuration/salary-ranges/Edit'

import BudgetsNewPage from 'pages/configuration/budgets/New'
import BudgetsIndexPage from 'pages/configuration/budgets/Index'
import BudgetsShowPage from 'pages/configuration/budgets/Show'
import BudgetsEditPage from 'pages/configuration/budgets/Edit'

// Start work from here

import BudgetCriteriaHeadsNewPage from 'pages/configuration/budget-criteria-heads/New'
import BudgetCriteriaHeadsIndexPage from 'pages/configuration/budget-criteria-heads/Index'
import BudgetCriteriaHeadsShowPage from 'pages/configuration/budget-criteria-heads/Show'
import BudgetCriteriaHeadsEditPage from 'pages/configuration/budget-criteria-heads/Edit'

import BudgetCriteriaValuesNewPage from 'pages/configuration/budget-criteria-values/New'
import BudgetCriteriaValuesIndexPage from 'pages/configuration/budget-criteria-values/Index'
import BudgetCriteriaValuesShowPage from 'pages/configuration/budget-criteria-values/Show'
import BudgetCriteriaValuesEditPage from 'pages/configuration/budget-criteria-values/Edit'

import BudgetHeadsIndexPage from 'pages/configuration/budget-heads/Index'
import BudgetAnalysisPage from 'pages/configuration/budget-heads/BudgetAnalysis'
import BudgetEntryPage from 'pages/configuration/budget-heads/BudgetEntry'
// import BudgetPlanningPage from 'pages/configuration/budget-heads/BudgetPlanning'
import BudgetHeadsNewPage from 'pages/configuration/budget-heads/New'
import BudgetChildrenNewPage from 'pages/configuration/budget-heads/BudgetChildren'
import BudgetHeadsEditPage from 'pages/configuration/budget-heads/Edit'
import BudgetHeadsShowPage from 'pages/configuration/budget-heads/Show'
import BudgetUtilizationsDataPage from 'pages/configuration/budget-heads/BudgetUtilizationsData'
import RecruitmentStaffingReportPage from 'pages/configuration/budget-heads/RecruitmentStaffingReport'
import AddPositionPage from 'pages/configuration/budget-heads/AddPosition'
import UpdateEmployee from 'pages/configuration/budget-heads/UpdateEmployee'
import ResourcePlanPage from 'pages/configuration/budget-heads/ResourcePlan'

import BudgetValuesIndexPage from 'pages/configuration/budget-values/Index'
import BudgetValuesNewPage from 'pages/configuration/budget-values/New'
import BudgetValuesShowPage from 'pages/configuration/budget-values/Show'
import BudgetValuesEditPage from 'pages/configuration/budget-values/Edit'

import BudgetApprovalWorkflowPage from 'pages/configuration/budget-approval-workflow/BudgetApprovalWorkflow'

import BudgetUtilizationsIndexPage from 'pages/configuration/budget-utilizations/Index'
import BudgetUtilizationsNewPage from 'pages/configuration/budget-utilizations/New'
import BudgetUtilizationsShowPage from 'pages/configuration/budget-utilizations/Show'
import BudgetUtilizationsEditPage from 'pages/configuration/budget-utilizations/Edit'
//End Work Here

import MinimumWagesIndexPage from 'pages/configuration/minimum-wages/Index'
import MinimumWagesShowPage from 'pages/configuration/minimum-wages/Show'
import MinimumWagesEditPage from 'pages/configuration/minimum-wages/Edit'
import MinimumWagesNewPage from 'pages/configuration/minimum-wages/New'

import OrganizationMinimumSalariesIndexPage from 'pages/configuration/organization-minimum-salaries/Index'
import OrganizationMinimumSalariesShowPage from 'pages/configuration/organization-minimum-salaries/Show'
import OrganizationMinimumSalariesEditPage from 'pages/configuration/organization-minimum-salaries/Edit'
import OrganizationMinimumSalariesNewPage from 'pages/configuration/organization-minimum-salaries/New'

import IncentivesNewPage from 'pages/configuration/incentives/New'
import IncentivesIndexPage from 'pages/configuration/incentives/Index'
import IncentivesShowPage from 'pages/configuration/incentives/Show'
import IncentivesEditPage from 'pages/configuration/incentives/Edit'

import AccessGroupsIndexPage from 'pages/access-groups/Index'
import AccessGroupsShowPage from 'pages/access-groups/Show'
import AccessGroupsEditPage from 'pages/access-groups/Edit'
import AccessGroupsNewPage from 'pages/access-groups/New'

import ExportPage from 'pages/Export'
import ImportPage from 'pages/Import'

import LetterDownloadRedirector from 'redirectors/letters/Download'
import DocusignLettersDownloadRedirector from 'redirectors/docusign_letters/DocusignDownload'

import OfferDownloadRedirector from 'redirectors/offers/Download'
import DocusignOfferDownloadRedirector from 'redirectors/docusign_offers/DocusignDownload'
import DocumentDownloadRedirector from 'redirectors/documents/Download'
import ExportDownloadRedirector from 'redirectors/exports/Download'
import ImportDownloadRedirector from 'redirectors/imports/Download'
import OfferDraftDownloadRedirector from 'redirectors/offer-draft/Download'

import SmartRecruitersAuth from 'pages/SmartRecruitersAuth'

import PendingHireReviewApprovalIndexPage from 'pages/fitment-approvals/pending-hirereview-approval/Index'
import PendingHireReviewApprovalShowPage from 'pages/fitment-approvals/pending-hirereview-approval/Show'

import DesignationsIndexPage from 'pages/designations/Index'

import * as AuthHelper from 'helpers/auth.helper'
import PrivateRoute from './PrivateRoute'

import SettingsPage from 'pages/Settings'

import UserOrganizationEditPage from 'pages/users/organizations/Edit'

import CountryDetailsIndexPage from 'pages/configuration/country-details/Index'
import CountryDetailsNewPage from 'pages/configuration/country-details/New'
import CountryDetailsShowPage from 'pages/configuration/country-details/Show'
import CountryDetailsEditPage from 'pages/configuration/country-details/Edit'

import NotificationIndexPage from 'pages/users/notifications/Index'
import NotificationShowPage from 'pages/users/notifications/Show'
import HirereviewSsoLogin from 'pages/HirereviewSsoLogin'
import WorkForcePeriodIndex from 'pages/configuration/workforce-period/Index'
import WorkForcePeriodNew from 'pages/configuration/workforce-period/New'
import WorkForcePeriodShow from 'pages/configuration/workforce-period/Show'
import WorkForcePeriodEdit from 'pages/configuration/workforce-period/Edit'
import PositionOccupancyReportIndexPage from 'pages/configuration/position-occupancy-report/Index'
import PositionOccupancyReportEditPage from 'pages/configuration/position-occupancy-report/Edit'
import IncrementalChangeTimelinePage from 'pages/configuration/budget-heads/IncrementalChangeTimeline'
import BudgetMonitoringUtilizationReportPage from 'pages/configuration/budget-heads/BudgetMonitoringUtilizationReport'
import PositionTransitionAnalysisPage from 'pages/configuration/budget-heads/PositionTransitionAnalysis'
import RecruitmentVacancyReportPage from 'pages/configuration/budget-heads/RecruitmentVacancyReport'
import YOYSummary from 'pages/configuration/budget-heads/YOYSummary'
import OrgChart from 'pages/configuration/budget-heads/OrganizationChart'
import PendingBudgetApprovalIndexPage from 'pages/pending-budget-approval/Index'
import PendingBudgetApprovalShowPage from 'pages/pending-budget-approval/Show'

import AdminBudgetApprovalStatusIndexPage from 'pages/configuration/admin-budget-approval-status/Index'
import AdminBudgetApprovalStatusShowPage from 'pages/configuration/admin-budget-approval-status/Show'
import AdminBudgetApprovalStatusEditPage from 'pages/configuration/admin-budget-approval-status/Edit'
import WorkForceDashboard from 'pages/configuration/WorkForceDashboard'
import OrganizationConfigurationPage from 'pages/users/organizations/Configuration'
import PendingSalaryApprovalIndexPage from 'pages/pending-salary-approval/Index'
import PendingSalaryApprovalShowPage from 'pages/pending-salary-approval/Show'
import ConsentForm from 'pages/customer-integration/ConsentForm'

import APILogsIndexPage from 'pages/api-log/Index'
import APILogsShowPage from 'pages/api-log/Show'

import WebServiceIndexPage from 'pages/admin-users/organizations/api-web-service/Index'
import WebServiceShowPage from 'pages/admin-users/organizations/api-web-service/Show'
import WebServiceEditPage from 'pages/admin-users/organizations/api-web-service/Edit'

const Routes = () => {
	const location = useLocation()
	const history = useHistory()
	const params = queryString.parse(location.search)
	const { token, ...otherParams } = params
	if (!!token) {
		AuthHelper.setToken(token)

		const redirectUrl = [location.pathname, queryString.stringify(otherParams)]
			.filter((e) => !!e)
			.join('?')
		history.replace(redirectUrl)
		return <ContentLoading />
	}

	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<Route path='/'>
				<Switch>
					<Route
						exact
						path='/admin-users/login'
						component={() => (
							<AuthLayout title='Super Admin Login'>
								<SuperAdminLoginPage />
							</AuthLayout>
						)}
					/>
					<Route
						exact
						path='/login'
						component={() => (
							<AuthLayout title='Login'>
								<LoginPage />
							</AuthLayout>
						)}
					/>
					<Route
						exact
						path='/hirereview-sso-login'
						component={() => (
							<AuthLayout title='PayReview to Hirereview Login'>
								<HirereviewSsoLogin />
							</AuthLayout>
						)}
					/>
					<PrivateRoute
						exact
						path='/admin-users'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Super Admin Dashboard'
						component={() => <SuperAdminDashboardPage />}
					/>

					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/users/:userId/edit'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Update User Details'
						goBackLink='/admin-users/organizations/:organizationId/users/:userId'
						component={() => <SuperAdminOrganizationsUsersEditPage />}
					/>

					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/users/new'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Add User'
						goBackLink='/admin-users/organizations/:organizationId'
						component={() => <SuperAdminOrganizationsUsersNewPage />}
					/>

					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/users/:userId'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='User Details'
						goBackLink='/admin-users/organizations/:organizationId/users'
						component={() => <SuperAdminOrganizationsUsersShowPage />}
					/>

					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/users'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle="Organization's Users"
						goBackLink='/admin-users/organizations'
						component={() => <SuperAdminOrganizationsUsersIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/admin-users/organizations/new'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Create Organization'
						goBackLink='/admin-users/organizations'
						component={() => <SuperAdminOrganizationNewPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/edit'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Edit Organization'
						goBackLink='/admin-users/organizations/:organizationId'
						component={() => <SuperAdminOrganizationEditPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Organization Show'
						goBackLink='/admin-users/organizations'
						component={() => <SuperAdminOrganizationShowPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organizations'
						loginRoute='/admin-users/login'
						withAppLayout
						headerTitle='Organizations'
						component={() => <SuperAdminOrganizationIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/api-web-services'
						loginRoute='/admin-users/login'
						goBackLink='/admin-users/organizations/:organizationId'
						withAppLayout
						headerTitle='API Web Services'
						component={() => <WebServiceIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/api-web-services/:webServiceId'
						loginRoute='/admin-users/login'
						goBackLink='/admin-users/organizations/:organizationId'
						withAppLayout
						headerTitle='API Web Services'
						component={() => <WebServiceShowPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organizations/:organizationId/api-web-services/:webServiceId/edit'
						loginRoute='/admin-users/login'
						goBackLink='/admin-users/organizations/:organizationId'
						withAppLayout
						headerTitle='API Web Services'
						component={() => <WebServiceEditPage />}
					/>
					<PrivateRoute
						exact
						path='/change-password'
						withAppLayout
						headerTitle='Change Password'
						component={() => <ChangePasswordPage />}
					/>
					<PrivateRoute
						exact
						path='/settings'
						withAppLayout
						headerTitle='Settings'
						component={() => <SettingsPage />}
					/>
					<PrivateRoute
						exact
						path='/users/organizations/edit'
						withAppLayout
						headerTitle='Organizations'
						component={() => <UserOrganizationEditPage />}
					/>
					<PrivateRoute
						exact
						path='/users/organization-configuration'
						withAppLayout
						headerTitle='Organization Configuration'
						component={() => <OrganizationConfigurationPage />}
					/>
					<PrivateRoute
						exact
						path='/'
						withAppLayout
						headerTitle='Dashboard'
						component={() => <DashboardPage />}
					/>
					<PrivateRoute
						exact
						path='/api-log'
						withAppLayout
						headerTitle='API Log'
						component={() => <APILogsIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/api-log/:apiLogId'
						withAppLayout
						headerTitle='API Log'
						goBackLink='/api-log/:apiLogId'
						component={() => <APILogsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/edit-organization'
						withAppLayout
						headerTitle='Edit Organization'
						component={() => <OrganizationEditPage />}
					/>
					<PrivateRoute
						exact
						path='/admin-users/organization/parameters'
						withAppLayout
						headerTitle='Organization Parameters'
						component={() => <OrganizationParametersPage />}
					/>
					<PrivateRoute
						exact
						path='/fitments/:fitmentId'
						withAppLayout
						headerTitle='Fitment Details'
						goBackLink='/fitments'
						component={() => <FitmentsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/fitments/:fitmentId/edit'
						withAppLayout
						headerTitle='Fitment Update'
						goBackLink='/fitments/:fitmentId'
						component={() => <FitmentsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/fitments/:fitmentId/update-designation'
						withAppLayout
						headerTitle='Fitment Update'
						goBackLink='/fitments/:fitmentId'
						component={() => <UpdateDesignationPage />}
					/>
					<PrivateRoute
						exact
						path='/candidates/:candidateId/edit'
						withAppLayout
						headerTitle='Address Update'
						goBackLink='/fitments'
						component={() => <CandidateEditPage />}
					/>
					<Route
						exact
						path='/salary-approvals/:token/approve'
						component={() => <SalaryApprovalsApprovePage />}
					/>
					<Route
						exact
						path='/fitment-approvals/:token/approve'
						component={() => <FitmentApprovalsApprovePage />}
					/>
					<Route
						exact
						path='/fitment-approvals/:token/reject'
						component={() => <FitmentApprovalsApprovePage />}
					/>
					<Route
						exact
						path='/fitment-approvals/:token/comments'
						component={() => <FitmentApprovalsApprovePage />}
					/>
					<PrivateRoute
						exact
						path='/fitments'
						withAppLayout
						headerTitle='Shortlisted Candidate'
						component={() => <FitmentsIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/additional-fitment-document'
						withAppLayout
						headerTitle='Additional Fitments Document'
						component={() => <AdditionalFitmentsDocumentIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/additional-fitment-document/:fitmentId'
						withAppLayout
						headerTitle='Additional Fitments Document'
						goBackLink='/additional-fitment-document'
						component={() => <AdditionalFitmentsDocumentShowPage />}
					/>
					<PrivateRoute
						exact
						path='/offers/:offerUuid/download'
						withAppLayout
						headerTitle='Download Offer'
						component={() => <OfferDownloadRedirector />}
					/>
					<PrivateRoute
						exact
						path='/offers/:offerUuid/docusign_download'
						withAppLayout
						headerTitle='Download Docusign Offer'
						component={() => <DocusignOfferDownloadRedirector />}
					/>
					<PrivateRoute
						exact
						path='/letters/:letterUuid/download'
						withAppLayout
						headerTitle='Download Letters'
						component={() => <LetterDownloadRedirector />}
					/>
					<PrivateRoute
						exact
						path='/fitments/:letterUuid/download_docusign_letter'
						withAppLayout
						headerTitle='Download Docusign Letters'
						component={() => <DocusignLettersDownloadRedirector />}
					/>
					<PrivateRoute
						exact
						path='/fitments/:fitmentId/download-draft-letter'
						withAppLayout
						headerTitle='Download Offer Draft'
						component={() => <OfferDraftDownloadRedirector />}
					/>
					<PrivateRoute
						exact
						path='/fitment-documents/:documentUuid/download'
						withAppLayout
						headerTitle='Download Document'
						component={() => <DocumentDownloadRedirector />}
					/>
					<PrivateRoute
						exact
						path='/configuration/fitment-fields'
						withAppLayout
						headerTitle='Fitment Fields Configuration'
						component={() => <FitmentFieldConfigurationsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/fitment-fields/new'
						withAppLayout
						headerTitle='Fitment Fields Configuration'
						goBackLink='/configuration/fitment-fields'
						component={() => <FitmentFieldConfigurationsCreatePage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/fitment-fields/:fitmentFieldsConfigurationId'
						withAppLayout
						headerTitle='Fitment Fields Configuration Details'
						goBackLink='/configuration/fitment-fields'
						component={() => <FitmentFieldConfigurationsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/fitment-fields/:fitmentFieldsConfigurationId/edit'
						withAppLayout
						headerTitle='Update Fitment Fields Configuration'
						goBackLink='/configuration/fitment-fields/:fitmentFieldsConfigurationId'
						component={() => <FitmntFieldsConfigurationsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/documents'
						withAppLayout
						headerTitle='Documents'
						component={() => <DocumentsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/documents/new'
						withAppLayout
						headerTitle='Document'
						goBackLink='/configuration/documents'
						component={() => <DocumentsCreatePage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/documents/:documentId'
						withAppLayout
						headerTitle='Document Details'
						goBackLink='/configuration/documents'
						component={() => <DocumentsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/documents/:documentId/edit'
						withAppLayout
						headerTitle='Update Document'
						goBackLink='/configuration/documents/:documentId'
						component={() => <DocumentsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/vendors'
						withAppLayout
						headerTitle='Vendors'
						component={() => <VendorsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/vendors/new'
						withAppLayout
						headerTitle='Vendor'
						goBackLink='/configuration/vendors'
						component={() => <VendorsCreatePage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/vendors/:vendorId'
						withAppLayout
						headerTitle='Vendor Details'
						goBackLink='/configuration/vendors'
						component={() => <VendorsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/vendors/:vendorId/edit'
						withAppLayout
						headerTitle='Update Vendor'
						goBackLink='/configuration/vendors/:vendorId'
						component={() => <VendorsEditPage />}
					/>
					{/* country */}
					<PrivateRoute
						exact
						path='/configuration/country-details'
						withAppLayout
						headerTitle='Country Details'
						component={() => <CountryDetailsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/country-details/new'
						withAppLayout
						headerTitle='Create Country Details'
						goBackLink='/configuration/country-details'
						component={() => <CountryDetailsNewPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/country-details/:countryId'
						withAppLayout
						headerTitle='Country Details'
						goBackLink='/configuration/country-details'
						component={() => <CountryDetailsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/country-details/:countryId/edit'
						withAppLayout
						headerTitle='Update Country Details'
						goBackLink='/configuration/country-details/:countryId'
						component={() => <CountryDetailsEditPage />}
					/>
					{/*  */}
					<PrivateRoute
						exact
						path='/roles'
						withAppLayout
						headerTitle='Roles & Rights'
						component={() => <RolesPage />}
					/>
					<PrivateRoute
						exact
						path='/users/new'
						withAppLayout
						headerTitle='Create User'
						goBackLink='/users'
						component={() => <UsersNewPage />}
					/>
					<PrivateRoute
						exact
						path='/users/:userId/edit'
						withAppLayout
						headerTitle='Update User'
						goBackLink='/users/:userId'
						component={() => <UsersEditPage />}
					/>
					<PrivateRoute
						exact
						path='/users/:userId'
						withAppLayout
						headerTitle='User Details'
						goBackLink='/users'
						component={() => <UsersShowPage />}
					/>
					<PrivateRoute
						exact
						path='/users'
						withAppLayout
						headerTitle='User Mapping with Roles'
						component={() => <UsersIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/notifications'
						withAppLayout
						headerTitle='Notifications'
						component={() => <NotificationIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/notifications/:notificationId'
						withAppLayout
						headerTitle='Notifications'
						goBackLink='/notifications'
						component={() => <NotificationShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/fitment'
						withAppLayout
						headerTitle='Fitment Configuration'
						component={() => <FitmentConfigurationPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/integration'
						withAppLayout
						headerTitle='Integration'
						component={() => <IntegrationPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/salary-ranges/new'
						withAppLayout
						headerTitle='Create Salary Range'
						goBackLink='/configuration/salary-ranges'
						component={() => <SalaryRangesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/salary-ranges/:salaryRangeId/edit'
						withAppLayout
						headerTitle='Edit Salary Range'
						goBackLink='/configuration/salary-ranges/:salaryRangeId'
						component={() => <SalaryRangesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/salary-ranges/:salaryRangeId'
						withAppLayout
						headerTitle='Salary Range Details'
						goBackLink='/configuration/salary-ranges'
						component={() => <SalaryRangesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/salary-ranges'
						withAppLayout
						headerTitle='Salary Ranges'
						component={() => <SalaryRangesIndexPage />}
					/>
					{/* Budget Criteria */}

					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads/new'
						withAppLayout
						headerTitle='Create Organization Hierarchy'
						goBackLink='/configuration/budget-criteria-heads'
						component={() => <BudgetCriteriaHeadsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads/:budgetCriteriaHeadId/edit'
						withAppLayout
						headerTitle='Edit Organization Hierarchy'
						goBackLink='/configuration/budget-criteria-heads'
						component={() => <BudgetCriteriaHeadsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads/:budgetCriteriaHeadId'
						withAppLayout
						headerTitle='Organization Hierarchy Detail'
						goBackLink='/configuration/budget-criteria-heads'
						component={() => <BudgetCriteriaHeadsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads'
						withAppLayout
						headerTitle='Organization Hierarchy'
						component={() => <BudgetCriteriaHeadsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads/:budgetCriteriaHeadId/budget-criteria-values'
						withAppLayout
						headerTitle='Hierarchy Values'
						goBackLink='/configuration/budget-criteria-heads'
						component={() => <BudgetCriteriaValuesIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads/:budgetCriteriaHeadId/budget-criteria-values/new'
						withAppLayout
						headerTitle='Create Hierarchy Values'
						goBackLink='/configuration/budget-criteria-heads'
						component={() => <BudgetCriteriaValuesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-criteria-heads/:budgetCriteriaHeadId/budget-criteria-values/:budgetCriteriaValueId/edit'
						withAppLayout
						headerTitle='Edit Hierarchy Values'
						goBackLink='/configuration/budget-criteria-heads'
						component={() => <BudgetCriteriaValuesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-criteria-values/:budgetCriteriaValueId'
						withAppLayout
						headerTitle='Hierarchy Values Detail'
						goBackLink='/configuration/budget-criteria-values'
						component={() => <BudgetCriteriaValuesShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads'
						withAppLayout
						headerTitle='Budget Hierarchy'
						component={() => <BudgetHeadsIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/budget-analysis'
						withAppLayout
						headerTitle='Budget Heads'
						component={() => <BudgetAnalysisPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/budget-entry'
						withAppLayout
						headerTitle='Budget Entry'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetEntryPage />}
					/>
					{/* <PrivateRoute
						exact
						path='/configuration/budget-heads/budget-planning'
						withAppLayout
						headerTitle='Add Expense'
						component={() => <BudgetPlanningPage />}
					/> */}
					<PrivateRoute
						exact
						path='/configuration/budget-heads/new'
						withAppLayout
						headerTitle='Create Budget Heads'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetHeadsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/budget-utilization-data'
						withAppLayout
						headerTitle='Budget Utilizations'
						goBackLink='/'
						component={() => <BudgetUtilizationsDataPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/recruitment-staffing-report'
						withAppLayout
						headerTitle='Recruitment Staffing Report'
						component={() => <RecruitmentStaffingReportPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/position-occupancy-report'
						withAppLayout
						headerTitle='Position Occupancy Report'
						component={() => <PositionOccupancyReportIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/position-occupancy-report/:budgetValueId/edit'
						withAppLayout
						headerTitle='Position Occupancy Report'
						goBackLink='/configuration/budget-heads/position-occupancy-report'
						component={() => <PositionOccupancyReportEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/incremental-change-timeline'
						withAppLayout
						headerTitle='Incremental Change Timeline'
						component={() => <IncrementalChangeTimelinePage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/budget-monitoring-utilization-report'
						withAppLayout
						headerTitle='Budget Monitoring & Utilization Report'
						component={() => <BudgetMonitoringUtilizationReportPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/position-transition-analysis'
						withAppLayout
						headerTitle='Position Transition Analysis'
						component={() => <PositionTransitionAnalysisPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/recruitment-vacancy-report'
						withAppLayout
						headerTitle='Recruitment Vacancy Report'
						component={() => <RecruitmentVacancyReportPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/yoy-summary'
						withAppLayout
						headerTitle='YOY Summary'
						component={() => <YOYSummary />}
					/>
					<PrivateRoute
						exact
						path='/position-reportings/organization-chart'
						withAppLayout
						headerTitle='Organization Chart'
						component={() => <OrgChart />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/add-position'
						withAppLayout
						headerTitle='Add Position'
						component={() => <AddPositionPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/workforce/:budgetHeadId/budget-values/new'
						withAppLayout
						headerTitle='Add Position'
						component={() => <BudgetValuesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/budget-approval-workflow'
						withAppLayout
						headerTitle='Budget Approval Workflow'
						component={() => <BudgetApprovalWorkflowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/admin-budget-approval-status'
						withAppLayout
						headerTitle='Admin Budget Approval Status'
						component={() => <AdminBudgetApprovalStatusIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/admin-budget-approval-status/:budgetTentativeValueId'
						withAppLayout
						headerTitle='Admin Budget Approval Status'
						goBackLink='/configuration/admin-budget-approval-status'
						component={() => <AdminBudgetApprovalStatusShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/admin-budget-approval-status/:budgetTentativeValueId/edit'
						withAppLayout
						headerTitle='Admin Budget Approval Status'
						goBackLink='/configuration/admin-budget-approval-status/:budgetTentativeValueId'
						component={() => <AdminBudgetApprovalStatusEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/add-employee'
						withAppLayout
						headerTitle='Update Employee'
						component={() => <UpdateEmployee />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/resource-plan'
						withAppLayout
						headerTitle='Resource Planning'
						component={() => <ResourcePlanPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/workforce/:budgetHeadId/budget-utilizations/new'
						withAppLayout
						headerTitle='Add Employee'
						component={() => <BudgetUtilizationsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget_children'
						withAppLayout
						headerTitle='Create Budget Head Children'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetChildrenNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/edit'
						withAppLayout
						headerTitle='Update Budget Heads'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetHeadsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId'
						withAppLayout
						headerTitle='Budget Head Detail'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetHeadsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-values'
						withAppLayout
						headerTitle='Planned Value'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetValuesIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-values/new'
						withAppLayout
						headerTitle='Create Planned Value'
						goBackLink='/configuration/budget-heads/:budgetHeadId/budget-values'
						component={() => <BudgetValuesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-values/:budgetValueId'
						withAppLayout
						headerTitle='Planned Value Detail'
						goBackLink='/configuration/budget-heads/:budgetHeadId/budget-values'
						component={() => <BudgetValuesShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-values/:budgetValueId/edit'
						withAppLayout
						headerTitle='Update Planned Value'
						goBackLink='/configuration/budget-heads/:budgetHeadId/budget-values/:budgetValueId'
						component={() => <BudgetValuesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-utilizations'
						withAppLayout
						headerTitle='Actual Value'
						goBackLink='/configuration/budget-heads'
						component={() => <BudgetUtilizationsIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-utilizations/new'
						withAppLayout
						headerTitle='Create Actual Value'
						goBackLink='/configuration/budget-heads/:budgetHeadId/budget-utilizations'
						component={() => <BudgetUtilizationsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-utilizations/:budgetUtilizationId'
						withAppLayout
						headerTitle='Actual Value Detail'
						goBackLink='/configuration/budget-heads/:budgetHeadId/budget-utilizations'
						component={() => <BudgetUtilizationsShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budget-heads/:budgetHeadId/budget-utilizations/:budgetUtilizationId/edit'
						withAppLayout
						headerTitle='Update Actual Value'
						goBackLink='/configuration/budget-heads/:budgetHeadId/budget-utilizations/:budgetUtilizationId'
						component={() => <BudgetUtilizationsEditPage />}
					/>
					{/* Budget Criteria */}

					{/* Budget */}

					<PrivateRoute
						exact
						path='/configuration/budgets/new'
						withAppLayout
						headerTitle='Create Budget'
						goBackLink='/configuration/budgets'
						component={() => <BudgetsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budgets/:budgetId/edit'
						withAppLayout
						headerTitle='Edit Budget'
						goBackLink='/configuration/budgets/:budgetId'
						component={() => <BudgetsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/budgets/:budgetId'
						withAppLayout
						headerTitle='Budget Details'
						goBackLink='/configuration/budgets'
						component={() => <BudgetsShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/budgets'
						withAppLayout
						headerTitle='Candidate Budgets'
						component={() => <BudgetsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/minimum-wages/new'
						withAppLayout
						headerTitle='Create Minimum Wage'
						goBackLink='/configuration/minimum-wages'
						component={() => <MinimumWagesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/minimum-wages/:minimumWageId/edit'
						withAppLayout
						headerTitle='Edit Minimum Wage'
						goBackLink='/configuration/minimum-wages/:minimumWageId'
						component={() => <MinimumWagesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/minimum-wages/:minimumWageId'
						withAppLayout
						headerTitle='Minimum Wage Details'
						goBackLink='/configuration/minimum-wages/'
						component={() => <MinimumWagesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/minimum-wages'
						withAppLayout
						headerTitle='Minimum Wages'
						component={() => <MinimumWagesIndexPage />}
					/>

					{/* Organization minimum salaries */}
					<PrivateRoute
						exact
						path='/configuration/organization-minimum-salaries/new'
						withAppLayout
						headerTitle='Create Organization Minimum Salary'
						goBackLink='/configuration/organization-minimum-salaries'
						component={() => <OrganizationMinimumSalariesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/organization-minimum-salaries/:organizationMinimumSalaryId/edit'
						withAppLayout
						headerTitle='Edit Organization Minimum Salary'
						goBackLink='/configuration/organization-minimum-salaries/:organizationMinimumSalaryId'
						component={() => <OrganizationMinimumSalariesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/organization-minimum-salaries/:organizationMinimumSalaryId'
						withAppLayout
						headerTitle='Organization Minimum Salary Details'
						goBackLink='/configuration/organization-minimum-salaries'
						component={() => <OrganizationMinimumSalariesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/organization-minimum-salaries'
						withAppLayout
						headerTitle='Organization Minimum Salaries'
						component={() => <OrganizationMinimumSalariesIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/incentives/new'
						withAppLayout
						headerTitle='Create Incentive '
						goBackLink='/configuration/incentives'
						component={() => <IncentivesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/incentives/:incentiveId/edit'
						withAppLayout
						headerTitle='Edit Incentive '
						goBackLink='/configuration/incentives/:incentiveId'
						component={() => <IncentivesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/incentives/:incentiveId'
						withAppLayout
						headerTitle='Incentive  Details'
						goBackLink='/configuration/incentives'
						component={() => <IncentivesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/incentives'
						withAppLayout
						headerTitle='Incentives'
						component={() => <IncentivesIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/approval-chains/'
						withAppLayout
						headerTitle='Approval Chains'
						component={() => <ApprovalChainsIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/approval-chains/new'
						withAppLayout
						headerTitle='Create Approval Chain'
						goBackLink='/configuration/approval-chains'
						component={() => <ApprovalChainsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/approval-chains/:approvalChainId/edit'
						withAppLayout
						headerTitle='Edit Approval Chain'
						goBackLink='/configuration/approval-chains/:approvalChainId'
						component={() => <ApprovalChainsEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/approval-chains/:approvalChainId'
						withAppLayout
						headerTitle='Approval Chain Details'
						goBackLink='/configuration/approval-chains'
						component={() => <ApprovalChainsShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/approval-chains'
						withAppLayout
						headerTitle='Approval Chains'
						component={() => <ApprovalChainsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/ss-configurations/:ssConfigurationId/edit'
						withAppLayout
						headerTitle='Edit Salary Structure Configuration'
						goBackLink={`/configuration/ss-configurations/:ssConfigurationId`}
						component={() => <SSConfigurationsEditPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/ss-configurations/:ssConfigurationId'
						withAppLayout
						headerTitle='Salary Structure Configuration'
						goBackLink='/configuration/ss-configurations'
						component={() => <SSConfigurationsShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/ss-configurations'
						withAppLayout
						headerTitle='Salary Structure Configurations'
						component={() => <SSConfigurationsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/offer-letter-templates/new'
						withAppLayout
						headerTitle='Create Offer Letter Template'
						goBackLink='/configuration/offer-letter-templates'
						component={() => <OfferLetterTemplatesNewPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/offer-letter-templates/:offerLetterTemplateId/edit'
						withAppLayout
						headerTitle='Edit Offer Letter Template'
						goBackLink={`/configuration/offer-letter-templates/:offerLetterTemplateId`}
						component={() => <OfferLetterTemplatesEditPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/offer-letter-templates/:offerLetterTemplateId'
						withAppLayout
						headerTitle='Offer Letter Template'
						goBackLink='/configuration/offer-letter-templates'
						component={() => <OfferLetterTemplatesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/offer-letter-templates'
						withAppLayout
						headerTitle='Offer Letter Templates'
						component={() => <OfferLetterTemplatesIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/email-templates/new'
						withAppLayout
						headerTitle='Create Email Template'
						goBackLink='/configuration/email-templates'
						component={() => <EmailTemplatesNewPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/additional-letter-templates/new'
						withAppLayout
						headerTitle='Create Additional Letter Template'
						goBackLink='/configuration/additional-letter-templates'
						component={() => <AdditionalLetterTemplatesNewPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/additional-letter-templates/:letterTemplateId/edit'
						withAppLayout
						headerTitle='Edit Additional Letter Template'
						goBackLink={`/configuration/additional-letter-templates/:letterTemplateId`}
						component={() => <AdditionalLetterTemplatesEditPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/additional-letter-templates/:letterTemplateId'
						withAppLayout
						headerTitle='Additional Letter Template'
						goBackLink='/configuration/additional-letter-templates'
						component={() => <AdditionalLetterTemplatesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/additional-letter-templates'
						withAppLayout
						headerTitle='Additional Letter Templates'
						component={() => <AdditionalLetterTemplatesIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/email-templates/new'
						withAppLayout
						headerTitle='Create Email Template'
						goBackLink='/configuration/email-templates'
						component={() => <EmailTemplatesNewPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/email-templates/:emailTemplateId/edit'
						withAppLayout
						headerTitle='Edit Email Template'
						goBackLink={`/configuration/email-templates/:emailTemplateId`}
						component={() => <EmailTemplatesEditPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/email-templates/:emailTemplateId'
						withAppLayout
						headerTitle='Email Template'
						goBackLink='/configuration/email-templates'
						component={() => <EmailTemplatesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/email-templates'
						withAppLayout
						headerTitle='Email Templates'
						component={() => <EmailTemplatesIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/auth/smartrecruiters/callback'
						component={() => <SmartRecruitersAuth />}
					/>

					<PrivateRoute
						exact
						path='/configuration/ss-configurations'
						withAppLayout
						headerTitle='Salary Structure Configurations'
						component={() => <SSConfigurationsIndexPage />}
					/>

					{/* Control Features */}
					<PrivateRoute
						exact
						path='/configuration/control-features'
						withAppLayout
						headerTitle='Control Features'
						component={() => <ControlFeaturesIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/control-features/new'
						withAppLayout
						headerTitle='Create Control Feature'
						goBackLink='/configuration/control-features'
						component={() => <ControlFeaturesNewPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/control-features/:controlFeatureId/edit'
						withAppLayout
						headerTitle='Edit Control Feature'
						goBackLink='/configuration/control-features/'
						component={() => <ControlFeaturesEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/control-features/:controlFeatureId'
						withAppLayout
						headerTitle='Control Feature'
						goBackLink='/configuration/control-features'
						component={() => <ControlFeaturesShowPage />}
					/>

					<PrivateRoute
						exact
						path='/exports'
						withAppLayout
						headerTitle='Exports'
						component={() => <ExportPage />}
					/>

					<PrivateRoute
						exact
						path='/exports/:exportId/download'
						withAppLayout
						headerTitle='Download Export'
						component={() => <ExportDownloadRedirector />}
					/>

					<PrivateRoute
						exact
						path='/imports'
						withAppLayout
						headerTitle='Imports'
						component={() => <ImportPage />}
					/>

					<PrivateRoute
						exact
						path='/imports/:importId/download'
						withAppLayout
						headerTitle='Download Export'
						component={() => <ImportDownloadRedirector />}
					/>

					<PrivateRoute
						exact
						path='/access-groups/new'
						withAppLayout
						headerTitle='Create Access Group'
						goBackLink='/access-groups'
						component={() => <AccessGroupsNewPage />}
					/>
					<PrivateRoute
						exact
						path='/access-groups/:accessGroupId/edit'
						withAppLayout
						headerTitle='Edit Access Group'
						goBackLink='/access-groups/:accessGroupId'
						component={() => <AccessGroupsEditPage />}
					/>

					<PrivateRoute
						exact
						path='/access-groups/:accessGroupId'
						withAppLayout
						headerTitle='Access Group Details'
						goBackLink='/access-groups'
						component={() => <AccessGroupsShowPage />}
					/>

					<PrivateRoute
						exact
						path='/access-groups'
						withAppLayout
						headerTitle='Access Groups'
						component={() => <AccessGroupsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/fitment-approvals/pending-hirereview-approvals'
						withAppLayout
						headerTitle='Pending HireReview Approvals'
						component={() => <PendingHireReviewApprovalIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/fitment-approvals/pending-hirereview-approvals/:id'
						withAppLayout
						headerTitle='Pending HireReview Approval'
						goBackLink='/fitment-approvals/pending-hirereview-approvals'
						component={() => <PendingHireReviewApprovalShowPage />}
					/>
					<PrivateRoute
						exact
						path='/pending-salary-approvals'
						withAppLayout
						headerTitle='Pending Salary Approvals'
						component={() => <PendingSalaryApprovalIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/pending-salary-approvals/:salaryApprovalId'
						withAppLayout
						headerTitle='Pending Salary Approval'
						goBackLink='/pending-salary-approvals'
						component={() => <PendingSalaryApprovalShowPage />}
					/>
					<PrivateRoute
						exact
						path='/budget-approvals/pending-budget-approvals'
						withAppLayout
						headerTitle='Pending Budget Approvals'
						component={() => <PendingBudgetApprovalIndexPage />}
					/>
					<PrivateRoute
						exact
						path='/budget-approvals/pending-budget-approvals/:pendingApprovalId'
						withAppLayout
						headerTitle='Pending Budget Approval'
						goBackLink='/budget-approvals/pending-budget-approvals'
						component={() => <PendingBudgetApprovalShowPage />}
					/>
					<PrivateRoute
						exact
						path='/budget-approvals/:token/approve'
						headerTitle='Pending Budget Approval'
						component={() => <PendingBudgetApprovalShowPage />}
					/>
					<PrivateRoute
						exact
						path='/designations'
						withAppLayout
						headerTitle='Designations'
						component={() => <DesignationsIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/workforce-period'
						withAppLayout
						headerTitle='WorkForce Period'
						component={() => <WorkForcePeriodIndex />}
					/>
					<PrivateRoute
						exact
						path='/configuration/workforce-dashboard'
						withAppLayout
						headerTitle='WorkForce Dashboard'
						component={() => <WorkForceDashboard />}
					/>
					<PrivateRoute
						exact
						path='/configuration/admin-workforce-approval-status'
						withAppLayout
						headerTitle='Admin WorkForce Approval Status'
						component={() => <AdminBudgetApprovalStatusIndexPage />}
					/>

					<PrivateRoute
						exact
						path='/configuration/admin-workforce-approval-status/:budgetTentativeValueId'
						withAppLayout
						headerTitle='Admin WorkForce Approval Status'
						goBackLink='/configuration/admin-workforce-approval-status'
						component={() => <AdminBudgetApprovalStatusShowPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/admin-workforce-approval-status/:budgetTentativeValueId/edit'
						withAppLayout
						headerTitle='Admin WorkForce Approval Status'
						goBackLink='/configuration/admin-workforce-approval-status/:budgetTentativeValueId'
						component={() => <AdminBudgetApprovalStatusEditPage />}
					/>
					<PrivateRoute
						exact
						path='/configuration/workforce-period/new'
						withAppLayout
						headerTitle='WorkForce Period'
						goBackLink='/configuration/workforce-period'
						component={() => <WorkForcePeriodNew />}
					/>
					<PrivateRoute
						exact
						path='/configuration/workforce-period/:workforcePeriodID'
						withAppLayout
						headerTitle='WorkForce Period'
						goBackLink='/configuration/workforce-period'
						component={() => <WorkForcePeriodShow />}
					/>
					<PrivateRoute
						exact
						path='/configuration/workforce-period/:workforcePeriodID/edit'
						withAppLayout
						headerTitle='WorkForce Period'
						goBackLink='/configuration/workforce-period/:workforcePeriodID'
						component={() => <WorkForcePeriodEdit />}
					/>
					<PrivateRoute
						exact
						path='/consent-form'
						headerTitle='Consent Form'
						component={() => <ConsentForm />}
					/>

					<Route path='/' component={Error404Page} />
				</Switch>
			</Route>
		</div>
	)
}

export default view(Routes)
