import moment from 'moment'

export const getCurrentFiscalDateRange = () => {
	const today = moment()
	const year = today.year()

	// April 1 current year, March 31 next year
	const start = moment(`${year}-04-01`, 'YYYY-MM-DD')
	const end = moment(`${year + 1}-03-31`, 'YYYY-MM-DD')

	// If today's before April 1, shift everything back one year
	if (today.isBefore(start)) {
		start.subtract(1, 'year')
		end.subtract(1, 'year')
	}

	return [start, end]
}
