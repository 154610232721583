import React from 'react'
import styled from 'styled-components'
// import { RedoOutlined } from '@ant-design/icons'

import { Color } from 'constants/theme'
// import FitmentsService from 'services/users/fitments.service'
// import { message } from 'antd'
// import globalStore from 'store/index'

const StyledCard = styled.div`
	margin-bottom: 30px;
	padding: 16px 30px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 5px;
	color: ${Color.textColor};
	box-shadow: 2px 2px 20px 2px #dadddf82;
	background: ${Color.secondaryColor};
	border: 1px solid rgba(0, 0, 0, 0.1);
	align-items: flex-start;
	& > .top {
		display: flex;
		justify-content: space-between;
		width: 100%;
		& > .name {
			font-size: 2.5em;
			margin: 0px;
			font-weight: bold;
		}
	}
	& > .details {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		width: 100%;
		& > .email {
			font-size: 1.5em;
			margin: 0px 0px 10px 0px;
			font-weight: 300;
		}
		& > .external-id {
			font-weight: 300;
		}
	}
	& .status {
		display: flex;
		align-items: baseline;
		text-transform: capitalize;
		& .status-circle {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: gray;
			margin-right: 5px;
			margin-left: 5px;
		}
		& .status-circle.orange {
			background: orange;
		}
		& .status-circle.green {
			background: green;
		}
	}
`

// const getStatusColor = (status) => {
// 	if (status === 'completed') {
// 		return 'green'
// 	}
// 	if (status === 'pending') {
// 		return 'orange'
// 	}
// 	if (status === 'failed') {
// 		return 'red'
// 	}
// 	return 'gray'
// }

const CandidateCard = ({ name, email, external_id, fitment, showTydy, onSetFitment }) => {
	// const [sendDataToTydy, setSendDataToTydy] = useState(false)
	// const currentOrg = globalStore.currentOrganization
	// const handleSendDataToTydy = async () => {
	// 	const tempState = {}

	// 	setSendDataToTydy(true)
	// 	await FitmentsService.sendDataToTydy({
	// 		pageState: tempState,
	// 		fitmentId: fitment?.id,
	// 	})
	// 	setSendDataToTydy(false)
	// 	const { error, fitment: fitmentData } = tempState
	// 	if (error) {
	// 		message.error('Oops!! something went wrong')
	// 		return
	// 	}
	// 	onSetFitment(fitmentData)
	// 	if (fitmentData?.tydy_sync_status === 'failed') message.error('Sending data failed.')
	// 	else message.success('Data has been sent successfully.')
	// }

	return (
		<StyledCard>
			<div className='top'>
				<div className='name'>{name}</div>
				{/* Todo when client will provide cred after that we will uncomment this part */}
				{/* {showTydy &&
					currentOrg['is_wns?'] &&
					['docusign_inprogress', 'docusign_completed'].includes(fitment?.status) && (
						<div className='status'>
							Tydy sync status :
							<div className={`status-circle ${getStatusColor(fitment?.tydy_sync_status)}`}></div>
							{fitment?.tydy_sync_status}
							<Button type='link' onClick={handleSendDataToTydy}>
								<RedoOutlined spin={sendDataToTydy} rotate={270} />
							</Button>
						</div>
					)} */}
			</div>
			<div className='details'>
				<div className='email'>{email}</div>
				<div className='external-id'>
					{fitment?.application_id && <div>Application ID: {fitment?.application_id}</div>}
					{fitment?.employee_external_id && <div>Employee ID: {fitment?.employee_external_id}</div>}
					External ID: {external_id}
				</div>
			</div>
		</StyledCard>
	)
}

export default CandidateCard
