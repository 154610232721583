import React, { useEffect, useState } from 'react'
import moment from 'moment'

import styled from 'styled-components'
import { Table, message, DatePicker } from 'antd'
import { view } from '@risingstack/react-easy-state'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { Redirect } from 'react-router-dom'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const { Column, ColumnGroup } = Table

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`
const RecruitmentVacancyReportPage = () => {
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const pageState = globalStore.ui.configuration.budgetHeads.recruitmentReport
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod

	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc().set('month', 3)
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc().set('month', 4).add('years', 1)
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	useEffect(() => {
		const fetchWorkForceTimeline = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.recruitmentReport

			const postData = {
				start_date: dateRange[0].startOf('month').format(),
				end_date: dateRange[1].endOf('month').format(),
			}
			await BudgetHeadsService.recruitmentReport({ pageState, postData })
			const { serverStatus, dataErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "Requirement Reports couldn't be fetched.")
				return
			}
		}
		fetchWorkForceTimeline()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange])

	const { workforce_recruitment_data, API_USERS_RECRUITMENT_REPORT_STATUS } = pageState
	const dataSource = workforce_recruitment_data?.recruitment_reports?.map((reportData, index) => {
		const returnData = { ...reportData.data, key: index }
		return returnData
	})
	const renderContents = () => {
		return (
			<Table
				className='position-req-report'
				dataSource={dataSource}
				pagination={{
					defaultPageSize: 12,
				}}
				bordered
				loading={false}
				style={{
					margin: '20px 0px',
				}}>
				{workforce_recruitment_data?.criteria_header_for_report &&
					workforce_recruitment_data?.criteria_header_for_report.map((data, index) => (
						<Column key={index} title={data?.title} dataIndex={data?.key} fixed={true} />
					))}
				<ColumnGroup title='Planned'>
					<Column title='Budgeted' dataIndex='budgeted' key='budgeted' />
					<Column title='Non-Budgeted' dataIndex='non_budgeted' key='non_budgeted' />
					<Column title='Total' dataIndex='total_planned' key='total_planned' />
				</ColumnGroup>
				<ColumnGroup title='Available/Filled'>
					<Column title='Joined' dataIndex='joined' key='joined' />
					<Column title='Offered' dataIndex='offer_initiated' key='offer_initiated' />
					<Column title='Total' dataIndex='total_available' key='total_available' />
				</ColumnGroup>
				<ColumnGroup title='Vacancy'>
					<Column title='Total' dataIndex='vacancy' key='vacancy' />
				</ColumnGroup>
			</Table>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 20,
						marginTop: 20,
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Period</div>
						<DatePicker.RangePicker
							value={dateRange}
							picker='month'
							format='YYYY MMM'
							onChange={setDateRange}
						/>
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content incremental-timeline'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_RECRUITMENT_REPORT_STATUS,
					data: workforce_recruitment_data,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(RecruitmentVacancyReportPage)
