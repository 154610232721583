import { API, generic } from 'api'

export default class FitmentsService {
	static index = async ({ pageState, page, pageSize, email }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_INDEX_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, email } },
			errorMessage: 'Fitments could not be fetched.',
		})
	}
	static sendDataToTydy = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_TYDY_DATA_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['error'],
			apiEndpoint: API.users.fitments.sendDataToTydy,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Process Failed.',
		})
	}

	static show = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SHOW_STATUS',
			stateDataKey: ['fitment', 'fitment_configuration'],
			stateErrorKey: ['fitmentErrors', 'fitmentConfigurationErrors'],
			apiEndpoint: API.users.fitments.show,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment could not be fetched.',
		})
	}
	static delete = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_DELETE_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.delete,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment could not be deleted.',
			serializedResponse: false,
		})
	}

	static deleteMultiple = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_MULTIPLE_DESTROY_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.deleteMultiple,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Fitment could not be deleted.',
			serializedResponse: false,
		})
	}

	static update = async ({ pageState, fitmentId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_UPDATE_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.update,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: 'Fitment could not be updated.',
		})
	}

	static cancel = async ({ pageState, fitmentId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_CANCEL_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.cancel,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: 'Fitment could not be cancelled.',
		})
	}

	static validateSalary = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_VALIDATE_SALARY_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.validateSalary,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: "Fitment's salary could not be validated.",
		})
	}

	static designationValues = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_DESIGNATION_VALUES_STATUS',
			stateDataKey: ['designations'],
			stateErrorKey: ['designationsErrors'],
			apiEndpoint: API.users.fitments.designationValues,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Designation values could not be fetched.',
		})
	}

	static workLocationValues = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_WORKLOCATION_VALUES_STATUS',
			stateDataKey: ['workLocations'],
			stateErrorKey: ['workLocationsErrors'],
			apiEndpoint: API.users.fitments.workLocationValues,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Designation values could not be fetched.',
		})
	}

	static updateDesignation = async ({ pageState, fitmentId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_UPDATE_DESIGNATION_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.updateDesignation,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: 'Designation could not be updated.',
		})
	}

	static export = async ({ pageState, params }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_EXPORT_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.export,
			apiUrlReplacements: {},
			apiData: { params },
			errorMessage: 'Fitment could not be exported.',
		})
	}

	static exportSalaries = async ({ pageState, params }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_EXPORT_SALARIES_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.exportSalaries,
			apiUrlReplacements: {},
			apiData: { params },
			errorMessage: 'Salaries could not be exported.',
		})
	}

	static pull = async ({ pageState }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_PULL_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.pull,
			apiUrlReplacements: {},
			apiData: {},
			errorMessage: 'Fitment syncing could not be done.',
		})
	}

	static comment = async ({ pageState, fitmentId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_COMMENT_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.comment,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: 'Comment could not be submitted.',
		})
	}

	// TODO: Move in separate fitment document service
	static fitmentDocuments = async ({ pageState, fitmentId, formData }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENT_DOCUMENTS_CREATE_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.fitmentDocuments.create,
			apiUrlReplacements: { fitmentId },
			apiData: formData,
			errorMessage: 'Fitment documents could not be uploaded.',
		})
	}

	static fitmentDocumentDelete = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENT_DOCUMENTS_DELETE_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.fitmentDocuments.delete,
			apiUrlReplacements: { fitmentId },
			errorMessage: 'Fitment documents could not be deleted.',
		})
	}

	static assignApprovalChain = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_ASSIGN_APPROVAL_CHAIN_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.assignApprovalChain,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment approval chain could not be updated.',
		})
	}
	static assignSalaryApprovalChain = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_ASSIGN_SALARY_APPROVAL_CHAIN_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.assignSalaryApprovalChain,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment Salary approval chain could not be updated.',
		})
	}

	static generateOfferLetter = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_GENERATE_OFFER_LETTER_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.generateOfferLetter,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Offer letter not be generated.',
			serializedResponse: false,
		})
	}

	static syncJoiningDate = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SYNC_JOINING_DATE_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.syncJoiningDate,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: "Joining date couldn't be synced.",
		})
	}

	static syncCandidateAddress = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SYNC_CANDIDATE_ADDRESS_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.syncCandidateAddress,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: "Address couldn't be synced.",
		})
	}
	static syncDocumentAddress = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SYNC_CANDIDATE_Document_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.syncDocuments,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: "Documents couldn't be synced.",
		})
	}

	static auditReport = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_AUDIT_REPORT_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.auditReport,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Audit report could not be exported.',
		})
	}

	static auditReports = async ({ pageState, params }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_AUDIT_REPORTS_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.auditReports,
			apiUrlReplacements: {},
			apiData: { params },
			errorMessage: 'Audit reports could not be exported.',
		})
	}

	static consolidateReport = async ({ pageState, params }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_CONSOLIDATE_REPORT_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.consolidatedReport,
			apiUrlReplacements: {},
			apiData: { params },
			errorMessage: 'Consolidated report could not be exported.',
		})
	}

	static search = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SEARCH_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.search,
			apiUrlReplacements: {},
			apiData: { ...values },
			errorMessage: 'Search could not be performed.',
		})
	}

	static resendApprovalMail = async ({ pageState, values, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_RESEND_APPROVAL_MAIL_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.resendApprovalMail,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: "Main couldn't be sent.",
		})
	}
	static resendMailForSalaryApprover = async ({ pageState, values, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_RESEND_SALARY_APPROVAL_MAIL_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.resendMailForSalaryApprover,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: "Main couldn't be sent.",
		})
	}

	static downloadOfferDraft = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_DOWNLOAD_OFFER_DRAFT_STATUS',
			stateDataKey: ['redirect_url'],
			stateErrorKey: ['redirectUrlErrors'],
			apiEndpoint: API.users.fitments.downloadOfferDraft,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Offer letter draft could not be downloaded.',
		})
	}

	static refresh = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_REFRESH_STATUS',
			stateDataKey: ['fitment', 'fitment_configuration'],
			stateErrorKey: ['fitmentErrors', 'fitmentConfigurationErrors'],
			apiEndpoint: API.users.fitments.refresh,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment could not be refreshed.',
		})
	}

	static updateDocusignSetting = async ({ pageState, fitmentId, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_UPDATE_DOCUSIGN_SETTING_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.updateDocusignSetting,
			apiUrlReplacements: { fitmentId },
			apiData: { ...values },
			errorMessage: 'Docusign setting could not be updated.',
		})
	}

	static refreshDocusignStatus = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_REFRESH_DOCUSIGN_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.refreshDocusignStatus,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Docusign status could not be updated.',
		})
	}

	static syncOfferLetter = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SYNC_OFFER_LETTER_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.syncOfferLetter,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Offer letter could not be synced.',
		})
	}
	static createLetter = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_CREATE_LETTER_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.createLetter,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Letter could not be created.',
			serializedResponse: false,
		})
	}
	static sendLetter = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_SEND_LETTER_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.sendLetter,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Letter could not be send.',
			serializedResponse: false,
		})
	}

	static generateBulkLetters = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_GENERATE_BULK_LETTERS_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['errors'],
			apiEndpoint: API.users.fitments.generateBulkLetters,
			apiData: { ...values },
			errorMessage: 'Fitment could not be updated.',
			serializedResponse: false,
		})
	}
	static sendBulkLetters = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SEND_BULK_LETTERS_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['errors'],
			apiEndpoint: API.users.fitments.sendBulkLetters,
			apiData: { ...values },
			errorMessage: 'Fitment could not be updated.',
			serializedResponse: false,
		})
	}
	static sendBulkLettersViaDocusign = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_SEND_BULK_LETTERS__VIA_DOCUSIGN_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['errors'],
			apiEndpoint: API.users.fitments.sendBulkLettersViaDocusign,
			apiData: { ...values },
			errorMessage: 'Fitment could not be updated.',
			serializedResponse: false,
		})
	}

	static additionDocumentsIndex = async ({ pageState, page, pageSize, email }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_ADDITIONAL_DOCUMENT_INDEX_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentsErrors'],
			apiEndpoint: API.users.fitments.additionDocumentsIndex,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, email } },
			errorMessage: 'Addition Documents could not be fetched.',
		})
	}
	static fitmentLetterDetails = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_LETTER_DETAILS_STATUS',
			stateDataKey: ['fitment'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.fitmentLetterDetails,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment could not be fetched.',
		})
	}
	static refreshDocusignLetterStatus = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_REFRESH_DOCUSIGN_LETTER_STATUS',
			stateDataKey: ['fitments'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.refreshDocusignLetterStatus,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Fitment could not be fetched.',
		})
	}
	static refreshBulkDocusignLetterStatus = async ({ pageState, values }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_REFRESH_BULK_DOCUSIGN_LETTER_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.refreshBulkDocusignLetterStatus,
			apiData: { ...values },
			errorMessage: 'Fitment could not be fetched.',
			serializedResponse: false,
		})
	}
	static downloadDocusignLetter = async ({ pageState, letterUuid }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_DOWNLOAD_DOCUSIGN_LETTERS_STATUS',
			stateDataKey: ['redirect_url'],
			stateErrorKey: ['redirectUrlErrors'],
			apiEndpoint: API.users.fitments.downloadDocusignLetter,
			apiUrlReplacements: { letterUuid },
			apiData: {},
			errorMessage: 'Docusign letter could not be downloaded.',
		})
	}
	static generateDraftLetter = async ({ pageState, fitmentId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_FITMENTS_GENERATE_DRAFT_LETTER_STATUS',
			stateDataKey: ['message'],
			stateErrorKey: ['fitmentErrors'],
			apiEndpoint: API.users.fitments.generateDraftLetter,
			apiUrlReplacements: { fitmentId },
			apiData: {},
			errorMessage: 'Draft letter not be generated.',
			serializedResponse: false,
		})
	}
}
