import { API, generic } from 'api'

export default class WebService {
	static index = async ({ pageState, page, pageSize, organizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WEB_SERVICE_INDEX_STATUS',
			stateDataKey: ['web_services'],
			stateErrorKey: ['webServicesErrors'],
			apiEndpoint: API.adminUsers.webServices.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize, organization_id: organizationId } },
			errorMessage: 'Web Services could not be fetched.',
		})
	}

	static show = async ({ pageState, webServiceId, organizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WEB_SERVICE_SHOW_STATUS',
			stateDataKey: ['web_service'],
			stateErrorKey: ['webServiceErrors'],
			apiEndpoint: API.adminUsers.webServices.show,
			apiUrlReplacements: { webServiceId },
			apiData: { params: { organization_id: organizationId } },
			errorMessage: 'Fitment could not be fetched.',
		})
	}

	static update = async ({ pageState, values, webServiceId, organizationId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_WEB_SERVICE_UPDATE_STATUS',
			stateDataKey: ['web_service'],
			stateErrorKey: ['webServiceErrors'],
			apiEndpoint: API.adminUsers.webServices.update,
			apiUrlReplacements: { webServiceId },
			apiData: { organization_id: organizationId, ...values },
			errorMessage: 'Fitment could not be fetched.',
		})
	}
}
