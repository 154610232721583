import { API, generic } from 'api'

export default class APiLogsService {
	static index = async ({ pageState, page, pageSize }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_API_LOGS_INDEX_STATUS',
			stateDataKey: ['api_logs'],
			stateErrorKey: ['apiLogsErrors'],
			apiEndpoint: API.users.apiLogs.index,
			apiUrlReplacements: {},
			apiData: { params: { page, page_size: pageSize } },
			errorMessage: 'Api Logs could not be fetched.',
		})
	}

	static show = async ({ pageState, apiLogId }) => {
		await generic({
			pageState,
			stateApiStatusKey: 'API_USERS_API_LOGS_SHOW_STATUS',
			stateDataKey: ['api_log'],
			stateErrorKey: ['apiLogErrors'],
			apiEndpoint: API.users.apiLogs.show,
			apiUrlReplacements: { apiLogId },
			apiData: {},
			errorMessage: 'Fitment could not be fetched.',
		})
	}
}
