import React, { useEffect, useState } from 'react'
import moment from 'moment'

import styled from 'styled-components'
import { Table, message, DatePicker, Switch } from 'antd'
import { view } from '@risingstack/react-easy-state'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { Redirect } from 'react-router-dom'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const { Column, ColumnGroup } = Table

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const YOYSummary = () => {
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [count, setCount] = useState(false)

	const pageState = globalStore.ui.configuration.budgetHeads.yearOnYearSummary
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod

	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.start_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc().set('month', 3)
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc().set('month', 4).add('years', 1)
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	useEffect(() => {
		const fetchWorkForceTimeline = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.yearOnYearSummary

			const postData = {
				start_date: dateRange[0].startOf('month').format(),
				end_date: dateRange[1].endOf('month').format(),
			}
			// setTableLoading(true)
			await BudgetHeadsService.yearOnYearSummary({ pageState, postData })
			// setTableLoading(false)
			const { serverStatus, dataErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "Requirement Reports couldn't be fetched.")
				return
			}
		}
		fetchWorkForceTimeline()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange])

	const { workforce_summary, API_USERS_YEAR_ON_YEAR_SUMMARY_STATUS } = pageState

	const handleCountSwitch = (value) => {
		setCount(value)
	}

	const dataSource = workforce_summary?.summary?.map((reportData, index) => {
		const { data } = reportData
		const budgeted = !count
			? parseFloat(data?.budgeted_size)
			: parseFloat(parseFloat(data?.budgeted_value).toFixed(2))
		const nonBudgeted = !count
			? parseFloat(data?.non_budgeted_size)
			: parseFloat(parseFloat(data?.non_budgeted_value).toFixed(2))
		const totalPlanned = parseFloat(budgeted + nonBudgeted)
		const replacement = !count
			? parseFloat(data?.non_budgeted_size)
			: parseFloat(parseFloat(data?.non_budgeted_value).toFixed(2))
		const newPosition = !count
			? parseFloat(data?.non_budgeted_size)
			: parseFloat(parseFloat(data?.non_budgeted_value).toFixed(2))
		const totalActual = parseFloat(replacement + newPosition)
		const gap = parseFloat(totalPlanned - totalActual)

		const returnData = {
			key: index,
			budgeted: budgeted,
			non_budgeted: nonBudgeted,
			total_planned: totalPlanned,
			replacement: replacement,
			new_position: newPosition,
			total_actual: totalActual,
			gap_count: gap,
			...reportData.data,
		}
		return returnData
	})

	const renderContents = () => {
		return (
			<Table
				dataSource={dataSource}
				pagination={{
					defaultPageSize: 12,
				}}
				bordered
				loading={false}
				className='yoy-summary'
				style={{
					margin: '20px 0px',
				}}>
				{workforce_summary?.criteria_header_for_report &&
					workforce_summary?.criteria_header_for_report.map((data, index) => (
						<Column key={index} title={data?.title} dataIndex={data?.key} fixed={true} />
					))}
				<ColumnGroup title='Planned'>
					<Column
						title='Budgeted'
						dataIndex={!count ? 'budgeted' : 'display_budgeted_value'}
						key='budgeted'
					/>
					<Column
						title='Non-Budgeted'
						dataIndex={!count ? 'non_budgeted' : 'display_non_budgeted_value'}
						key='non_budgeted'
					/>
					<Column
						title='Total'
						dataIndex={!count ? 'total_planned' : 'display_planned_total'}
						key='total_planned'
					/>
				</ColumnGroup>
				<ColumnGroup title='Actual 	'>
					<Column
						title='Replacement'
						dataIndex={!count ? 'replacement' : 'display_replacement_value'}
						key='replacement'
					/>
					<Column
						title='New Position'
						dataIndex={!count ? 'new_position' : 'display_new_position_value'}
						key='new_position'
					/>
					<Column
						title='Total'
						dataIndex={!count ? 'total_actual' : 'display_actual_total'}
						key='total_actual'
					/>
				</ColumnGroup>
				<Column title='Gap' dataIndex={!count ? 'gap_count' : 'display_gap'} key='gap' />
			</Table>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: 20,
						marginTop: 20,
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Period</div>
						<DatePicker.RangePicker
							value={dateRange}
							picker='month'
							format='YYYY MMM'
							onChange={setDateRange}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexGrow: 1,
						}}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: 10,
							}}>
							<div>Count</div>
							<Switch checked={count} onChange={handleCountSwitch} />
							<div>Cost</div>
						</div>
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content incremental-timeline'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_YEAR_ON_YEAR_SUMMARY_STATUS,
					data: workforce_summary,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(YOYSummary)
