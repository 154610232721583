import axios from 'axios'
import { deserialise } from 'kitsu-core'
import { message } from 'antd'

import { fetchFromLocalStorage } from 'store/localStorage'
import ServerNotReachableError from 'errors/ServerNotReachableError'

import * as Authhelper from 'helpers/auth.helper'

export const getAuthorization = (token) => {
	if (token) return `Bearer ${token}`

	const storedToken = fetchFromLocalStorage({
		key: 'token',
	})
	if (!storedToken) return null

	return `Bearer ${storedToken}`
}

export const apiClient = ({ token } = {}) => {
	const headers = {
		'Content-Type': 'application/json',
	}

	const authorization = getAuthorization(token)

	if (authorization) {
		headers['Authorization'] = authorization
	}

	return axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
		headers,
	})
}

export const call = async (f, ...args) => {
	let response
	try {
		response = await f(...args)
	} catch (e) {
		message.destroy()

		Object.keys(e)
			.filter((k) => k !== 'toJSON')
			.forEach((k) => console.log(`e.${k}`, e[k]))
		if (!e.isAxiosError) {
			throw e
		}

		response = e.response

		if (!response) {
			throw new ServerNotReachableError('Maybe server is not running')
		}

		if (response.status >= 500) {
			throw e
		}
	}
	return response
}

export const API = {
	hirereviewLogin: {
		method: 'get',
		url: '/hirereview_login',
	},
	payreviewLogin: {
		method: 'get',
		url: '/payreview_login',
	},
	users: {
		index: { method: 'get', url: '/api/users' },
		show: { method: 'get', url: '/api/users/:userId' },
		update: { method: 'put', url: '/api/users/:userId' },
		updateStatus: { method: 'put', url: '/api/users/:userId/update_status' },
		create: { method: 'post', url: '/api/users' },
		me: { method: 'get', url: '/api/users/me' },
		smartRecruitersConsent: { method: 'post', url: '/api/users/smart_recruiters_consent' },
		workforcePositions: { method: 'get', url: '/api/users/workforce_positions' },
		positionReportings: {
			orgCharts: { method: 'post', url: '/api/users/position_reportings/org_charts' },
			import: { method: 'post', url: '/api/users/position_reportings/import' },
			importTransition: {
				method: 'post',
				url: '/api/users/position_reportings/import_transition',
			},
		},
		employeePositions: {
			transitionAnalysis: {
				method: 'post',
				url: '/api/users/employee_positions/transition_analysis',
			},
		},
		dashboard: {
			index: {
				method: 'get',
				url: '/api/users/dashboard',
			},
		},
		resetPassword: {
			method: 'put',
			url: '/api/users/reset-password',
		},
		tokens: {
			login: { method: 'post', url: '/api/users/login' },
			srOauthAccessCallback: {
				method: 'get',
				url: '/auth/smartrecruiters/callback?code=:code',
			},
		},
		notifications: {
			index: { method: 'get', url: '/api/users/notifications' },
			show: {
				method: 'get',
				url: '/api/users/notifications/:notificationId',
			},
		},
		apiLogs: {
			index: { method: 'get', url: '/api/users/api_logs' },
			show: { method: 'get', url: '/api/users/api_logs/:apiLogId' },
		},
		fitments: {
			index: { method: 'get', url: '/api/users/fitments' },
			sendDataToTydy: { method: 'get', url: '/api/users/fitments/:fitmentId/send_data_to_tydy' },
			show: { method: 'get', url: '/api/users/fitments/:fitmentId' },
			update: { method: 'put', url: '/api/users/fitments/:fitmentId' },
			delete: { method: 'put', url: '/api/users/fitments/:fitmentId/delete' },
			deleteMultiple: { method: 'post', url: '/api/users/fitments/delete_multiple' },
			cancel: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/cancel',
			},
			validateSalary: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/validate_salary',
			},
			designationValues: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/designation_values',
			},
			workLocationValues: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/work_location_values',
			},
			updateDesignation: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/update_designation',
			},
			export: {
				method: 'get',
				url: '/api/users/fitments/export',
			},
			exportSalaries: {
				method: 'get',
				url: '/api/users/fitments/export_salaries',
			},
			pull: {
				method: 'get',
				url: '/api/users/fitments/pull',
			},
			comment: {
				method: 'post',
				url: '/api/users/fitments/:fitmentId/comment',
			},
			fitmentDocuments: {
				create: {
					method: 'post',
					url: '/api/users/fitments/:fitmentId/fitment_documents',
				},
				delete: {
					method: 'delete',
					url: '/api/users/fitment_documents/:fitmentId',
				},
			},
			assignApprovalChain: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/assign_approval_chain',
			},
			assignSalaryApprovalChain: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/assign_salary_approval_chain',
			},
			generateOfferLetter: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/generate_offer_letter',
			},
			generateDraftLetter: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/generate_draft_letter',
			},
			syncJoiningDate: {
				method: 'get',
				url: 'api/users/fitments/:fitmentId/sync_joining_date',
			},
			syncCandidateAddress: {
				method: 'get',
				url: 'api/users/fitments/:fitmentId/sync_candidate_address',
			},
			syncDocuments: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/sync_documents',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/fitments/audit_reports',
			},
			consolidatedReport: {
				method: 'get',
				url: '/api/users/fitments/consolidate_report',
			},
			search: { method: 'post', url: '/api/users/fitments/search' },
			resendApprovalMail: {
				method: 'post',
				url: '/api/users/fitments/:fitmentId/resend_mail_for_approver',
			},
			resendMailForSalaryApprover: {
				method: 'post',
				url: '/api/users/fitments/:fitmentId/resend_mail_for_salary_approver',
			},
			downloadOfferDraft: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/download_draft_letter',
			},
			refresh: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/refresh',
			},
			updateDocusignSetting: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/update_docusign_setting',
			},
			refreshDocusignStatus: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/refresh_docusign_status',
			},
			syncOfferLetter: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/sync_offer_letter',
			},
			createLetter: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/create_letters',
			},
			sendLetter: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/send_letters',
			},
			letterTemplates: {
				refresh: {
					method: 'put',
					url: '/api/users/fitments/:fitmentId/letter_templates/refresh',
				},
			},
			generateBulkLetters: {
				method: 'post',
				url: '/api/users/fitments/generate_bulk_letters',
			},
			sendBulkLetters: {
				method: 'post',
				url: '/api/users/fitments/send_bulk_letters',
			},
			sendBulkLettersViaDocusign: {
				method: 'post',
				url: '/api/users/fitments/send_bulk_letters_via_docusign',
			},
			additionDocumentsIndex: {
				method: 'get',
				url: '/api/users/fitments/addition_documents_index',
			},
			fitmentLetterDetails: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/fitment_letter_details',
			},
			refreshDocusignLetterStatus: {
				method: 'get',
				url: '/api/users/fitments/:fitmentId/refresh_docusign_letter_status',
			},
			refreshBulkDocusignLetterStatus: {
				method: 'post',
				url: '/api/users/fitments/refresh_bulk_docusign_letter_status',
			},
			downloadDocusignLetter: {
				method: 'get',
				url: '/api/users/fitments/:letterUuid/download_docusign_letter',
			},
		},
		candidates: {
			show: { method: 'get', url: '/api/users/candidates/:candidateId' },
			update: { method: 'put', url: '/api/users/candidates/:candidateId' },
		},
		organizationRoles: {
			index: { method: 'get', url: '/api/users/organization_roles' },
			addFeature: {
				method: 'put',
				url: '/api/users/organization_roles/:roleId/add_feature',
			},
			removeFeature: {
				method: 'put',
				url: '/api/users/organization_roles/:roleId/remove_feature',
			},
			createDefaultRole: {
				method: 'get',
				url: '/api/users/organization_roles/create_default_role',
			},
		},
		organizationFields: {
			index: { method: 'get', url: '/api/users/organization_fields' },
			show: {
				method: 'get',
				url: '/api/users/organization_fields/:fieldId',
			},
			sync: {
				method: 'put',
				url: '/api/users/organization_fields/sync',
			},
		},
		fitmentFieldsConfigurations: {
			index: {
				method: 'get',
				url: '/api/users/fitment_fields_configurations',
			},
			create: {
				method: 'post',
				url: '/api/users/fitment_fields_configurations',
			},
			show: {
				method: 'get',
				url: '/api/users/fitment_fields_configurations/:fitmentFieldsConfigurationId',
			},
			update: {
				method: 'put',
				url: '/api/users/fitment_fields_configurations/:fitmentFieldsConfigurationId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/fitment_fields_configurations/:fitmentFieldsConfigurationId',
			},
			sortOrder: {
				method: 'post',
				url: '/api/users/fitment_fields_configurations/sort_order',
			},
		},
		fitmentConfigurations: {
			index: {
				method: 'get',
				url: '/api/users/fitment_configurations',
			},
			update: {
				method: 'put',
				url: '/api/users/fitment_configurations/:fitmentConfigurationId',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/fitment_configurations/audit_reports',
			},
		},
		approvalChains: {
			index: {
				method: 'get',
				url: '/api/users/approval_chains',
			},
			create: {
				method: 'post',
				url: '/api/users/approval_chains',
			},
			show: {
				method: 'get',
				url: '/api/users/approval_chains/:approvalChainId',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/approval_chains/refresh',
			},
			refreshSalaryApprovalChain: {
				method: 'post',
				url: 'api/users/approval_chains/refresh_salary_approval_chain',
			},
			refreshBudgetTentativeValue: {
				method: 'post',
				url: '/api/users/approval_chains/refresh_budget_tentative_value',
			},
			update: {
				method: 'put',
				url: '/api/users/approval_chains/:approvalChainId',
			},
			export: {
				method: 'get',
				url: '/api/users/approval_chains/export',
			},
			import: {
				method: 'post',
				url: '/api/users/approval_chains/import',
			},
			delete: {
				method: 'delete',
				url: '/api/users/approval_chains/:approvalChainId',
			},
			publish: {
				method: 'put',
				url: '/api/users/approval_chains/:approvalChainId/publish',
			},
			unpublish: {
				method: 'put',
				url: '/api/users/approval_chains/:approvalChainId/unpublish',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/approval_chains/:approvalChainId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/approval_chains/audit_reports',
			},
			syncBCSLAOwnerEmail: {
				method: 'get',
				url: '/api/users/approval_chains/sync_bcsla_owner_email',
			},
		},
		budgetTentativeValues: {
			index: {
				method: 'get',
				url: '/api/users/budget_tentative_values',
			},
			show: {
				method: 'get',
				url: '/api/users/budget_tentative_values/:budgetTentativeValueId',
			},
			create: {
				method: 'post',
				url: '/api/users/budget_tentative_values',
			},
			update: { method: 'put', url: '/api/users/budget_tentative_values/:budgetTentativeValueId' },
			delete: {
				method: 'delete',
				url: '/api/users/budget_tentative_values/:budgetTentativeValueId',
			},
			assignApprovalChain: {
				method: 'put',
				url: '/api/users/budget_tentative_values/:budgetTentativeValueId/assign_approval_chain',
			},
			comment: {
				method: 'post',
				url: '/api/users/budget_tentative_values/:budgetTentativeValueId/comment',
			},
		},
		budgetApprovals: {
			approve: {
				method: 'put',
				url: '/api/users/budget_approvals/:budgetTentativeValueId/approve',
			},
			reject: { method: 'put', url: '/api/users/budget_approvals/:budgetTentativeValueId/reject' },
			pendingApprovals: {
				method: 'get',
				url: '/api/users/budget_approvals/pending_approvals',
			},
			show: {
				method: 'get',
				url: '/api/users/budget_approvals/:pendingApprovalId',
			},
			comment: {
				method: 'post',
				url: '/api/users/budget_approvals/:pendingApprovalId/comment',
			},
		},
		salaryRanges: {
			index: {
				method: 'get',
				url: '/api/users/salary-ranges',
			},
			create: {
				method: 'post',
				url: '/api/users/salary-ranges',
			},
			show: {
				method: 'get',
				url: '/api/users/salary-ranges/:salaryRangeId',
			},
			update: {
				method: 'put',
				url: '/api/users/salary-ranges/:salaryRangeId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/salary-ranges/:salaryRangeId',
			},
			export: {
				method: 'get',
				url: '/api/users/salary-ranges/export',
			},
			import: {
				method: 'post',
				url: '/api/users/salary-ranges/import',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/salary_ranges/refresh',
			},
			revalidate: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/salary_ranges/revalidate',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/salary-ranges/:salaryRangeId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/salary-ranges/audit_reports',
			},
		},
		// budget_criteria_heads routes start here
		budget_criteria_heads: {
			index: {
				method: 'get',
				url: '/api/users/budget-criteria-heads',
			},
			create: {
				method: 'post',
				url: '/api/users/budget-criteria-heads',
			},
			show: {
				method: 'get',
				url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId',
			},
			update: {
				method: 'put',
				url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId',
			},
			update_active_status: {
				method: 'put',
				url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId/update_active_status',
			},
			validateCriteriaValues: {
				method: 'get',
				url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId/validate_criteria_values',
			},
			import: {
				method: 'post',
				url: '/api/users/budget-criteria-heads/:id/import',
			},
			export: {
				method: 'get',
				url: '/api/users/budget-criteria-heads/:id/export',
			},
			criteria_head_list: {
				method: 'get',
				url: '/api/users/budget-criteria-heads/criteria_head_list',
			},
			criteriaList: {
				method: 'get',
				url: '/api/users/budget-criteria-heads/criteria_list',
			},
			criteriaValues: {
				method: 'get',
				url: '/api/users/budget-criteria-heads/:id/criteria_values',
			},
			criteriaValuesList: {
				method: 'post',
				url: '/api/users/budget-criteria-heads/criteria_values_list',
			},
			budget_criteria_values: {
				index: {
					method: 'get',
					url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId/budget-criteria-values',
				},
				create: {
					method: 'post',
					url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId/budget-criteria-values',
				},
				show: {
					method: 'get',
					url: '/api/users/budget-criteria-values/:budgetCriteriaValueId',
				},
				update: {
					method: 'put',
					url: '/api/users/budget-criteria-values/:budgetCriteriaValueId',
				},
				delete: {
					method: 'delete',
					url: '/api/users/budget-criteria-values/:budgetCriteriaValueId',
				},
				parent_budget_criteria_value: {
					method: 'get',
					url: '/api/users/budget-criteria-heads/:budgetCriteriaHeadId/budget-criteria-values/parent_budget_criteria_value',
				},
			},
		},
		budget_utilizations: {
			index: {
				method: 'get',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations',
			},
			sampleFileData: {
				method: 'get',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/sample_file_data',
			},
			essentialFieldsList: {
				method: 'get',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/essential_fields_list',
			},
			create: {
				method: 'post',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations',
			},
			sync_workforce_data: {
				method: 'post',
				url: '/api/users/budget_heads/sync_payreview_workforce_data',
			},
			show: {
				method: 'get',
				url: '/api/users/budget_utilizations/:budgetUtilizationId',
			},
			update: {
				method: 'put',
				url: '/api/users/budget_utilizations/:budgetUtilizationId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/budget_utilizations/:budgetUtilizationId',
			},
			addEmployee: {
				method: 'post',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/add_employee',
			},
			validate_actual_budget: {
				method: 'post',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/validate_actual_budget',
			},
			upsert_actual_budget_entry: {
				method: 'post',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/upsert_actual_budget_entry',
			},
			import: {
				method: 'post',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/import',
			},
			importVacancy: {
				method: 'post',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/import_vacancy',
			},
			export: {
				method: 'get',
				url: '/api/users/budget_heads/:budgetHeadId/budget_utilizations/export',
			},
		},
		budgetHeads: {
			index: {
				method: 'get',
				url: '/api/users/budget_heads',
			},
			budget_analysis: {
				method: 'post',
				url: '/api/users/budget_heads/budget_analysis',
			},
			create: {
				method: 'post',
				url: '/api/users/budget_heads',
			},
			create_children: {
				method: 'post',
				url: '/api/users/budget_heads/:id/budget_children',
			},
			budget_entry_data: {
				method: 'post',
				url: '/api/users/budget_heads/:id/budget_entry_data',
			},
			update: {
				method: 'put',
				url: '/api/users/budget_heads/:budgetHeadId',
			},
			show: {
				method: 'get',
				url: '/api/users/budget_heads/:budgetHeadId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/budget_heads/:budgetHeadId',
			},
			budgetUtilizationData: {
				method: 'post',
				url: '/api/users/budget_heads/budget_utilization_data',
			},
			export: {
				method: 'post',
				url: '/api/users/budget_heads/export',
			},
			workforceData: {
				method: 'post',
				// url: '/api/users/budget_heads/workforce_report',
				url: '/api/users/budget_heads/workforce_planned_report',
			},
			workforceTimeline: {
				method: 'post',
				url: '/api/users/budget_heads/workforce_timeline',
			},
			workforceMonitor: {
				method: 'post',
				url: '/api/users/budget_heads/workforce_moniter',
			},
			calculateResourcePlan: {
				method: 'post',
				url: '/api/users/budget_heads/calculate_resource_plan',
			},
			calculateAllResourcePlan: {
				method: 'post',
				url: '/api/users/budget_heads/calculate_all_resource_plan',
			},
			syncPayreviewPlannedWorkforceData: {
				method: 'post',
				url: '/api/users/budget_heads/sync_payreview_planned_workforce_data',
			},
			exportPositionWiseReport: {
				method: 'get',
				url: '/api/users/budget_heads/export_position_reports',
			},
			budgetValues: {
				index: {
					method: 'get',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values',
				},
				sampleFileData: {
					method: 'get',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/sample_file_data',
				},
				essentialFieldsList: {
					method: 'get',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/essential_fields_list',
				},
				create: {
					method: 'post',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values',
				},
				show: {
					method: 'get',
					url: '/api/users/budget_values/:budgetValueId',
				},
				editPosition: {
					method: 'put',
					url: '/api/users/budget_values/:budgetValueId/edit_position',
				},
				update: {
					method: 'put',
					url: '/api/users/budget_values/:budgetValueId',
				},
				delete: {
					method: 'delete',
					url: '/api/users/budget_values/:budgetValueId',
				},
				addPosition: {
					method: 'post',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/add_position',
				},
				upsert_planned_budget_entry: {
					method: 'post',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/upsert_planned_budget_entry',
				},
				import: {
					method: 'post',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/import',
				},
				importVacancy: {
					method: 'post',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/import_vacancy',
				},
				export: {
					method: 'get',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/export',
				},

				search: {
					method: 'post',
					url: 'api/users/budget_heads/:budgetHeadId/budget_values/search',
				},
				exportPositionReport: {
					method: 'get',
					url: '/api/users/budget_heads/:budgetHeadId/budget_values/export_position_report',
				},
			},
			budgetUtilizations: {
				search: {
					method: 'post',
					url: 'api/users/budget_heads/:budgetHeadId/budget_utilizations/search',
				},
			},
			recruitmentReport: {
				method: 'post',
				url: 'api/users/budget_heads/recruitment_report',
			},
			yearOnYearSummary: {
				method: 'post',
				url: 'api/users/budget_heads/year_on_year_summary',
			},
		},
		budgets: {
			index: {
				method: 'get',
				url: '/api/users/budgets',
			},
			create: {
				method: 'post',
				url: '/api/users/budgets',
			},
			show: {
				method: 'get',
				url: '/api/users/budgets/:budgetId',
			},
			update: {
				method: 'put',
				url: '/api/users/budgets/:budgetId',
			},
			export: {
				method: 'get',
				url: '/api/users/budgets/export',
			},
			import: {
				method: 'post',
				url: '/api/users/budgets/import',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/budgets/refresh',
			},
			revalidate: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/budgets/revalidate',
			},
			delete: {
				method: 'delete',
				url: '/api/users/budgets/:budgetId',
			},
			multipleDestroy: {
				method: 'put',
				url: 'api/users/budgets/multiple_destroy',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/budgets/:budgetId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/budgets/audit_reports',
			},
		},
		workForcePeriod: {
			index: {
				method: 'get',
				url: '/api/users/workforce_periods',
			},
			create: {
				method: 'post',
				url: '/api/users/workforce_periods',
			},
			show: {
				method: 'get',
				url: '/api/users/workforce_periods/:workforcePeriodID',
			},
			update: {
				method: 'put',
				url: '/api/users/workforce_periods/:workforcePeriodID',
			},
			currentWorkforcePeriod: {
				method: 'get',
				url: '/api/users/workforce_periods/current_workforce_period',
			},
		},
		minimumWages: {
			index: {
				method: 'get',
				url: '/api/users/minimum-wages',
			},
			create: {
				method: 'post',
				url: '/api/users/minimum-wages',
			},
			show: {
				method: 'get',
				url: '/api/users/minimum-wages/:minimumWageId',
			},
			update: {
				method: 'put',
				url: '/api/users/minimum-wages/:minimumWageId',
			},
			export: {
				method: 'get',
				url: '/api/users/minimum-wages/export',
			},
			import: {
				method: 'post',
				url: '/api/users/minimum-wages/import',
			},
			delete: {
				method: 'delete',
				url: '/api/users/minimum-wages/:minimumWageId',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/minimum_wages/refresh',
			},
			revalidate: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/minimum_wages/revalidate',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/minimum-wages/:minimumWageId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/minimum-wages/audit_reports',
			},
		},
		organizationMinimumSalaries: {
			index: {
				method: 'get',
				url: '/api/users/organization-minimum-salaries',
			},
			create: {
				method: 'post',
				url: '/api/users/organization-minimum-salaries',
			},
			show: {
				method: 'get',
				url: '/api/users/organization-minimum-salaries/:organizationMinimumSalaryId',
			},
			update: {
				method: 'put',
				url: '/api/users/organization-minimum-salaries/:organizationMinimumSalaryId',
			},
			export: {
				method: 'get',
				url: '/api/users/organization-minimum-salaries/export',
			},
			import: {
				method: 'post',
				url: '/api/users/organization-minimum-salaries/import',
			},
			delete: {
				method: 'delete',
				url: '/api/users/organization-minimum-salaries/:organizationMinimumSalaryId',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/organization_minimum_salaries/refresh',
			},
			revalidate: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/organization_minimum_salaries/revalidate',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/organization-minimum-salaries/:organizationMinimumSalaryId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/organization-minimum-salaries/audit_reports',
			},
		},
		offerLetterTemplates: {
			index: {
				method: 'get',
				url: '/api/users/offer-letter-templates',
			},
			create: {
				method: 'post',
				url: '/api/users/offer-letter-templates',
			},
			show: {
				method: 'get',
				url: '/api/users/offer-letter-templates/:offerLetterTemplateId',
			},
			update: {
				method: 'put',
				url: '/api/users/offer-letter-templates/:offerLetterTemplateId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/offer-letter-templates/:offerLetterTemplateId',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/offer_letter_templates/refresh',
			},
		},
		letterTemplates: {
			index: {
				method: 'get',
				url: '/api/users/letter-templates',
			},
			create: {
				method: 'post',
				url: '/api/users/letter-templates',
			},
			show: {
				method: 'get',
				url: '/api/users/letter-templates/:letterTemplateId',
			},
			update: {
				method: 'put',
				url: '/api/users/letter-templates/:letterTemplateId',
			},
			delete: {
				method: 'put',
				url: '/api/users/letter-templates/:letterTemplateId/destroy_letter',
			},
		},
		emailTemplates: {
			index: {
				method: 'get',
				url: '/api/users/email-templates',
			},
			create: {
				method: 'post',
				url: '/api/users/email-templates',
			},
			show: {
				method: 'get',
				url: '/api/users/email-templates/:emailTemplateId',
			},
			update: {
				method: 'put',
				url: '/api/users/email-templates/:emailTemplateId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/email-templates/:emailTemplateId',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/email_templates/refresh',
			},
			createDefaultBudgetApprovalTemplate: {
				method: 'get',
				url: '/api/users/email-templates/create_default_budget_approval_template',
			},
		},
		incentives: {
			index: {
				method: 'get',
				url: '/api/users/incentive_configurations',
			},
			create: {
				method: 'post',
				url: '/api/users/incentive_configurations',
			},
			show: {
				method: 'get',
				url: '/api/users/incentive_configurations/:incentiveId',
			},
			update: {
				method: 'put',
				url: '/api/users/incentive_configurations/:incentiveId',
			},
			export: {
				method: 'get',
				url: '/api/users/incentive_configurations/export',
			},
			import: {
				method: 'post',
				url: '/api/users/incentive_configurations/import',
			},
			refresh: {
				method: 'put',
				url: '/api/users/fitments/:fitmentId/incentive_configurations/refresh',
			},
			delete: {
				method: 'delete',
				url: '/api/users/incentive_configurations/:incentiveId',
			},
			auditReport: {
				method: 'get',
				url: '/api/users/incentive_configurations/:incetiveId/audit_report',
			},
			auditReports: {
				method: 'get',
				url: '/api/users/incentive_configurations/audit_reports',
			},
		},
		offers: {
			download: {
				method: 'get',
				url: '/api/users/offers/:offerUuid/download',
			},
			docusignDownload: {
				method: 'get',
				url: '/api/users/offers/:offerUuid/docusign_download',
			},
		},
		letters: {
			download: {
				method: 'get',
				url: '/api/users/letters/:letterUuid/download',
			},
		},
		documents: {
			index: {
				method: 'get',
				url: '/api/users/documents',
			},
			create: {
				method: 'post',
				url: '/api/users/documents',
			},
			show: {
				method: 'get',
				url: '/api/users/documents/:documentId',
			},
			update: {
				method: 'put',
				url: '/api/users/documents/:documentId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/documents/:documentId',
			},
			download: {
				method: 'get',
				url: '/api/users/fitment-documents/:documentUuid/download',
			},
		},
		vendors: {
			index: {
				method: 'get',
				url: '/api/users/vendors',
			},
			create: {
				method: 'post',
				url: '/api/users/vendors',
			},
			show: {
				method: 'get',
				url: '/api/users/vendors/:vendorId',
			},
			update: {
				method: 'put',
				url: '/api/users/vendors/:vendorId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/vendors/:vendorId',
			},
		},
		organizationConfigurations: {
			me: { method: 'get', url: '/api/users/organization_configurations/me' },
			update: { method: 'put', url: '/api/users/organization_configurations/:id' },
		},
		organizations: {
			me: {
				method: 'get',
				url: '/api/users/organizations/me',
			},
			uploadLogo: {
				method: 'put',
				url: '/api/users/organizations/upload_logo',
			},
			templateVariable: {
				method: 'get',
				url: '/api/users/organizations/template_variable',
			},
			update: {
				method: 'put',
				url: '/api/users/organizations/:id',
			},
		},
		ss: {
			configurations: {
				index: {
					method: 'get',
					url: '/api/users/ss/configurations',
				},
				show: {
					method: 'get',
					url: '/api/users/ss/configurations/:ssConfigurationId',
				},
				update: {
					method: 'put',
					url: '/api/users/ss/configurations/:ssConfigurationId',
				},
				refresh: {
					method: 'put',
					url: '/api/users/fitments/:fitmentId/ss/configurations/refresh',
				},
			},
		},
		salaryApprovals: {
			approve: {
				method: 'put',
				url: '/api/users/salary_approvals/:salaryApprovalId/approve',
			},
			reject: {
				method: 'put',
				url: '/api/users/salary_approvals/:salaryApprovalId/reject',
			},
			show: {
				method: 'get',
				url: '/api/users/salary_approvals/:salaryApprovalId',
			},
			comment: {
				method: 'post',
				url: '/api/users/salary_approvals/:salaryApprovalId/comment',
			},
			pendingApprovals: {
				method: 'get',
				url: '/api/users/salary_approvals/pending_approvals',
			},
		},
		fitmentApprovals: {
			show: {
				method: 'get',
				url: '/api/users/fitment_approvals/:fitmentApprovalId',
			},
			approve: {
				method: 'put',
				url: '/api/users/fitment_approvals/:fitmentApprovalId/approve',
			},
			reject: {
				method: 'put',
				url: '/api/users/fitment_approvals/:fitmentApprovalId/reject',
			},
			comment: {
				method: 'post',
				url: '/api/users/fitment_approvals/:fitmentApprovalId/comment',
			},
			pendingApprovals: {
				method: 'get',
				url: '/api/users/fitment_approvals/pending_approvals',
			},
		},
		exports: {
			index: {
				method: 'get',
				url: '/api/users/exports',
			},
			download: {
				method: 'get',
				url: '/api/users/exports/:exportId/url',
			},
		},
		imports: {
			index: {
				method: 'get',
				url: '/api/users/imports',
			},
			download: {
				method: 'get',
				url: '/api/users/imports/:importId/url',
			},
		},
		accessGroups: {
			index: {
				method: 'get',
				url: '/api/users/access_groups',
			},
			show: {
				method: 'get',
				url: '/api/users/access_groups/:accessGroupId',
			},
			update: {
				method: 'put',
				url: '/api/users/access_groups/:accessGroupId',
			},
			create: {
				method: 'post',
				url: '/api/users/access_groups',
			},
		},
		designations: {
			index: {
				method: 'get',
				url: '/api/users/designations',
			},
			import: {
				method: 'post',
				url: '/api/users/designations/import',
			},
		},
		control_features: {
			index: {
				method: 'get',
				url: '/api/users/control_features',
			},
			create: {
				method: 'post',
				url: '/api/users/control_features',
			},
			show: {
				method: 'get',
				url: '/api/users/control_features/:controlFeatureId',
			},
			update: {
				method: 'put',
				url: '/api/users/control_features/:controlFeatureId',
			},
			delete: {
				method: 'delete',
				url: '/api/users/control_features/:controlFeatureId',
			},
		},
		countries: {
			index: {
				method: 'get',
				url: '/api/users/countries',
			},
		},
		industries: {
			index: {
				method: 'get',
				url: '/api/users/industries',
			},
		},
		countryDetails: {
			index: {
				method: 'get',
				url: '/api/users/country_details/',
			},
			show: {
				method: 'get',
				url: '/api/users/country_details/:countryId',
			},
			update: {
				method: 'put',
				url: '/api/users/country_details/:countryId',
			},
			create: { method: 'post', url: '/api/users/country_details' },
		},
	},
	visitors: {
		fitmentApprovals: {
			show: {
				method: 'get',
				url: '/api/visitors/fitment_approvals/:token',
			},
			approve: {
				method: 'put',
				url: '/api/visitors/fitment_approvals/:token/approve',
			},
			reject: {
				method: 'put',
				url: '/api/visitors/fitment_approvals/:token/reject',
			},
			comment: {
				method: 'post',
				url: '/api/visitors/fitment_approvals/:token/comment',
			},
		},
		salaryApprovals: {
			show: {
				method: 'get',
				url: '/api/visitors/salary_approvals/:token',
			},
			approve: {
				method: 'put',
				url: '/api/visitors/salary_approvals/:token/approve',
			},
			reject: {
				method: 'put',
				url: '/api/visitors/salary_approvals/:token/reject',
			},
			comment: {
				method: 'post',
				url: '/api/visitors/salary_approvals/:token/comment',
			},
		},
		budgetApprovals: {
			approve: {
				method: 'put',
				url: '/api/visitors/budget_approvals/:token/approve',
			},
			reject: { method: 'put', url: '/api/visitors/budget_approvals/:token/reject' },
			show: {
				method: 'get',
				url: '/api/visitors/budget_approvals/:token',
			},
			comment: {
				method: 'post',
				url: '/api/visitors/budget_approvals/:token/comment',
			},
		},
	},
	adminUsers: {
		webServices: {
			index: { method: 'get', url: '/api/admin_users/web-services' },
			show: { method: 'get', url: '/api/admin_users/web-services/:webServiceId' },
			update: { method: 'post', url: '/api/admin_users/web-services/:webServiceId' },
		},
		tokens: {
			login: {
				method: 'post',
				url: '/api/admin_users/login',
			},
		},
		organizations: {
			index: {
				method: 'get',
				url: '/api/admin_users/organizations',
			},
			show: {
				method: 'get',
				url: '/api/admin_users/organizations/:organizationId',
			},
			update: {
				method: 'put',
				url: '/api/admin_users/organizations/:organizationId',
			},
			create: {
				method: 'post',
				url: '/api/admin_users/organizations',
			},
			users: {
				index: {
					method: 'get',
					url: '/api/admin_users/organizations/:organizationId/users',
				},
				show: {
					method: 'get',
					url: '/api/admin_users/organizations/:organizationId/users/:userId',
				},
				update: {
					method: 'put',
					url: '/api/admin_users/organizations/:organizationId/users/:userId',
				},
				create: {
					method: 'post',
					url: '/api/admin_users/organizations/:organizationId/users',
				},
			},
			organizationRoles: {
				index: {
					method: 'get',
					url: '/api/admin_users/organizations/:organizationId/organization_roles',
				},
			},
			defaultWorkforceReason: {
				method: 'get',
				url: '/api/admin_users/organizations/:organizationId/default_workforce_reason',
			},
		},
		countries: {
			index: {
				method: 'get',
				url: '/api/admin_users/countries',
			},
		},
		industries: {
			index: {
				method: 'get',
				url: '/api/admin_users/industries',
			},
		},
	},
}

export const createUrl = (url, replacements = {}) => {
	let createdUrl = url
	Object.keys(replacements).forEach(
		(key) => (createdUrl = createdUrl.replace(`:${key}`, replacements[key]))
	)
	return createdUrl
}

export const generic = async ({
	pageState,
	stateApiStatusKey,
	stateDataKey,
	stateErrorKey,
	apiEndpoint,
	apiUrlReplacements = {},
	apiData = {},
	errorMessage = null,
	serializedResponse = true,
}) => {
	if (!pageState) throw new Error('pageState is required')
	if (!stateApiStatusKey) throw new Error('stateApiStatusKey is required')
	if (!stateDataKey) throw new Error('stateDataKey is required')
	if (!stateErrorKey) throw new Error('stateErrorKey is required')
	if (!apiEndpoint) throw new Error('apiEndpoint is required')
	if (!apiEndpoint.method) throw new Error('apiEndpoint.method is required')
	if (!apiEndpoint.url) throw new Error('apiEndpoint.url is required')

	const { method, url: urlRaw } = apiEndpoint
	let response
	try {
		pageState[stateApiStatusKey] = 'pending'
		const url = createUrl(urlRaw, apiUrlReplacements)
		response = await call(apiClient()[method], url, apiData)
	} catch (e) {
		pageState[stateApiStatusKey] = 'rejected'

		if (e.name === 'ServerNotReachableError') {
			pageState.serverStatus = {
				status: 523,
				statusText: 'API Server is not reachable',
			}
			message.error('Unable to reach server, please try after some time')
			return
		}
		pageState.serverStatus = {
			status: 500,
			statusText: 'Oops!! something is went wrong. Please try later.',
		}
		message.error('An error occurred, please try after some time')
		throw e
	}

	// TODO: This needs to be improved
	// the variables are restrictive
	// code is not DRY
	if (response.status === 403 && response.data.errors.includes('Token has expired')) {
		Authhelper.logout()
		//message.warning("You need to login to do that.")
		if (window?.location?.pathname !== '/hirereview-sso-login')
			window.location = `${process.env.REACT_APP_FRONTEND_HOST}/login`
	}
	pageState['serverStatus'] = {
		status: response.status,
		statusText: response.statusText,
	}

	if (!serializedResponse) {
		pageState[stateApiStatusKey] = 'fulfilled'
		if (response.data[stateDataKey]) {
			pageState[stateDataKey] = response.data[stateDataKey]
		} else {
			pageState[stateErrorKey] = response.data.errors
		}
		return
	}
	if (response.data && response.data.multi_data) {
		if (!(stateDataKey instanceof Array))
			throw new Error('stateDataKey should be an array for multi-data response')
		if (!(stateErrorKey instanceof Array))
			throw new Error('stateErrorKey should be an array for multi-data response')
		if (stateDataKey.length !== stateErrorKey.length)
			throw new Error('stateErrorKey and stateErrorKey should have the same length')

		const promises = stateDataKey.map(async (k, index) => {
			if (!response.data[k]) return

			const deserialisedData = await deserialise(response.data[k])

			if (deserialisedData.meta) {
				pageState[`${k}Meta`] = deserialisedData.meta
			}

			if (deserialisedData.errors) {
				pageState[stateErrorKey[index]] = deserialisedData.errors
				return
			}
			if (
				deserialisedData.data instanceof Array ||
				!deserialisedData.data ||
				deserialisedData.data.id
			) {
				pageState[k] = deserialisedData?.data
				// if (deserialisedData.meta) {
				//   pageState[`${k}_meta`] = deserialisedData.meta
				// }
				delete pageState[stateErrorKey[index]]

				return
			}

			// FIXME: Need a better solution to handle non-serialized data
			if (deserialisedData.data && deserialisedData.data.redirect_url) {
				pageState[k] = deserialisedData.data.redirect_url
				delete pageState[stateErrorKey[index]]
				return
			}

			if (!!deserialisedData) {
				pageState[k] = deserialisedData
				return
			}

			if (errorMessage) message.error(errorMessage)
		})
		await Promise.all(promises)
		pageState[stateApiStatusKey] = 'fulfilled'

		return
	}

	const deserialisedData = await deserialise(response.data)

	if (deserialisedData.errors) {
		pageState[stateApiStatusKey] = 'fulfilled'

		if (stateErrorKey instanceof Array) {
			pageState[stateErrorKey[0]] = deserialisedData.errors
			return
		}
		pageState[stateErrorKey] = deserialisedData.errors
		return
	}

	if (
		Object.keys(deserialisedData).length === 0 ||
		deserialisedData.data instanceof Array ||
		!deserialisedData.data ||
		deserialisedData.data.id
	) {
		pageState[stateDataKey] = deserialisedData.data
		delete pageState[stateErrorKey]
		pageState[stateApiStatusKey] = 'fulfilled'
		return
	}

	if (errorMessage) message.error(errorMessage)
}
