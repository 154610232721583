import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Table, Select, DatePicker, Switch, message } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import FilterForm from 'components/budget-analysis/FilterForm'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { view } from '@risingstack/react-easy-state'
import { Redirect } from 'react-router-dom'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const { Column, ColumnGroup } = Table

const showOnlyColumnsOptions = ['monthly', 'quartely', 'halfyearly', 'yearly']

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`
const costComparisonIndex = [
	{
		dataIndex: 'display_planned_cost',
		label: 'Planned',
	},
	{
		dataIndex: 'display_actual_cost',
		label: 'Actual',
	},
	{
		dataIndex: 'display_unutilized',
		label: 'Unutilized',
	},
	{
		dataIndex: 'utilized_percentage',
		label: 'Utilized %',
	},
]

const countComparisonIndex = [
	{
		dataIndex: 'planned_unit',
		label: 'Planned',
	},
	{
		dataIndex: 'actual_unit',
		label: 'Actual',
	},
	{
		dataIndex: 'unutilized_unit',
		label: 'Unutilized',
	},
	{
		dataIndex: 'utilized_unit_percentage',
		label: 'Utilized %',
	},
]

const BudgetMonitoringUtilizationReportPage = () => {
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [showOnlyColumns, setShowOnlyColumns] = useState(showOnlyColumnsOptions)
	// const [tableDataSource, setTableDataSource] = useState([])
	const [tableLoading, setTableLoading] = useState(false)
	const [count, setCount] = useState(false)
	const [filterValue, setFilterValue] = useState({})
	const [essentialPosition, setEssentialPosition] = useState(0)
	const pageState = globalStore.ui.configuration.budgetHeads.workForceMonitoring
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod

	let comparisonIndex = count ? costComparisonIndex : countComparisonIndex
	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		globalStore.siderCollapsed = true

		return () => {
			globalStore.siderCollapsed = false
		}
	}, [])
	const defaultPeriod = [moment.utc().set('month', 3), moment.utc().set('month', 4).add('years', 1)]

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.start_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc().set('month', 3)
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc().set('month', 4).add('years', 1)
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	useEffect(() => {
		const fetchWorkForceTimeline = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.workForceMonitoring
			const { criteria } = filterValue

			const postData = {
				criteria: criteria?.criteria,
				start_date: dateRange
					? dateRange[0].startOf('month').format()
					: defaultPeriod[0].startOf('month').format(),
				end_date: dateRange
					? dateRange[1].endOf('month').format()
					: defaultPeriod[1].endOf('month').format(),
			}
			setTableLoading(true)
			await BudgetHeadsService.workforceMonitor({ pageState, postData })
			setTableLoading(false)
			const { serverStatus, dataErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "WorkForce Timeline criteria couldn't be fetched.")
				return
			}
		}
		fetchWorkForceTimeline()
		// setTableDataSource(data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange])

	const { workforce_moniter_data, API_USERS_WORKFORCE_MONITOR_STATUS } = pageState
	const handleCountSwitch = (value) => {
		setCount(value)
	}

	const renderData = (value, dataIndex) => {
		const data = !['utilized_percentage', 'utilized_unit_percentage'].includes(dataIndex)
			? value
			: `${value} %`
		return count ? data : value
	}

	const tableDataSource = () => {
		const tableData = workforce_moniter_data?.moniter_data?.map((timelineData, index) => {
			const mainData = timelineData.data
			const yearOne =
				mainData[dateRange ? dateRange[0].format('YYYY') : defaultPeriod[0].format('YYYY')]
			const yearTwo =
				mainData[dateRange ? dateRange[1].format('YYYY') : defaultPeriod[1].format('YYYY')]
			const rows = comparisonIndex.map((ci, i) => {
				const apr = renderData(yearOne?.apr ? yearOne?.apr[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const may = renderData(yearOne?.may ? yearOne?.may[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const jun = renderData(yearOne?.jun ? yearOne?.jun[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const jul = renderData(yearOne?.jul ? yearOne?.jul[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const aug = renderData(yearOne?.aug ? yearOne?.aug[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const sep = renderData(yearOne?.sep ? yearOne?.sep[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const oct = renderData(yearOne?.oct ? yearOne?.oct[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const nov = renderData(yearOne?.nov ? yearOne?.nov[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const dec = renderData(yearOne?.dec ? yearOne?.dec[ci?.dataIndex] || 0 : 0, ci?.dataIndex)

				const jan = renderData(yearTwo?.jan ? yearTwo?.jan[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const feb = renderData(yearTwo?.feb ? yearTwo?.feb[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const mar = renderData(yearTwo?.mar ? yearTwo?.mar[ci?.dataIndex] || 0 : 0, ci?.dataIndex)

				const q1 = renderData(yearOne?.amj ? yearOne?.amj[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const q2 = renderData(yearOne?.jas ? yearOne?.jas[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const q3 = renderData(yearOne?.ond ? yearOne?.ond[ci?.dataIndex] || 0 : 0, ci?.dataIndex)
				const q4 = renderData(yearTwo?.jfm ? yearTwo?.jfm[ci?.dataIndex] || 0 : 0, ci?.dataIndex)

				const firstHalfYear = yearOne?.first_half ? yearOne?.first_half[ci?.dataIndex] || 0 : 0
				const secondHalfYear = yearOne?.second_half ? yearOne?.second_half[ci?.dataIndex] || 0 : 0

				const year = mainData?.yearly ? mainData?.yearly[ci?.dataIndex] || 0 : 0

				const data = {
					key: Math.random(),
					rowKey: index,
					rowMatchKey: i,
					comparisonIndex: ci.label,
					apr: apr,
					may: may,
					jun: jun,
					q1: q1,
					jul: jul,
					aug: aug,
					sep: sep,
					q2: q2,
					firstHalfYear: firstHalfYear,
					oct: oct,
					nov: nov,
					dec: dec,
					q3: q3,
					jan: jan,
					feb: feb,
					mar: mar,
					q4: q4,
					secondHalfYear: secondHalfYear,
					year: year,
				}
				const returnData = {
					...mainData,
					...data,
				}
				return returnData
			})
			return rows
		})
		return tableData?.flat(Infinity)
	}

	const handleShowEssential = (pagination, filters) => {
		if (filters?.comparisonIndex?.includes('Planned')) {
			setEssentialPosition(0)
			return
		}
		if (filters?.comparisonIndex?.includes('Actual')) {
			setEssentialPosition(1)
			return
		}
		if (filters?.comparisonIndex?.includes('Unutilized')) {
			setEssentialPosition(2)
			return
		}
		if (filters?.comparisonIndex?.includes('Utilized %')) {
			setEssentialPosition(3)
			return
		}
	}

	const renderContents = () => {
		const renderTable = () => {
			if (showOnlyColumnsOptions.length <= 0) {
				return
			}
			return (
				<Table
					dataSource={tableDataSource()}
					pagination={{
						defaultPageSize: 12,
					}}
					bordered
					loading={tableLoading}
					scroll={{
						x: 'max-content',
					}}
					className='incremental-timeline'
					onChange={handleShowEssential}
					style={{
						margin: '20px 0px',
					}}>
					{workforce_moniter_data?.criteria_header_for_report &&
						workforce_moniter_data?.criteria_header_for_report.map((data, index) => (
							<Column
								key={index}
								title={data?.title}
								dataIndex={data?.key}
								fixed={true}
								render={(value, row, index) => {
									const obj = {
										children: value,
										props: {},
									}
									if (row?.rowMatchKey !== essentialPosition) {
										obj.children = null
									}
									return obj
								}}
							/>
						))}

					<Column
						title='Comparison Index'
						dataIndex='comparisonIndex'
						key='comparisonIndex'
						fixed={true}
						width={130}
						filters={comparisonIndex.map((ci) => ({
							text: ci.label,
							value: ci.label,
						}))}
						onFilter={(value, record) => {
							return record.comparisonIndex.includes(value)
						}}
					/>
					{(showOnlyColumns.includes('monthly') || showOnlyColumns.includes('quartely')) && (
						<ColumnGroup title='H1(Apr-Sep)' className='H1'>
							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q1(AMJ)'>
									<Column title='Apr' dataIndex='apr' key='apr' style={{ background: 'red' }} />
									<Column title='May' dataIndex='may' key='may' />
									<Column title='Jun' dataIndex='jun' key='jun' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q1' key='q1' className='colored-bg' />
							)}

							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q2(JAS)'>
									<Column title='Jul' dataIndex='jul' key='jul' />
									<Column title='Aug' dataIndex='aug' key='aug' />
									<Column title='Sep' dataIndex='sep' key='sep' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q2' key='q2' className='colored-bg' />
							)}
						</ColumnGroup>
					)}
					{showOnlyColumns.includes('halfyearly') && (
						<Column
							title='H1 Yearly Total'
							dataIndex='firstHalfYear'
							key='firstHalfYear'
							className='colored-bg'
						/>
					)}
					{(showOnlyColumns.includes('monthly') || showOnlyColumns.includes('quartely')) && (
						<ColumnGroup title='H2(Oct-Mar)' className='H2'>
							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q3(OND)'>
									<Column title='Oct' dataIndex='oct' key='oct' />
									<Column title='Nov' dataIndex='nov' key='nov' />
									<Column title='Dec' dataIndex='dec' key='dec' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q3' key='q3' className='colored-bg' />
							)}

							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q4(JFM)'>
									<Column title='Jan' dataIndex='jan' key='jan' />
									<Column title='Feb' dataIndex='feb' key='feb' />
									<Column title='Mar' dataIndex='mar' key='mar' />
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q4' key='q4' className='colored-bg' />
							)}
						</ColumnGroup>
					)}
					{showOnlyColumns.includes('halfyearly') && (
						<Column
							title='H2 Yearly Total'
							dataIndex='secondHalfYear'
							key='secondHalfYear'
							className='colored-bg'
						/>
					)}
					{showOnlyColumns.includes('yearly') && (
						<Column title='Year' dataIndex='year' key='year' className='colored-bg-dark' />
					)}
				</Table>
			)
		}

		return <div>{renderTable()}</div>
	}

	const handleFilterChange = ({ criteria, start_date, end_date }) => {
		setFilterValue({ criteria, start_date, end_date })
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div>
					<FilterForm
						dateRange={dateRange}
						setDateRange={setDateRange}
						type='workforce-monitoring'
						onFilterChange={handleFilterChange}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 20,
						marginTop: 20,
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Period</div>
						<DatePicker.RangePicker
							value={dateRange}
							picker='month'
							format='YYYY MMM'
							onChange={setDateRange}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Count</div>
						<Switch checked={count} onChange={handleCountSwitch} />
						<div>Cost</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-between',
							alignItems: 'center',
							gap: 20,
						}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: 10,
							}}>
							<div>Show only</div>
							<div>
								<Select
									mode='multiple'
									defaultValue={showOnlyColumns}
									style={{ minWidth: 350 }}
									onChange={setShowOnlyColumns}>
									{showOnlyColumnsOptions.map((item) => (
										<Select.Option key={item} value={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</div>
						</div>
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content incremental-timeline'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_WORKFORCE_MONITOR_STATUS,
					data: workforce_moniter_data?.criteria_header_for_report,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetMonitoringUtilizationReportPage)
