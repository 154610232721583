import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Button, Input, message, Row, Col, Select } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import globalStore from 'store/index'

import OrganizationsService from 'services/users/organizations.service'
import WebService from 'services/admin-users/web-services.service'

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const WebServiceEditPage = () => {
	const history = useHistory()
	const { webServiceId, organizationId } = useParams()
	const [createButtonLoading, setCreateButtonLoading] = useState(false)
	const [inputValues, setInputValues] = useState({})

	useEffect(() => {
		const fetchMinimumWage = async () => {
			const pageState = globalStore.ui.adminUsers.webServices.show

			await WebService.show({
				pageState,
				webServiceId,
				organizationId,
			})

			const { serverStatus, web_service, webServiceErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (webServiceErrors) {
				message.error("Web service couldn't be fetched.")
				return
			}

			const { name, type, status, web_service_url, service_type, username } = web_service

      setInputValues({
        username,
				name,
				type,
				status,
				web_service_url,
				service_type,
			})
		}

		if (organizationId) fetchMinimumWage()
	}, [organizationId, webServiceId])

	useEffect(() => {
		const pageState = globalStore.currentOrganization
		const organizationFields = pageState.organization?.all_fields_with_datatype
		if (organizationFields && organizationFields.length !== 0) return

		const fetchAllFieldsData = async () => {
			await OrganizationsService.me({ pageState })

			const { organization } = pageState

			if (!organization?.all_fields_with_datatype) {
				message.error('Organization fields not found')
				return
			}
		}

		fetchAllFieldsData()
	}, [])

	const handleInputChange = (e, inputType) => {
		let newInputValues = null

		if (inputType === 'status') {
			newInputValues = { ...inputValues, [inputType]: e }
		} else {
			newInputValues = { ...inputValues, [inputType]: e.target.value }
		}

		setInputValues(newInputValues)
	}

	const handleUpdate = async () => {
		// if (!inputValues['name']) {
		// 	message.error("Name can't be blank")
		// 	return
		// }
		// if (!inputValues['type']) {
		// 	message.error("Type can't be blank")
		// 	return
		// }
		// if (!inputValues['status']) {
		// 	message.error("Status can't be blank")
		// 	return
		// }
		// if (!inputValues['web_service_url']) {
		// 	message.error("Web Service Url can't be blank")
		// 	return
		// }
		// if (!inputValues['service_type']) {
		// 	message.error("Service Type can't be blank")
		// 	return
		// }

		const pageState = {}
		const postData = {
			web_service: {
				...inputValues,
			},
		}

		setCreateButtonLoading(true)
		await WebService.update({
			pageState,
			values: postData,
			webServiceId,
			organizationId,
		})
		setCreateButtonLoading(false)

		const { serverStatus, webServiceErrors } = pageState

		if (webServiceErrors) {
			message.error(webServiceErrors[0] || 'Failed to update minimum wage')
			return
		}

		if (serverStatus.status === 200) {
			message.success('Organization minimum salary updated.')
			history.push(-1)
			setInputValues({})

			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		return (
			<StyledConfigurationFormBox>
				<h1>Api Web Service Update</h1>
				<Row gutter={20}>
					<Col span={12}>
						<div className='input-box'>
							<strong>User Name</strong>
							<Input
								value={inputValues['username']}
								onChange={(e) => handleInputChange(e, 'username')}
							/>
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<strong>Name</strong>
							<Input value={inputValues['name']} onChange={(e) => handleInputChange(e, 'name')} />
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<strong>Type</strong>
							<Input value={inputValues['type']} onChange={(e) => handleInputChange(e, 'type')} />
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<strong>Status</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose Status'
								value={inputValues['status']}
								onChange={(value) => handleInputChange(value, 'status')}>
								<Select.Option value='Active'>Active</Select.Option>
								<Select.Option value='InActive'>InActive</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<strong>Service Type</strong>
							<Input
								value={inputValues['service_type']}
								onChange={(e) => handleInputChange(e, 'service_type')}
							/>
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<strong>Web Service Url</strong>
							<Input
								value={inputValues['web_service_url']}
								onChange={(e) => handleInputChange(e, 'web_service_url')}
							/>
						</div>
					</Col>
				</Row>
				<div className='action-buttons submit'>
					<Button type='primary' loading={createButtonLoading} onClick={handleUpdate}>
						Save
					</Button>
				</div>
			</StyledConfigurationFormBox>
		)
	}

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(WebServiceEditPage)
