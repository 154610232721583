import React, { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import { EyeOutlined, DownOutlined, DeleteOutlined } from '@ant-design/icons'
import {
	message,
	Button,
	Tooltip,
	Pagination,
	Input,
	Table,
	Select,
	Menu,
	Dropdown,
	DatePicker,
	Popconfirm,
} from 'antd'

import { hideExportButtons } from 'utils/export'
import StyledBox from 'components/common/styled-components/StyledBox'
import FitmentsService from 'services/users/fitments.service'
import { staleWhileRevalidate } from 'utils/render-strategies'

import globalStore from 'store/index'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const FitmentsIndexPage = () => {
	const history = useHistory()
	const pageState = globalStore.ui.fitments.index
	const [fitments, setFitments] = useState(null)
	const [syncing, setSyncing] = useState(false)
	const [exportingFitments, setExportingFitments] = useState(false)
	const [exportingSalaries, setExportingSalaries] = useState(false)
	const [exportAuditLog, setExportAuditLog] = useState(false)
	const [exportConsolidatedReport, setExportConsolidatedReport] = useState(false)
	const [tableDataLoading, setTableDataLoading] = useState(false)
	const [selectedFilterKey, setSelectedFilterKey] = useState(null)
	const [searchValue, setSearchValue] = useState(null)
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [selectedRowKeys, setSelectedRowKeys] = useState([])
	const [selectedIds, setSelectedIds] = useState()
	const accessibleFeatures = globalStore.currentUser?.accessible_features || []
	const enableFitmentDeletion = globalStore.currentOrganization?.enable_fitment_deletion

	useEffect(() => {
		const fetchData = async () => {
			const pageState = globalStore.ui.fitments.index
			setTableDataLoading(true)
			await FitmentsService.index({ pageState })
			setTableDataLoading(false)

			const { fitments } = pageState

			if (fitments) {
				setFitments(fitments)
				return
			}

			message.error('Failed to load fitments')
		}

		fetchData()
	}, [])

	const {
		fitmentsMeta,
		// displayType = "table",
	} = pageState

	const repaginate = async (page, pageSize, email) => {
		const tempState = {}

		setTableDataLoading(true)

		if (email) {
			await FitmentsService.index({
				pageState: tempState,
				page,
				pageSize,
				email,
			})
		} else {
			await FitmentsService.index({ pageState: tempState, page, pageSize })
		}

		setTableDataLoading(false)

		const { fitments, fitmentsMeta } = tempState

		if (fitments) {
			setFitments(fitments)
			pageState.fitmentsMeta = fitmentsMeta
			return
		}
	}

	const { total, page: currentPage, page_size: pageSize } = fitmentsMeta || {}

	const pagination = (
		<Pagination
			current={currentPage}
			total={total}
			onChange={repaginate}
			onShowSizeChange={repaginate}
			pageSize={pageSize || 25}
			pageSizeOptions={[10, 25, 50, 100]}
		/>
	)

	const handleFitmentClick = (fitmentId) => {
		if (!fitmentId) return null
		history.push(`/fitments/${fitmentId}`)
	}

	const handleFitmentDeleteClick = async (e, fitmentId) => {
		if (!fitmentId) return null
		e.stopPropagation()
		const tempState = {}

		await FitmentsService.delete({ pageState: tempState, fitmentId })

		const { message: messages, fitmentErrors } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Failed to delete Fitment')
			return
		}

		setFitments(fitments.filter((data) => data.id !== fitmentId))
		message.success(messages)
	}

	const handleFitmentMultipleDeleteClick = async (e) => {
		e.stopPropagation()
		const tempState = {}
		const values = { fitment_ids: selectedIds }
		await FitmentsService.deleteMultiple({ pageState: tempState, values })
		
		const { message: messages, fitmentErrors } = tempState

		if (fitmentErrors) {
			message.error(fitmentErrors[0] || 'Failed to delete Fitment')
			return
		}

		setFitments(fitments.filter((data) => !selectedIds.includes(data.id)))
		message.success(messages)
		setSelectedRowKeys([])
	}

	const formatFitments = () => {
		if (!fitments) return []
		if (!Array.isArray(fitments)) return []

		return fitments.map((fitment) => {
			return {
				key: fitment.id,
				fitment_id: fitment.id,
				status: fitment.status,
				ref_number: fitment?.job?.ref_number,
				...fitment['candidate'],
			}
		})
	}

	const handleExportFitments = async () => {
		const tempState = {}
		setExportingFitments(true)
		const params = {
			start_date: dateRange ? dateRange[0] : undefined,
			end_date: dateRange ? dateRange[1] : undefined,
		}
		await FitmentsService.export({ pageState: tempState, params })
		setExportingFitments(false)

		const { serverStatus } = tempState

		if (serverStatus?.status === 200) {
			message.destroy()
			message.info('Export started')
			return
		}
	}

	const handleExportSalaries = async () => {
		const tempState = {}
		setExportingSalaries(true)
		const params = {
			start_date: dateRange ? dateRange[0] : undefined,
			end_date: dateRange ? dateRange[1] : undefined,
		}
		await FitmentsService.exportSalaries({ pageState: tempState, params })
		setExportingSalaries(false)

		const { serverStatus } = tempState

		if (serverStatus?.status === 200) {
			message.destroy()
			message.info('Export started')
			return
		}
	}

	const handleSyncFitments = async () => {
		const tempState = {}
		setSyncing(true)
		await FitmentsService.pull({ pageState: tempState })
		setSyncing(false)

		const { fitments, fitmentsErrors } = tempState

		message.destroy()
		if (fitmentsErrors) {
			message.error(fitmentsErrors[0] || 'Fitment syncing failed')
			return
		}

		message.success('Fitments data synced with SR')
		setFitments(fitments)
	}

	const handleAuditReports = async () => {
		const tempState = {}
		setExportAuditLog(true)
		const params = {
			start_date: dateRange ? dateRange[0] : undefined,
			end_date: dateRange ? dateRange[1] : undefined,
		}
		await FitmentsService.auditReports({ pageState: tempState, params })
		setExportAuditLog(false)

		const { serverStatus } = tempState

		if (serverStatus?.status === 200) {
			message.destroy()
			message.info('Export audit reports started')
			return
		}
	}

	const handleExportConsolidatedReport = async () => {
		const tempState = {}
		setExportConsolidatedReport(true)
		const params = {
			start_date: dateRange ? dateRange[0] : undefined,
			end_date: dateRange ? dateRange[1] : undefined,
		}
		await FitmentsService.consolidateReport({ pageState: tempState, params })
		setExportConsolidatedReport(false)

		const { serverStatus } = tempState

		if (serverStatus?.status === 200) {
			message.destroy()
			message.info('Export consolidate report started')
			return
		}
	}

	// const exportMenuItems = ["Fitments", "Salaries", "Audit Log", "Consolidate Report"]
	const exportButtonMenu = (
		<Menu>
			<Menu.Item key='0'>
				<Button type='link' ghost loading={exportingFitments} onClick={handleExportFitments}>
					{exportingFitments ? 'Exporting Fitments...' : 'Export Fitments'}
				</Button>
			</Menu.Item>
			<Menu.Item key='1'>
				<Button
					type='link'
					ghost
					loading={exportingSalaries}
					// style={{ marginLeft: 10 }}
					onClick={handleExportSalaries}>
					{exportingSalaries ? 'Exporting Salaries...' : 'Export Salaries'}
				</Button>
			</Menu.Item>
			<Menu.Item key='2'>
				<Button
					type='link'
					ghost
					loading={exportAuditLog}
					// style={{ marginLeft: 10 }}
					onClick={handleAuditReports}>
					{exportAuditLog ? 'Exporting Audit Log...' : 'Export Audit Log'}
				</Button>
			</Menu.Item>
			<Menu.Item key='3'>
				<Button
					type='link'
					ghost
					loading={exportConsolidatedReport}
					// style={{ marginLeft: 10 }}
					onClick={handleExportConsolidatedReport}>
					{exportConsolidatedReport
						? 'Exporting Consolidated Report...'
						: 'Export Consolidate Report'}
				</Button>
			</Menu.Item>
		</Menu>
	)

	const statusColor = (status) => {
		if (['details_updated', 'validated', 'in_progress', 'docusign_inprogress'].includes(status))
			return 'orange'
		if (['approved', 'docusign_completed'].includes(status)) return 'green'
		if (['rejected', 'cancelled', 'docusign_declined'].includes(status)) return 'red'
		return 'gray'
	}

	const handleFilterSelectInputChange = (value) => {
		if (!value) return
		setSelectedFilterKey(value)
	}

	const handeSearch = async (value) => {
		if (!value) return

		setSearchValue(value)

		const tempState = {}

		const searchData = {
			label: selectedFilterKey,
			value,
		}

		setTableDataLoading(true)
		await FitmentsService.search({ pageState: tempState, values: searchData })
		setTableDataLoading(false)

		const { fitments } = tempState

		if (fitments) {
			setFitments(fitments)
			return
		}
	}

	const handleTableChange = async (pagination, filters, sorter) => {
		const tempState = {}
		const searchData = {
			label: selectedFilterKey,
			value: searchValue,
			statuses: filters.status,
		}

		setTableDataLoading(true)
		await FitmentsService.search({ pageState: tempState, values: searchData })
		setTableDataLoading(false)

		const { fitments } = tempState

		if (fitments) {
			setFitments(fitments)
			return
		}
	}

	const handleDateChange = (range) => {
		setDateRange(range)
	}

	const handleClearFilter = async () => {
		const tempState = {}
		setSelectedFilterKey(null)
		setSearchValue(null)
		setTableDataLoading(true)
		await FitmentsService.index({ pageState: tempState })
		setTableDataLoading(false)

		const { fitments } = pageState

		if (fitments) {
			setFitments(fitments)
			return
		}
	}

	const renderFitments = () => {
		const formattedFitments = formatFitments()

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: 'REF NUMBER',
				dataIndex: 'ref_number',
				key: 'ref_number',
			},
			{
				title: 'Status',
				dataIndex: 'status',
				key: 'status',
				filters: [
					{
						text: 'Pending',
						value: 'pending',
					},
					{
						text: 'Validated',
						value: 'validated',
					},
					{
						text: 'Approved',
						value: 'approved',
					},
					{
						text: 'In progress',
						value: 'in_progress',
					},
					{
						text: 'Cancelled',
						value: 'cancelled',
					},
					{
						text: 'Docusign In Progress',
						value: 'docusign_inprogress',
					},
					{
						text: 'Docusign Completed',
						value: 'docusign_completed',
					},
					{
						text: 'Docusign Declined',
						value: 'docusign_declined',
					},
				],
				render: (status, row) => {
					return (
						<div className='fitment__status-box'>
							<Tooltip title={status && status.split('_').join(' ')}>
								<div className={`fitment__status ${statusColor(status)}`}></div>
							</Tooltip>
							<span>{status.split('_').join(' ')}</span>
						</div>
					)
				},
			},
			{
				title: 'Action',
				dataIndex: 'action',
				key: 'action',
				align: 'right',
				render: (action, row) => {
					return (
						<div className='icon-box'>
							<Button type='link' onClick={() => handleFitmentClick(row.fitment_id)}>
								<EyeOutlined />
							</Button>
							{enableFitmentDeletion && (
								<Popconfirm
									title='Are you sure?'
									onConfirm={(e) => handleFitmentDeleteClick(e, row.fitment_id)}
									onCancel={() => {}}
									okText='Yes'
									cancelText='No'>
									<Button type='link'>
										<DeleteOutlined />
									</Button>
								</Popconfirm>
							)}
						</div>
					)
				},
			},
		]

		const rowSelection = enableFitmentDeletion
			? {
					type: 'checkbox',
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						setSelectedRowKeys(selectedRowKeys)
						setSelectedIds(selectedRows.map((data) => data.fitment_id))
					},
			  }
			: null

		return (
			<div style={{ margin: '10px 0px' }}>
				<Table
					rowSelection={rowSelection}
					columns={columns}
					dataSource={formattedFitments}
					loading={tableDataLoading}
					onChange={handleTableChange}
					pagination={false}
				/>
			</div>
		)
	}

	const renderPageContent = () => {
		const emailSearch = (
			<Input.Search
				placeholder='Email/Name'
				onSearch={(email) => repaginate(1, pageSize, email)}
				style={{ width: 200, marginBottom: '20px' }}
			/>
		)

		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<div>{emailSearch}</div>
					<div
						className='action-buttons top'
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							marginBottom: '20px',
						}}>
						{hideExportButtons(accessibleFeatures) && (
							<>
								<div className='input-box'>
									<strong style={{ padding: '0 5px' }}>Period :</strong>
									<DatePicker.RangePicker
										style={{ marginRight: 10 }}
										format='DD-MMM-YYYY'
										value={dateRange}
										onChange={handleDateChange}
									/>
								</div>
								<Dropdown overlay={exportButtonMenu}>
									<Button>
										Export <DownOutlined />
									</Button>
								</Dropdown>
							</>
						)}
						{enableFitmentDeletion && (
							<Popconfirm
								placement='bottom'
								title='Are you sure?'
								onConfirm={handleFitmentMultipleDeleteClick}
								onCancel={() => {}}
								okText='Yes'
								cancelText='No'>
								<Button type='danger' ghost style={{ marginLeft: 10 }}>
									<DeleteOutlined />
								</Button>
							</Popconfirm>
						)}
						<Button
							type='primary'
							ghost
							loading={syncing}
							style={{ marginLeft: 10 }}
							onClick={handleSyncFitments}>
							{syncing ? 'Syncing...' : 'Synchronize'}
						</Button>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						gap: 10,
					}}>
					{pagination}
					<div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
						{selectedFilterKey ? (
							<Button type='link' onClick={handleClearFilter}>
								Clear filter
							</Button>
						) : null}
						<Select
							placeholder='Filter on'
							style={{ width: 200 }}
							value={selectedFilterKey}
							onChange={handleFilterSelectInputChange}>
							<Select.Option disabled>Filter on</Select.Option>
							{['Role Band', 'Job Location', 'BCSLA'].map((option, index) => (
								<Select.Option key={`option-${index}`} value={option}>
									{option}
								</Select.Option>
							))}
						</Select>
						<Input.Search
							placeholder='Enter value to search'
							value={searchValue}
							disabled={!selectedFilterKey}
							onSearch={handeSearch}
							onChange={(e) => setSearchValue(e.target.value)}
						/>
					</div>
				</div>
				{renderFitments()}
				{pagination}
			</div>
		)
	}

	const { API_FITMENTS_INDEX_STATUS } = pageState

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('offer-view'))
		return <Redirect to='/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_FITMENTS_INDEX_STATUS,
					data: fitments,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(FitmentsIndexPage)
