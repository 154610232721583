import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { message, Button } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import { Color } from 'constants/theme'
import APiLogsService from 'services/users/api-logs.service'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
			// box-shadow: 0px 5px 20px #0000000d;
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
		}
	}
`

const APILogsShowPage = (props) => {
	const pageState = globalStore.ui.apiLogs.show
	const history = useHistory()
	const { apiLogId } = useParams()

	useEffect(() => {
		const fetchMinimumWage = async () => {
			const pageState = globalStore.ui.apiLogs.show

			await APiLogsService.show({
				pageState,
				apiLogId,
			})

			const { serverStatus, apiLogConfigurationErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (apiLogConfigurationErrors) {
				message.error(apiLogConfigurationErrors[0] || "Api Logs couldn't be fetched.")
				return
			}
		}

		fetchMinimumWage()
	}, [apiLogId])

	const handleEdit = () => {
		history.push(`/configuration/api-logs/${apiLogId}/edit`)
	}
	const { api_log, API_USERS_API_LOGS_SHOW_STATUS } = pageState

	const renderContent = () => {
		if (!api_log) return null

		const { api_service_name, loggable_type, status, request_body, response_body } = api_log
		return (
			<div>
				<h1>{api_service_name}</h1>
				<StyledItemList>
					<div>
						<div className='item-row colored'>
							<div className='label'>Log Type</div>
							<div>{loggable_type}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Status</div>
							<div>{status}</div>
						</div>
					</div>
						<div>
							<h1 className='label'>Request Body</h1>
							<div
								style={{
									wordBreak: 'break-all',
									backgroundColor: '#f8f9fa',
									border: '1px solid #ddd',
									padding: '10px',
									borderRadius: 5,
									whiteSpace: 'pre-wrap',
								}}>
								{request_body}
							</div>
						</div>
						<div>
							<h1 className='label'>Response Body</h1>
							<div
								style={{
									wordBreak: 'break-all',
									backgroundColor: '#f8f9fa',
									border: '1px solid #ddd',
									padding: '10px',
									borderRadius: 5,
									whiteSpace: 'pre-wrap',
								}}>
								{JSON.stringify(response_body)}
							</div>
						</div>
				</StyledItemList>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button type='primary' ghost onClick={handleEdit}>
						Edit
					</Button>
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	// const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	// if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('fitment-data-view'))
	// 	return <Redirect to='/configuration/api-logs' />

	const strategy = api_log && apiLogId === api_log.id ? staleWhileRevalidate : networkOnly

	return (
		<div className='page-content api-logs-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_API_LOGS_SHOW_STATUS,
					data: api_log,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(APILogsShowPage)
