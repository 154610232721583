import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import globalStore from 'store/index'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { DatePicker, Menu, Dropdown, Button, Table, message } from 'antd'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'

import StyledBox from 'components/common/styled-components/StyledBox'
import { Redirect } from 'react-router-dom'
import FilterForm from 'components/budget-analysis/FilterForm'
import { view } from '@risingstack/react-easy-state'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`

const currentYear = new Date().getFullYear()

const modules = ['Workforce Plan', 'Budget Management']
const moduleColumnName = {
	'Workforce Plan': {
		c1Name: 'Workforce Plan Headcount Index',
		c2Name: 'Workforce Plan Cost Index',
		budgeted: 'Planned',
		available: 'Occupied',
		vacant: 'Vacant',
	},
	'Budget Management': {
		c1Name: 'Count Index',
		c2Name: 'Cost Index',
		budgeted: 'Planned',
		available: 'Actual',
		vacant: 'Unutilized',
	},
}
const RecruitmentStaffingReportPage = () => {
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [costIn, setCostIn] = useState(1)
	const [currency, setCurrency] = useState('₹')
	const [tableLoading, setTableLoading] = useState(false)
	const [currentModule, setCurrentModule] = useState(modules[0])
	const [filterValue, setFilterValue] = useState({})
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod
	const defaultPeriod = [
		moment.utc(`04/01/ ${currentYear}`).startOf('month'),
		moment.utc(`03/31/ ${currentYear + 1}`).startOf('month'),
	]
	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		globalStore.siderCollapsed = true

		return () => {
			globalStore.siderCollapsed = false
		}
	}, [])

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.start_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc(`04/01/ ${currentYear}`).startOf('month')
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc(`03/31/ ${currentYear + 1}`).startOf('month')
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	const pageState = globalStore.ui.configuration.budgetHeads.workforce_data

	const handlePeriodChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])
		}
	}
	const costSelectionDigits = () => {
		const MAX_COST_SELECTION_DIGIT = 100000
		let costSelections = []
		let i = 1
		while (i <= MAX_COST_SELECTION_DIGIT) {
			costSelections.push(i)
			i *= 10
		}
		return costSelections
	}
	const costMenu = (
		<Menu>
			{costSelectionDigits().map((e, i) => (
				<Menu.Item key={i}>
					<div onClick={() => setCostIn(e)}>{e}</div>
				</Menu.Item>
			))}
		</Menu>
	)

	const currencyMenu = (
		<Menu>
			{[...'$', '₹'].map((e, i) => (
				<Menu.Item key={i}>
					<div onClick={() => setCurrency(e)}>{e}</div>
				</Menu.Item>
			))}
		</Menu>
	)
	const modulesMenu = (
		<Menu>
			{modules.map((e, i) => (
				<Menu.Item key={i}>
					<div onClick={() => setCurrentModule(e)}>{e}</div>
				</Menu.Item>
			))}
		</Menu>
	)

	useEffect(() => {
		const fetchWorkforceData = async () => {
			const { criteria } = filterValue
			let postData = {}
			if (dateRange) {
				postData = {
					criteria: criteria?.criteria,
					start_date: dateRange[0].startOf('month').format(),
					end_date: dateRange[1].endOf('month').format(),
					status: 'active',
				}
			} else {
				postData = {
					criteria: criteria?.criteria,
					start_date: defaultPeriod[0].startOf('month').format(),
					end_date: defaultPeriod[1].endOf('month').format(),
					status: 'active',
				}
			}
			setTableLoading(true)
			await BudgetHeadsService.workforceReport({ pageState, postData })
			setTableLoading(false)
			const { serverStatus, dataErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "Budget utilization data couldn't be fetched.")
				return
			}
		}

		fetchWorkforceData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange, costIn])

	const getTableDataSource = (data) => {
		// let workforceData = []
		const workforceData = data?.map((wfData) => {
			const criterias = { ...wfData.data, key: Math.random() }
			return criterias
			// let location = wfData.criterias["location"]?.join(">")
			// let bandgrade = wfData.criterias["band_grade"]?.join(">")
			// let workSite = wfData.criterias["work_site"]?.join(">")
			// let role = wfData.criterias["role"]?.join(">")
			// let budgeted = wfData.data?.budgeted
			// let available = wfData.data?.available
			// let vacancy = wfData.data?.vacancy
			// let planned = wfData.data?.planned
			// let actual = wfData.data?.actual
			// let unutilized = wfData.data?.unutilized
			// workforceData.push({
			// 	key: 1,
			// 	worksite: workSite,
			// 	bandgrade: bandgrade,
			// 	location: location,
			// 	role: role,
			// 	budgeted: budgeted,
			// 	available: available,
			// 	vacancy: vacancy,
			// 	planned: planned,
			// 	actual: actual,
			// 	unutilized: unutilized,
			// })
		})
		return workforceData || []
	}

	const getColumns = (data) => {
		const columns = [
			// {
			// 	title: 'Work Site',
			// 	dataIndex: 'work_site',
			// 	key: 'work_site',
			// },
			// {
			// 	title: 'Band Grade',
			// 	width: 50,
			// 	dataIndex: 'bandgrade',
			// 	key: 'bandgrade',
			// },
			// {
			// 	title: 'Location',
			// 	dataIndex: 'location',
			// 	key: 'location',
			// },
			// {
			// 	title: 'Role',
			// 	dataIndex: 'role',
			// 	key: 'role',
			// },
			{
				title: `${moduleColumnName[currentModule]['c1Name']}`,
				children: [
					{
						title: `${moduleColumnName[currentModule]['budgeted']}`,
						dataIndex: 'budgeted',
						key: 'budgeted',
					},
					{
						title: `${moduleColumnName[currentModule]['available']}`,
						dataIndex: 'available',
						key: 'available',
					},
					{
						title: `${moduleColumnName[currentModule]['vacant']}`,
						dataIndex: 'vacancy',
						key: 'vacancy',
					},
				],
			},
			{
				title: `${moduleColumnName[currentModule]['c2Name']}`,
				children: [
					{
						title: 'Planned',
						dataIndex: 'planned',
						key: 'planned',
						render: (data) => parseFloat((data / costIn).toFixed(2)),
					},
					{
						title: 'Actual',
						dataIndex: 'actual',
						key: 'actual',
						render: (data) => parseFloat((data / costIn).toFixed(2)),
					},
					{
						title: 'Unutilized',
						dataIndex: 'unutilized',
						key: 'unutilized',
						render: (data) => parseFloat((data / costIn).toFixed(2)),
					},
				],
			},
		]
		const startData = data.map((d) => {
			return { ...d, dataIndex: d?.key }
		})
		return [...startData, ...columns]
	}

	const handleFilterChange = ({ criteria, start_date, end_date }) => {
		setFilterValue({ criteria, start_date, end_date })
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<FilterForm
					dateRange={dateRange}
					setDateRange={setDateRange}
					type='workforce-report'
					onFilterChange={handleFilterChange}
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 20,
						marginTop: 20,
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Period</div>
						<DatePicker.RangePicker
							picker='month'
							format='YYYY MMM'
							value={dateRange}
							onChange={handlePeriodChange}
						/>
					</div>
					{/* <div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Status</div>
						<Select
							style={{ width: 260 }}
							placeholder='Choose Status'
							defaultValue='active'
							onChange={(value) => setStatus(value === 'all' ? '' : value)}>
							<Select.Option key='active' value='active'>
								Active
							</Select.Option>
							<Select.Option key='inactive' value='inactive'>
								Inactive
							</Select.Option>
							<Select.Option key='all' value='all'>
								All
							</Select.Option>
						</Select>
					</div> */}
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-between',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Module</div>
						<Dropdown overlay={modulesMenu} placement='bottomCenter'>
							<Button>{currentModule}</Button>
						</Dropdown>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-between',
							alignItems: 'center',
							gap: 20,
						}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: 10,
							}}>
							<div>Cost In</div>
							<Dropdown overlay={currencyMenu} placement='bottomCenter'>
								<Button>{currency}</Button>
							</Dropdown>
							<Dropdown overlay={costMenu} placement='bottomCenter'>
								<Button>{costIn}</Button>
							</Dropdown>
						</div>
					</div>
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	const { new_data } = pageState

	//todo change className
	const renderContent = () => {
		const renderTable = () => (
			<Table
				className='workforce-plan-table'
				pagination={{
					defaultPageSize: 12,
				}}
				loading={tableLoading}
				bordered
				columns={new_data ? getColumns(new_data?.criteria_header_for_report) : []}
				dataSource={new_data ? getTableDataSource(new_data?.workforce_data) : []}
				scroll={{
					x: 1200,
				}}
				style={{
					margin: '20px 0px',
				}}
			/>
		)
		return renderTable()
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(RecruitmentStaffingReportPage)
