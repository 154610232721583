import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { Row, Col, Select, Input, Button, message } from 'antd'
import { Link } from 'react-router-dom'

import StyledBox from 'components/common/styled-components/StyledBox'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'

import OrganizationsService from 'services/admin-users/organizations.service'
import CountriesService from 'services/admin-users/countries.service'
import IndustriesService from 'services/admin-users/industries.service'

const StyledPageContent = styled.div`
	& .action-buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;
	}
	& .input-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		& input:nth-child(2) {
			margin-left: 15px;
		}
		& .action-buttons {
			margin: 0px;
			& > button {
				margin: 0px 0px 0px 15px;
			}
		}
	}
`

const currencyData = [
	'AFN',
	'EUR',
	'ALL',
	'DZD',
	'USD',
	'AOA',
	'XCD',
	'ARS',
	'AMD',
	'AWG',
	'SHP',
	'AUD',
	'AZN',
	'BSD',
	'BHD',
	'BDT',
	'BBD',
	'BYN',
	'BZD',
	'XOF',
	'BMD',
	'BTN',
	'BOB',
	'BAM',
	'BWP',
	'BRL',
	'BND',
	'BGN',
	'BIF',
	'CVE',
	'KHR',
	'XAF',
	'CAD',
	'KYD',
	'NZD',
	'CLP',
	'CNY',
	'COP',
	'KMF',
	'CDF',
	'none',
	'CRC',
	'HRK',
	'CUP',
	'ANG',
	'CZK',
	'DKK',
	'DJF',
	'DOP',
	'EGP',
	'ERN',
	'SZL',
	'ETB',
	'FKP',
	'FJD',
	'XPF',
	'GMD',
	'GEL',
	'GHS',
	'GIP',
	'GTQ',
	'GGP',
	'GNF',
	'GYD',
	'HTG',
	'HNL',
	'HKD',
	'HUF',
	'ISK',
	'INR',
	'IDR',
	'XDR',
	'IRR',
	'IQD',
	'IMP',
	'ILS',
	'JMD',
	'JPY',
	'JEP',
	'JOD',
	'KZT',
	'KES',
	'KWD',
	'KGS',
	'LAK',
	'LBP',
	'LSL',
	'LRD',
	'LYD',
	'CHF',
	'MOP',
	'MKD',
	'MGA',
	'MWK',
	'MYR',
	'MVR',
	'MRU',
	'MUR',
	'MXN',
	'MDL',
	'MNT',
	'MAD',
	'MZN',
	'MMK',
	'NAD',
	'NPR',
	'NIO',
	'NGN',
	'KPW',
	'NOK',
	'OMR',
	'PKR',
	'PGK',
	'PYG',
	'PEN',
	'PHP',
	'PLN',
	'QAR',
	'RON',
	'RUB',
	'RWF',
	'WST',
	'STN',
	'SAR',
	'RSD',
	'SCR',
	'SLL',
	'SGD',
	'SBD',
	'SOS',
	'ZAR',
	'GBP',
	'KRW',
	'SSP',
	'LKR',
	'SDG',
	'SRD',
	'SEK',
	'SYP',
	'TWD',
	'TJS',
	'TZS',
	'THB',
	'TOP',
	'TTD',
	'TND',
	'TRY',
	'TMT',
	'UGX',
	'UAH',
	'AED',
	'UYU',
	'UZS',
	'VUV',
	'VES',
	'VND',
	'YER',
	'ZMW',
]

const OrganizationEditPage = (props) => {
	const { organizationId } = useParams()
	const history = useHistory()
	const pageState = globalStore.ui.adminUsers.organizations.show

	const [name, setName] = useState(null)
	const [industryId, setIndustryId] = useState(null)
	const [address, setAddress] = useState(null)
	const [payreviewOrgId, setPayreviewOrgId] = useState(null)
	const [city, setCity] = useState(null)
	const [countryId, setCountryId] = useState(null)
	const [countries, setCountries] = useState([])
	const [industries, setIndustries] = useState([])
	const [organization, setOrganization] = useState(null)
	const [updateButtonLoading, setUpdateButtonLoading] = useState(false)
	const [standardFieldCountries, setStandardFieldCountries] = useState({})
	const [resourcePlaningOrgId, setResourcePlaningOrgId] = useState(null)
	const [dataProvider, setDataProvider] = useState(null)
	const [dataProviderList, setDataProviderList] = useState([
		{ name: 'Smart Recruiters', value: 'smart_recruiters' },
	])
	const [dataProviderKey, setDataProviderKey] = useState(null)
	const [srMarketplaceApiKey, setSrMarketplaceApiKey] = useState(null)
	const [externalId, setExternalId] = useState(null)
	const [accessibleFeatures, setAccessibleFeatures] = useState()
	const [currency, setCurrency] = useState(null)
	const [enableSalaryApproval, setEnableSalaryApproval] = useState(null)
	const [enableFitmentApproval, setEnableFitmentApproval] = useState(null)
	const [enableFitmentDeletion, setEnableFitmentDeletion] = useState(null)
	const [approverRequiredForDocusign, setApproverRequiredForDocusign] = useState(null)
	const [countryForSfIntegration, setCountryForSfIntegration] = useState(null)
	useEffect(() => {
		const pageState = globalStore.ui.adminUsers.organizations.show
		const fetchData = async () => {
			await OrganizationsService.show({ pageState, organizationId })

			const { organization } = pageState

			if (organization) {
				setOrganization(organization)

				const {
					name,
					industry,
					address,
					city,
					country,
					hidden_fields_for,
					payreview_org_id,
					resource_planing_org_id,
					data_provider,
					data_provider_key,
					sr_marketplace_api_key,
					external_id,
					accessible_features,
					currency,
					enable_salary_approval,
					enable_fitment_approval,
					approver_required_for_docusign,
					enable_fitment_deletion,
					country_for_sf_integration,
				} = organization
				setName(name)
				setIndustryId(industry?.id)
				setAddress(address)
				setCity(city)
				setCountryId(country?.id)
				setStandardFieldCountries(hidden_fields_for)
				setPayreviewOrgId(payreview_org_id)
				setResourcePlaningOrgId(resource_planing_org_id)
				setDataProvider(data_provider || 'smart_recruiters')
				setDataProviderList([
					{
						name: 'Smart Recruiters',
						value: 'smart_recruiters',
					},
				])
				setDataProviderKey(data_provider_key)
				setSrMarketplaceApiKey(sr_marketplace_api_key)
				setExternalId(external_id)
				setAccessibleFeatures(accessible_features)
				setCurrency(currency)
				setEnableSalaryApproval(enable_salary_approval)
				setEnableFitmentApproval(enable_fitment_approval)
				setEnableFitmentDeletion(enable_fitment_deletion)
				setApproverRequiredForDocusign(approver_required_for_docusign)
				setCountryForSfIntegration(country_for_sf_integration)
				return
			}

			message.error("Organization data couldn't be fetched")

			history.push(`/admin-users/organizations/${organizationId}`)
		}

		fetchData()
	}, [organizationId, history])

	useEffect(() => {
		const tempState = {}

		const fetchCountries = async () => {
			await CountriesService.index({ pageState: tempState })

			const { countries } = tempState

			if (countries) {
				setCountries(countries)
			}
		}

		fetchCountries()
	}, [])

	useEffect(() => {
		const tempState = {}

		const fetchIndustries = async () => {
			await IndustriesService.index({ pageState: tempState })

			const { industries } = tempState

			if (industries) {
				setIndustries(industries)
			}
		}

		fetchIndustries()
	}, [])

	const handleNameChange = (e) => {
		setName(e.target.value)
	}

	const handleIndustryChange = (industryId) => {
		setIndustryId(industryId)
	}

	const handleAddressChange = (e) => {
		setAddress(e.target.value)
	}
	const handlePayreviewOrgIdChange = (e) => {
		setPayreviewOrgId(e.target.value)
	}

	const handleCityChange = (e) => {
		setCity(e.target.value)
	}

	const handleCountryChange = (countryId) => {
		setCountryId(countryId)
	}

	const handleResourcePlaningOrgId = (e) => {
		setResourcePlaningOrgId(e.target.value)
	}

	const handleDataProvider = (value) => {
		setDataProvider(value)
	}

	const handleDataProviderKey = (e) => {
		setDataProviderKey(e.target.value)
	}

	const handleSrMarketplaceApiKey = (e) => {
		setSrMarketplaceApiKey(e.target.value)
	}

	const handleExternalId = (e) => {
		setExternalId(e.target.value)
	}

	const handleUpdate = async () => {
		const tempState = {}
		const postData = {
			organization: {
				name,
				address,
				city,
				currency,
				country_id: countryId,
				industry_id: industryId,
				hidden_fields_for: standardFieldCountries,
				payreview_org_id: payreviewOrgId,
				resource_planing_org_id: resourcePlaningOrgId,
				data_provider: dataProvider,
				data_provider_key: dataProviderKey,
				sr_marketplace_api_key: srMarketplaceApiKey,
				external_id: externalId,
				accessible_features: accessibleFeatures,
				enable_salary_approval: enableSalaryApproval,
				enable_fitment_approval: enableFitmentApproval,
				approver_required_for_docusign: approverRequiredForDocusign,
				enable_fitment_deletion: enableFitmentDeletion,
				country_for_sf_integration: countryForSfIntegration,
			},
		}

		setUpdateButtonLoading(true)
		await OrganizationsService.update({
			pageState: tempState,
			values: postData,
			organizationId,
		})
		setUpdateButtonLoading(false)

		const { serverStatus, organization } = tempState

		if (serverStatus.status !== 200) {
			message.error('Failed to update.')
			return
		}

		if (organization) {
			setOrganization(organization)
			message.destroy()
			message.success('Organization details updated.')
			return
		}

		message.success('Updated')
	}

	const handleStandardFieldCountryChange = (key, value) => {
		const standardFieldCountriesCopy = JSON.parse(JSON.stringify(standardFieldCountries))

		setStandardFieldCountries({ ...standardFieldCountriesCopy, [key]: value })
	}

	const handleEnableSalaryApproval = (value) => {
		setEnableSalaryApproval(value)
	}

	const handleEnableFitmentApproval = (value) => {
		setEnableFitmentApproval(value)
	}
	const handleEnableFitmentDeletion = (value) => {
		setEnableFitmentDeletion(value)
	}
	const handleApproverRequiredForDocusign = (value) => {
		setApproverRequiredForDocusign(value)
	}

	const handleAccessibleFeaturesChange = (value) => {
		setAccessibleFeatures(value)
	}

	const handleCurrency = (value) => {
		setCurrency(value)
	}

	const renderCountriesOptions = () => {
		return countries.map((c) => (
			<Select.Option key={c.id} value={c.name}>
				{c.name}
			</Select.Option>
		))
	}

	const handleCreateDefaultWorkforcePlannedReason = async () => {
		const tempState = {}

		await OrganizationsService.defaultWorkforceReason({ pageState: tempState, organizationId })
		const { message: messages, organizationRolesErrors } = tempState

		if (organizationRolesErrors) {
			message.error(organizationRolesErrors)
		}
		message.success(messages)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<Row gutter={[20, 20]}>
					<Col span={12}>
						<Link to={`/admin-users/organizations/${organizationId}/api-web-services`}>API Web Service</Link>
					</Col>
					<Col span={12}>
						<Button type='link' onClick={handleCreateDefaultWorkforcePlannedReason}>
							Create Default Workforce Planned Reason
						</Button>
					</Col>
				</Row>
				<Row gutter={[20, 20]}>
					<Col span={12}>
						<div className='input-box'>
							<h1>Name</h1>
							<Input
								value={name}
								onChange={handleNameChange}
								placeholder='Enter organization name'
							/>
						</div>
					</Col>
					<Col span={12}>
						<div className='input-box'>
							<h1>Industry</h1>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder='Select industry'
								optionFilterProp='children'
								onChange={handleIndustryChange}
								value={industryId}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{industries.map((c) => (
									<Select.Option key={c.id} value={c.id}>
										{c.name}
									</Select.Option>
								))}
							</Select>
						</div>
					</Col>
				</Row>

				<div className='input-box'>
					<h1>Address</h1>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<Input value={address} placeholder='Enter address' onChange={handleAddressChange} />
						</Col>
						<Col span={6}>
							<Input value={city} placeholder='Enter city' onChange={handleCityChange} />
						</Col>
						<Col span={6}>
							<Select
								showSearch
								style={{ width: '100%' }}
								placeholder='Select country'
								optionFilterProp='children'
								value={countryId}
								onChange={handleCountryChange}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{countries.map((c) => (
									<Select.Option key={c.id} value={c.id}>
										{c.name}
									</Select.Option>
								))}
							</Select>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Accessible Features</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter Accessible Features'
									tokenSeparators={[',']}
									value={accessibleFeatures}
									onChange={handleAccessibleFeaturesChange}>
									<Select.Option key='1' value='budget'>
										Budget
									</Select.Option>
									<Select.Option key='2' value='workforce'>
										WorkForce
									</Select.Option>
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Currency</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Currency'
									value={currency || undefined}
									onChange={handleCurrency}>
									{currencyData.map((data, index) => {
										return (
											<Select.Option key={index} value={data}>
												{data}
											</Select.Option>
										)
									})}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Approver Required For Docusign</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Approver Required For Docusign'
									value={approverRequiredForDocusign}
									onChange={handleApproverRequiredForDocusign}>
									<Select.Option key='yes' value={true}>
										Yes
									</Select.Option>
									<Select.Option key='no' value={false}>
										No
									</Select.Option>
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Enable Salary Approval</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Salary Approval'
									value={enableSalaryApproval}
									onChange={handleEnableSalaryApproval}>
									<Select.Option key='yes' value={true}>
										Yes
									</Select.Option>
									<Select.Option key='no' value={false}>
										No
									</Select.Option>
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Enable Fitment Approval</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Fitment Approval'
									value={enableFitmentApproval}
									onChange={handleEnableFitmentApproval}>
									<Select.Option key='yes' value={true}>
										Yes
									</Select.Option>
									<Select.Option key='no' value={false}>
										No
									</Select.Option>
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Enable Fitment Deletion</h1>
								<Select
									showSearch
									style={{ width: '100%' }}
									placeholder='Select Fitment Deletion'
									value={enableFitmentDeletion}
									onChange={handleEnableFitmentDeletion}>
									<Select.Option key='yes' value={true}>
										Yes
									</Select.Option>
									<Select.Option key='no' value={false}>
										No
									</Select.Option>
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<br />
				<div className='input-box'>
					<h1 style={{ marginBottom: 10 }}>
						<strong>Api Configuration</strong>
					</h1>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<h1>Payreview Organization Id</h1>
							<Input
								value={payreviewOrgId}
								placeholder='Enter Payreview Organization Id'
								onChange={handlePayreviewOrgIdChange}
							/>
						</Col>
						<Col span={12}>
							<h1>Resource Planning Id</h1>

							<Input
								value={resourcePlaningOrgId}
								placeholder='Enter Resource Planning Id'
								onChange={handleResourcePlaningOrgId}
							/>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<h1>Data Provider</h1>
							<Select
								showSearch
								style={{
									width: '100%',
								}}
								placeholder='Select Data provider'
								optionFilterProp='children'
								value={dataProvider}
								onChange={handleDataProvider}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}>
								{dataProviderList.map((c, i) => (
									<Select.Option key={i} value={c.value}>
										{c.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col span={12}>
							<h1>Data Provider Key</h1>

							<Input
								value={dataProviderKey}
								placeholder='Enter Data provider key'
								onChange={handleDataProviderKey}
							/>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<h1>Smart Recruiter Market Place Api Key</h1>
							<Input
								value={srMarketplaceApiKey}
								placeholder='Enter Smart recruiter market place api key'
								onChange={handleSrMarketplaceApiKey}
							/>
						</Col>
						<Col span={12}>
							<h1>Organization External Id</h1>
							<Input
								value={externalId}
								placeholder='Enter Organization
								External Id'
								onChange={handleExternalId}
							/>
						</Col>
					</Row>
				</div>
				<br />
				<div className='input-box'>
					<h1 style={{ marginBottom: 10 }}>
						<strong>Hide following standard fields for selected countries</strong>
					</h1>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Offered fixed compensation</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.offered_fixed_compensation || []}
									onChange={(value) =>
										handleStandardFieldCountryChange('offered_fixed_compensation', value)
									}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Previous fixed compensation</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.previous_fixed_compensation || []}
									onChange={(value) =>
										handleStandardFieldCountryChange('previous_fixed_compensation', value)
									}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Rate</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.rate || []}
									onChange={(value) => handleStandardFieldCountryChange('rate', value)}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
						<Col span={12}>
							<div className='input-box'>
								<h1>Previous variable compensation</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={standardFieldCountries?.previous_variable_compensation || []}
									onChange={(value) =>
										handleStandardFieldCountryChange('previous_variable_compensation', value)
									}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div className='input-box'>
					<Row gutter={[20, 20]}>
						<Col span={12}>
							<div className='input-box'>
								<h1>Country For Sf Integration</h1>
								<Select
									mode='tags'
									style={{ width: '100%' }}
									placeholder='Enter country'
									tokenSeparators={[',']}
									value={countryForSfIntegration || []}
									onChange={(value) => setCountryForSfIntegration(value)}>
									{renderCountriesOptions()}
								</Select>
							</div>
						</Col>
					</Row>
				</div>
				<div>
					<div className='action-buttons'>
						<Button type='primary' loading={updateButtonLoading} onClick={handleUpdate}>
							Update
						</Button>
					</div>
				</div>
			</StyledPageContent>
		)
	}

	const { API_ADMIN_USERS_ORGANIZATIONS_SHOW_STATUS } = pageState

	return (
		<div className='page-content dashboard-page'>
			<StyledBox maxWidth='800px'>
				{staleWhileRevalidate({
					status: API_ADMIN_USERS_ORGANIZATIONS_SHOW_STATUS,
					data: organization,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(OrganizationEditPage)
