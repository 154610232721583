import React, { useState, useEffect } from 'react'
import { useHistory, Redirect, useParams } from 'react-router-dom'
import { view } from '@risingstack/react-easy-state'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Row, Col, Select } from 'antd'
import dayjs from 'dayjs'

import StyledBox from 'components/common/styled-components/StyledBox'
import ConditionRow from 'components/budget-analysis/ConditionRow'
import globalStore from 'store/index'

import BudgetCriteriaHeadsService from 'services/users/budget-criteria-heads.service'
import { DatePicker } from '../../../../node_modules/antd/lib/index'
import BudgetUtilizationsService from 'services/users/budget-utilizations.service'
import BudgetValuesService from 'services/users/budget-values.service'
import Insight from 'components/budget-analysis/Insight'

import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

const StyledConfigurationFormBox = styled.div`
	& .input-box {
		margin: 10px 0px;
	}
	& .action-buttons.submit {
		margin-top: 20px;
	}
`

const blankCondition = {
	field: null,
	operator: null,
	values: null,
}

const BudgetUtilizationsNewPage = () => {
	const history = useHistory()
	const [criteria, setCriteria] = useState([[blankCondition]])
	const { budgetHeadId } = useParams()
	const [inputValues, setInputValues] = useState({})
	const [budgetCriteriaHeads, setBudgetCriteriaHeads] = useState([[]])
	const [rate, setRate] = useState(1)
	const [count, setCount] = useState(1)
	const [total, setTotal] = useState(0)
	const [frequency, setFrequency] = useState('one_time')
	const [annualizedRate, setAnnualizedRate] = useState(0)
	const [proportioned, setProportioned] = useState(1)
	const dateFormat = 'DD-MMM-YYYY'
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [insight, setInsight] = useState('')
	const [insightStatus, setInsightStatus] = useState('')
	const [currency, setCurrency] = useState()
	const [criteriaChildren, setCriteriaChildren] = useState([])

	const enableAllocationFeature = globalStore?.currentOrganization?.enable_allocation_feature

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		if (criteriaChildren.length) {
			const updateCriteriaData = criteria[0].map((data) => {
				if (criteriaChildren.some((items) => items.name === data.field)) {
					return { ...data, values: null, operator: null }
				}
				return data
			})
			setCriteria([updateCriteriaData])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteriaChildren])

	useEffect(() => {
		setCurrency(globalStore.currentOrganization.currency)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [globalStore.currentOrganization.currency])

	useEffect(() => {
		const pageState = globalStore.ui.configuration.budgetCriteriaHeads.criteria_head_list

		const fetchAllFieldsData = async () => {
			await BudgetCriteriaHeadsService.criteriaHeadList({ pageState })
			const { budget_criteria_heads } = pageState

			if (!budget_criteria_heads) {
				message.error('Budget criteria not found')
				return
			}
			setBudgetCriteriaHeads(budget_criteria_heads)
		}

		fetchAllFieldsData()
	}, [])

	useEffect(() => {
		const fetchBudgetValues = async () => {
			const pageState = globalStore.ui.configuration.budgetValues.index

			await BudgetValuesService.index({ pageState, budgetHeadId })

			const { serverStatus, budgetValuesErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (budgetValuesErrors) {
				message.error(budgetValuesErrors[0] || "Couldn't fetch Budget Values")
				return
			}
		}

		fetchBudgetValues()
	}, [budgetHeadId])

	const handleInputChange = (value, inputType) => {
		let newInputValues = null

		if (inputType === 'frequency') {
			newInputValues = { ...inputValues, [inputType]: value }
			setFrequency(value)
			setAnnualizedRate(getAnnualizedRate(rate, value))
			// getInsights(rate, count, total)
			setTotal(getTotal(count, rate * getFrequencyDays(value), proportioned, value))
		} else {
			newInputValues = { ...inputValues, [inputType]: value }
		}

		if (inputType === 'rate') {
			setRate(value)
			setTotal(getTotal(count, getAnnualizedRate(value, frequency), proportioned, frequency))
			setAnnualizedRate(getAnnualizedRate(value, frequency))
			// getInsights(value, count, value * count)
		}

		if (inputType === 'unit') {
			setCount(value)
			setTotal(getTotal(value, annualizedRate, proportioned, frequency))
		}

		if (inputType === 'total_value') {
			setTotal(value)
			setRate(value / count)
			setAnnualizedRate(getAnnualizedRate(value / count, frequency))
			// getInsights(value/count, count, value)
		}

		if (inputType === 'currency') {
			setCurrency(value)
		}

		setInputValues(newInputValues)
	}

	const handleDateChange = (range) => {
		if (range) {
			setDateRange([range[0], range[1]])

			setProportioned(getProportionedFactor(range[0], range[1]))

			setInputValues({
				...inputValues,
				start_date: dateRange[0],
				end_date: dateRange[1],
			})
			setTotal(
				getTotal(count, annualizedRate, getProportionedFactor(range[0], range[1]), frequency)
			)
		}
	}

	const getTotal = (count, annualizedRate, proportioned, frequency) => {
		if (frequency === 'one_time') {
			return count * annualizedRate
		} else {
			return count * annualizedRate * proportioned
		}
	}

	const getProportionedFactor = (startDate, endDate) => {
		return Math.round((getPeriodDays(startDate, endDate) / 365) * 100) / 100
	}

	const getAnnualizedRate = (rate, frequency) => {
		return rate * getFrequencyDays(frequency)
	}

	const getFrequencyDays = (frequency) => {
		if (frequency === 'daily') {
			return 365.0
		} else if (frequency === 'half_yearly') {
			return 2 // 2.027
		} else if (frequency === 'quarterly') {
			return 4 // 4.05
		} else if (frequency === 'monthly') {
			return 12 // 12.16
		} else if (frequency === 'weekly') {
			return 52 // 52.14
		} else if (frequency === 'annually') {
			return 1
		} else if (frequency === 'one_time') {
			return 1
		}
	}

	const getPeriodDays = (startDate, endDate) => {
		const oneDay = 24 * 60 * 60 * 1000
		const days = Math.round(Math.abs((endDate - startDate) / oneDay))
		return days + 1
	}

	const handleAddNewCondition = (conditionSetIndex, parentConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(parentConditionIndex + 1, 0, blankCondition)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleRemoveCondition = (conditionSetIndex, deletedConditionIndex) => {
		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				newConditionSet.splice(deletedConditionIndex, 1)
				return newConditionSet
			})

			return newCriteria
		})
	}

	const handleCreateConditionValues = ({ conditionSetIndex, conditionIndex, condition }) => {
		if (!condition) return
		if (!condition.values) return

		setCriteria((prevCriteria) => {
			const newCriteria = prevCriteria.map((conditionSet, conSetIndex) => {
				if (conSetIndex !== conditionSetIndex) return conditionSet
				const newConditionSet = [...conditionSet]
				return newConditionSet.map((c, index) => {
					if (index !== conditionIndex) return c

					return condition
				})
			})

			return newCriteria
		})
	}

	const handleValidate = async () => {
		inputValues['currency'] = currency
		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_value'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency
		inputValues['annualized_rate'] = annualizedRate

		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}
		if (!inputValues['frequency']) {
			message.error("Frequency can't be blank")
			return
		}

		const pageState = {}
		const postData = {
			budget_utilization: {
				...inputValues,
			},
		}

		await BudgetUtilizationsService.validateActualBudget({
			pageState,
			values: postData,
			budgetHeadId: budgetHeadId,
		})

		const { serverStatus, insight, insightErrors } = pageState

		if (insightErrors) {
			setInsight(insightErrors)
			setInsightStatus('error')
			// message.error(budgetUtilizationErrors || "Actual Budget couldn't be created.")
			return
		}
		const insightMessage = insight?.message
		setInsight(insightMessage ? insightMessage : 'Go Ahead')
		setInsightStatus(insight?.status)

		if (serverStatus.status === 200) {
			return
		}

		message.error('Oops!! something went wrong')
	}

	const handleCreate = async () => {
		inputValues['currency'] = currency
		inputValues['rate'] = rate
		inputValues['unit'] = count
		inputValues['total_value'] = total
		inputValues['start_date'] = dateRange[0].format()
		inputValues['end_date'] = dateRange[1].format()
		inputValues['frequency'] = frequency

		if (!inputValues['rate']) {
			message.error("Rate can't be blank")
			return
		}
		if (!inputValues['unit']) {
			message.error("Unit can't be blank")
			return
		}
		if (!inputValues['start_date']) {
			message.error("Start Date can't be blank")
			return
		}
		if (!inputValues['end_date']) {
			message.error("End Date can't be blank")
			return
		}
		if (!inputValues['frequency']) {
			message.error("Frequency can't be blank")
			return
		}

		const pageState = {}
		const postData = {
			budget_utilization: {
				criteria: criteria.filter((c) => c.length > 0),
				...inputValues,
			},
		}

		await BudgetUtilizationsService.create({
			pageState,
			values: postData,
			budgetHeadId: budgetHeadId,
		})

		const { serverStatus, budgetUtilizationErrors } = pageState
		// setInsight(budget_utilization.insight_message)
		// setInsightStatus(budget_utilization.insight_status)

		if (budgetUtilizationErrors) {
			// message.error(budgetUtilizationErrors || "Actual Budget couldn't be created.")
			return
		}

		if (serverStatus.status === 200) {
			message.success('Actual Budget created.')
			setCriteria([[blankCondition]])
			setInputValues({})
			history.push(`/configuration/budget-heads/${budgetHeadId}/budget-utilizations`)
			return
		}

		message.error('Oops!! something went wrong')
	}

	const renderPageContent = () => {
		// if (showInsight) {
		//   return (
		//     <Insight insight={insight} insightStatus={insightStatus} />
		//   )
		// }

		return (
			<StyledConfigurationFormBox>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<h1>Actual Value for 'Budget'</h1>
				</div>
				<Row gutter={20}>
					<Col span={16}>
						<div className='input-box'>
							<div>
								<strong>
									Period <span style={{ color: 'red' }}>*</span>
								</strong>
							</div>
							<DatePicker.RangePicker
								format={dateFormat}
								defaultValue={dateRange}
								onChange={handleDateChange}
							/>
						</div>
					</Col>
					<Col span={8} pull={1}>
						<div className='input-box'>
							<strong>Proportioned Factor</strong>
							<Input placeholder='Proportioned Factor' value={proportioned} disabled={true} />
						</div>
					</Col>
					<Col span={2}>
						<div className='input-box'>
							<strong>Currency</strong>
							<Input
								placeholder='Currency'
								value={currency}
								onChange={(e) => handleInputChange(e.target.value, 'currency')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>
								Count <span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Count'
								value={count || inputValues['unit']}
								onChange={(e) => handleInputChange(e.target.value, 'unit')}
							/>
						</div>
					</Col>
					<Col span={5}>
						<div className='input-box'>
							<strong>
								Rate
								<span style={{ color: 'red' }}>*</span>
							</strong>
							<Input
								placeholder='Enter Rate'
								value={rate || inputValues['rate']}
								onChange={(e) => handleInputChange(e.target.value, 'rate')}
							/>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>
								Frequency <span style={{ color: 'red' }}>*</span>
							</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose frequency'
								defaultValue={frequency}
								onChange={(value) => handleInputChange(value, 'frequency')}>
								<Select.Option value='daily'>Daily</Select.Option>
								<Select.Option value='weekly'>Weekly</Select.Option>
								<Select.Option value='monthly'>Monthly</Select.Option>
								<Select.Option value='quarterly'>Quarterly</Select.Option>
								<Select.Option value='half_yearly'>Half Yearly</Select.Option>
								<Select.Option value='annually'>Annually</Select.Option>
								<Select.Option value='one_time'>One Time</Select.Option>
							</Select>
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Annualised Rate</strong>
							<Input placeholder='Annualised Rate' value={annualizedRate} disabled={true} />
						</div>
					</Col>
					<Col span={4}>
						<div className='input-box'>
							<strong>Total Cost</strong>
							<Input
								placeholder='Total Cost'
								value={total || inputValues['total_value']}
								onChange={(e) => handleInputChange(e.target.value, 'total_value')}
							/>
						</div>
					</Col>
				</Row>
				{/* <Row gutter={20}>
					<Col span={6}>
						<div className='input-box'>
							<strong>Position Type</strong>
							<Select
								style={{ display: 'block' }}
								placeholder='Choose Position Type'
								onChange={(value) => handleInputChange(value, 'position_type')}>
								<Select.Option value='replacement'>Replacement</Select.Option>
								<Select.Option value='new'>New</Select.Option>
							</Select>
						</div>
					</Col>
				</Row> */}
				{enableAllocationFeature && (
					<Row gutter={20}>
						<Col span={6}>
							<div className='input-box'>
								<strong>Allocation Percentage</strong>
								<Input
									placeholder='Enter Allocation Percentage'
									value={inputValues['allocation_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'allocation_percentage')}
								/>
							</div>
						</Col>
						<Col span={6}>
							<div className='input-box'>
								<strong>Increment Percentage</strong>
								<Input
									placeholder='Enter Increment Percentage'
									value={inputValues['increment_percentage']}
									onChange={(e) => handleInputChange(e.target.value, 'increment_percentage')}
								/>
							</div>
						</Col>
					</Row>
				)}
				<div className='input-box'>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}>
						<strong>Criteria</strong>
						{/* <Col span={20} style={{marginLeft: "95px"}}><strong>Symbol</strong></Col>
            <Col span={20}><strong>Value</strong></Col> */}
						{criteria.filter((c) => c.length > 0).length === 0 && (
							<Button shape='circle' onClick={() => setCriteria([[blankCondition]])}>
								<PlusOutlined />
							</Button>
						)}
					</div>
					{criteria.map((conditionSet, conditionSetIndex) => {
						return (
							<div key={conditionSetIndex}>
								{conditionSet.map((condition, conditionIndex) => {
									return (
										<ConditionRow
											criteria={criteria}
											clearChildren={setCriteriaChildren}
											condition={condition}
											key={conditionIndex}
											conditionIndex={conditionSetIndex}
											conditionSetLength={conditionSet.length}
											lastInputGroup={conditionSet.length === conditionIndex + 1}
											firstInputGroup={conditionIndex === 0}
											inputGroupIndex={conditionIndex}
											updateConditionValues={(condition) =>
												handleCreateConditionValues({
													conditionSetIndex,
													conditionIndex,
													condition,
												})
											}
											addNewCondition={() =>
												handleAddNewCondition(conditionSetIndex, conditionIndex)
											}
											removeCondition={() =>
												handleRemoveCondition(conditionSetIndex, conditionIndex)
											}
											budgetCriteriaHeads={budgetCriteriaHeads}
										/>
									)
								})}
							</div>
						)
					})}
				</div>
				<div className='action-buttons submit'>
					<Button type='primary' onClick={handleCreate}>
						Save
					</Button>
					<Button type='link' onClick={handleValidate}>
						Validate
					</Button>
				</div>
				<Insight insight={insight} insightStatus={insightStatus} />
			</StyledConfigurationFormBox>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['criteria-edit', 'budget-data-edit'].every((featureKey) =>
			accessibleFeatures.includes(featureKey)
		)
	)
		return <Redirect to='/configuration/minimum-wages/' />

	return (
		<div className='page-content dashboard-page'>
			<StyledBox>{renderPageContent()}</StyledBox>
		</div>
	)
}

export default view(BudgetUtilizationsNewPage)
