import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import { message } from 'antd'
import { TreeTable, TreeState } from 'cp-react-tree-table'

import globalStore from 'store/index'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { staleWhileRevalidate } from 'utils/render-strategies'
import StyledBox from 'components/common/styled-components/StyledBox'
import FilterForm from 'components/budget-analysis/FilterForm'
import { changeTitle, setSmartBudgetsFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const BudgetAnalysisPage = (props) => {
	const [treeValue, setTreeValue] = useState([])
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [period, setPeriod] = useState()
	const pageState = globalStore.ui.configuration.budgetHeads.budget_analysis

	//Change title and favicon for SmartBudgets
	useEffect(() => {
		changeTitle(LABELS.smartBudgetPageTitle)
		setSmartBudgetsFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		const fetchBudgetReports = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.budget_analysis
			await BudgetHeadsService.budget_analysis({ pageState })

			const { serverStatus, dataErrors } = pageState

			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "Budget heads couldn't be fetched.")
				return
			}
			setTreeValue(TreeState.create(pageState.data))
		}

		fetchBudgetReports()
	}, [])

	useEffect(() => {
		if (!Array.isArray(pageState.data)) return
		setTreeValue(TreeState.create(pageState.data))
	}, [pageState.data])

	const handleOnChange = (newValue) => {
		setTreeValue(newValue)
	}

	const renderIndexCell = (row) => {
		return (
			<div
				style={{ paddingLeft: row.metadata.depth * 15 + 'px' }}
				className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>
				{row.metadata.hasChildren ? (
					<button className='toggle-button' onClick={row.toggleChildren}>
						<span className={'toggle-button-icon'}>
							{row.$state.isExpanded ? (
								<CaretDownOutlined style={{ color: '#7614a7' }} />
							) : (
								<CaretRightOutlined />
							)}
						</span>
					</button>
				) : (
					''
				)}

				<span>{row.data.name}</span>
			</div>
		)
	}

	const renderPlannedCell = (row) => {
		return <span>{row.data.planned_value}</span>
	}

	const renderActualCell = (row) => {
		return <span>{row.data.actual_value}</span>
	}

	const renderAmountCell = (row) => {
		return <span>{row.data.amount}</span>
	}

	const renderPercentageCell = (row) => {
		return <span style={{ textAlign: 'right' }}>{row.data.deviation_percentage}</span>
	}

	const { API_USERS_BUDGET_HEADS_ANALYSIS_STATUS } = pageState

	const renderList = () => {
		return (
			<div>
				<div>
					<FilterForm
						type='budget-analysis'
						dateRange={dateRange}
						setDateRange={setDateRange}
						setPeriod={setPeriod}
					/>
				</div>
				{period && (
					<div style={{ padding: '0px 0px 4px 0px', color: 'black' }}>
						<strong>
							Period : {moment(dateRange[0]).format('DD-MMM-YYYY')} -
							{moment(dateRange[1]).format('DD-MMM-YYYY')}
						</strong>
					</div>
				)}
				<TreeTable
					className='budget-analysis-table'
					value={treeValue}
					onChange={(e) => handleOnChange(e)}>
					<TreeTable.Column
						basis='200px'
						grow='0.9'
						renderCell={renderIndexCell}
						renderHeaderCell={() => (
							<span style={{ textAlign: 'start', width: '100%' }}>Budget Name</span>
						)}
					/>
					<TreeTable.Column
						renderCell={renderPlannedCell}
						renderHeaderCell={() => (
							<span style={{ textAlign: 'end', width: '100%' }}>Planned Value</span>
						)}
					/>
					<TreeTable.Column
						renderCell={renderActualCell}
						renderHeaderCell={() => (
							<span style={{ textAlign: 'end', width: '100%' }}>Actual Value</span>
						)}
					/>
					<TreeTable.Column
						renderCell={renderAmountCell}
						renderHeaderCell={() => (
							<span style={{ textAlign: 'end', width: '100%' }}>Unutilized Amount</span>
						)}
					/>
					<TreeTable.Column
						renderCell={renderPercentageCell}
						renderHeaderCell={() => (
							<span style={{ textAlign: 'center', width: '100%' }}>Utilization %</span>
						)}
					/>
				</TreeTable>
			</div>
		)
	}

	const renderPageContent = () => {
		return <div className='content'>{renderList()}</div>
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (
		accessibleFeatures.length &&
		!['smart-budget'].every((featureKey) => accessibleFeatures.includes(featureKey))
	)
		return <Redirect to='/' />

	return (
		<div className='page-content budgets-page'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_BUDGET_HEADS_ANALYSIS_STATUS,
					data: treeValue,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(BudgetAnalysisPage)
