import React, { useEffect } from 'react'
import { view } from '@risingstack/react-easy-state'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { message, Button } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'

import { staleWhileRevalidate, networkOnly } from 'utils/render-strategies'
import globalStore from 'store/index'
import { Color } from 'constants/theme'
import WebService from 'services/admin-users/web-services.service'

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
		& > button {
			margin-right: 10px;
		}
	}
`

const StyledItemList = styled.div`
	& > div {
		margin-bottom: 50px;
	}

	& .item-row {
		display: flex;
		margin-bottom: 5px;
		& > div {
			flex: auto;
			min-height: 42px;
			border-radius: 5px;
			margin: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: ${Color.secondaryColor};
			background: white;
			justify-content: flex-start;
			padding: 5px 15px;
		}
		& > div:first-child {
			max-width: 250px;
			justify-content: flex-end;
			text-transform: uppercase;
			font-weight: bold;
			background: ${Color.secondaryColor};
			color: ${Color.textColor};
			// box-shadow: 0px 5px 20px #0000000d;
		}
	}
	& .colored.item-row {
		& div:last-child {
			background: #f9f3ff;
		
	}
`

const WebServiceShowPage = (props) => {
	const pageState = globalStore.ui.adminUsers.webServices.show
	const history = useHistory()
	const { webServiceId, organizationId } = useParams()

	useEffect(() => {
		const fetchMinimumWage = async () => {
			const pageState = globalStore.ui.adminUsers.webServices.show

			await WebService.show({
				pageState,
				webServiceId,
				organizationId,
			})

			const { serverStatus, apiLogConfigurationErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (apiLogConfigurationErrors) {
				message.error(apiLogConfigurationErrors[0] || "Api Logs couldn't be fetched.")
				return
			}
		}

		if (organizationId) fetchMinimumWage()
	}, [organizationId, webServiceId])

	const handleEdit = () => {
		history.push(`${webServiceId}/edit`)
	}
	const { web_service, API_USERS_WEB_SERVICE_SHOW_STATUS } = pageState || {}

	const renderContent = () => {
		if (!web_service) return null

		const { name, type, status, web_service_url, service_type } = web_service
		return (
			<div>
				<h1>{name}</h1>
				<StyledItemList>
					<div>
						<div className='item-row colored'>
							<div className='label'>Log Type</div>
							<div>{type}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Status</div>
							<div>{status}</div>
						</div>
						<div className='item-row colored'>
							<div className='label'>Service Type</div>
							<div>{service_type}</div>
						</div>
						<div className='item-row colored'>
							<div className='label' style={{ whiteSpace: 'nowrap' }}>
								Web Service Url
							</div>
							<div style={{ wordBreak: 'break-all' }}>{web_service_url}</div>
						</div>
					</div>
				</StyledItemList>
			</div>
		)
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div className='action-buttons top'>
					<Button type='primary' ghost onClick={handleEdit}>
						Edit
					</Button>
				</div>
				<div className='content'>{renderContent()}</div>
			</StyledPageContent>
		)
	}

	// const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	// if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('fitment-data-view'))
	// 	return <Redirect to='/configuration/api-logs' />

	const strategy =
		web_service && webServiceId === web_service.id ? staleWhileRevalidate : networkOnly

	return (
		<div className='page-content api-logs-page'>
			<StyledBox>
				{strategy({
					status: API_USERS_WEB_SERVICE_SHOW_STATUS,
					data: web_service,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(WebServiceShowPage)
