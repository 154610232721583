import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { Table, Select, DatePicker, Switch, Tooltip, message } from 'antd'

import StyledBox from 'components/common/styled-components/StyledBox'
import FilterForm from 'components/budget-analysis/FilterForm'
import { staleWhileRevalidate } from 'utils/render-strategies'
import globalStore from 'store/index'
import { changeTitle, setWorkforceFavicon, setHireReviewFavicon } from 'utils/header-meta'
import LABELS from 'constants/labels'
import BudgetHeadsService from 'services/users/budget-heads.service'
import { view } from '@risingstack/react-easy-state'
import { Redirect } from 'react-router-dom'
import { getCurrentFiscalDateRange } from 'utils/dateRange'

const { Column, ColumnGroup } = Table

const showOnlyColumnsOptions = ['monthly', 'quartely', 'halfyearly', 'yearly']

const StyledPageContent = styled.div`
	& > .action-buttons.top {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 20px;
	}
`
const IncrementalChangeTimelinePage = () => {
	const [dateRange, setDateRange] = useState(getCurrentFiscalDateRange)
	const [showOnlyColumns, setShowOnlyColumns] = useState(showOnlyColumnsOptions)
	// const [tableDataSource, setTableDataSource] = useState([])
	const [valueType, setValueType] = useState(false)
	const [tableLoading, setTableLoading] = useState(false)
	const [count, setCount] = useState(false)
	const [filterValue, setFilterValue] = useState({})
	const wfmCurrentPeriod = globalStore.ui.configuration.currentWorkforcePeriod
	const pageState = globalStore.ui.configuration.budgetHeads.workForceTimeline
	//Change title and favicon for Workforce
	useEffect(() => {
		changeTitle(LABELS.workforcePageTitle)
		setWorkforceFavicon()

		return () => {
			changeTitle(LABELS.defaultPageTitle)
			setHireReviewFavicon()
		}
	}, [])

	useEffect(() => {
		globalStore.siderCollapsed = true

		return () => {
			globalStore.siderCollapsed = false
		}
	}, [])

	useEffect(() => {
		if (wfmCurrentPeriod.workforce_period) {
			const startDate = wfmCurrentPeriod?.workforce_period?.start_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.start_date)
				: moment.utc().set('month', 3)
			const endDate = wfmCurrentPeriod?.workforce_period?.end_date
				? moment.utc(wfmCurrentPeriod?.workforce_period?.end_date)
				: moment.utc().set('month', 4).add('years', 1)
			setDateRange([startDate, endDate])
		}
	}, [wfmCurrentPeriod, wfmCurrentPeriod.workforce_period])

	const defaultPeriod = [moment.utc().set('month', 3), moment.utc().set('month', 4).add('years', 1)]
	useEffect(() => {
		const fetchWorkForceTimeline = async () => {
			const pageState = globalStore.ui.configuration.budgetHeads.workForceTimeline
			const { criteria } = filterValue

			const postData = {
				criteria: criteria?.criteria,
				value_type: !valueType ? 'planned' : 'actual',
				start_date: dateRange
					? dateRange[0].startOf('month').format()
					: defaultPeriod[0].startOf('month').format(),
				end_date: dateRange
					? dateRange[1].endOf('month').format()
					: defaultPeriod[1].endOf('month').format(),
			}
			setTableLoading(true)
			await BudgetHeadsService.workforceTimeline({ pageState, postData })
			setTableLoading(false)
			const { serverStatus, dataErrors } = pageState
			if (serverStatus.status !== 200) {
				message.error('Oops!! something went wrong')
				return
			}

			if (dataErrors) {
				message.error(dataErrors[0] || "WorkForce Timeline criteria couldn't be fetched.")
				return
			}
		}
		fetchWorkForceTimeline()
		// setTableDataSource(data)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateRange, valueType])

	const { workforce_timeline_data, API_USERS_WORKFORCE_TIMELINE_STATUS } = pageState

	const handleCountSwitch = (value) => {
		setCount(value)
	}
	const handlePlannedSwitch = (value) => {
		setValueType(value)
	}

	const tableDataSource = () => {
		const tableData = workforce_timeline_data?.timeline_data?.map((timelineData, index) => {
			const mainData = timelineData.data
			const yearOne =
				mainData[dateRange ? dateRange[0].format('YYYY') : defaultPeriod[0].format('YYYY')]
			const yearTwo =
				mainData[dateRange ? dateRange[1].format('YYYY') : defaultPeriod[1].format('YYYY')]

			const lastYearPlannedCount = parseFloat(
				!count ? mainData?.last_year_planned_count : mainData?.last_year_planned_cost
			)

			const apr = (!count ? yearOne?.apr?.total_value : yearOne?.apr?.display_total_cost) || 0
			const may = (!count ? yearOne?.may?.total_value : yearOne?.may?.display_total_cost) || 0
			const jun = (!count ? yearOne?.jun?.total_value : yearOne?.jun?.display_total_cost) || 0
			const jul = (!count ? yearOne?.jul?.total_value : yearOne?.jul?.display_total_cost) || 0
			const aug = (!count ? yearOne?.aug?.total_value : yearOne?.aug?.display_total_cost) || 0
			const sep = (!count ? yearOne?.sep?.total_value : yearOne?.sep?.display_total_cost) || 0
			const oct = (!count ? yearOne?.oct?.total_value : yearOne?.oct?.display_total_cost) || 0
			const nov = (!count ? yearOne?.nov?.total_value : yearOne?.nov?.display_total_cost) || 0
			const dec = (!count ? yearOne?.dec?.total_value : yearOne?.dec?.display_total_cost) || 0

			const jan = (!count ? yearTwo?.jan?.total_value : yearTwo?.jan?.display_total_cost) || 0
			const feb = (!count ? yearTwo?.feb?.total_value : yearTwo?.feb?.display_total_cost) || 0
			const mar = (!count ? yearTwo?.mar?.total_value : yearTwo?.mar?.display_total_cost) || 0

			const mayTotal = !count
				? lastYearPlannedCount + may
				: yearOne?.may?.display_total_cost_with_last_year
			const aprTotal = !count
				? lastYearPlannedCount + apr
				: yearOne?.apr?.display_total_cost_with_last_year
			const junTotal = !count
				? lastYearPlannedCount + jun
				: yearOne?.jun?.display_total_cost_with_last_year
			const julTotal = !count
				? lastYearPlannedCount + jul
				: yearOne?.jul?.display_total_cost_with_last_year
			const augTotal = !count
				? lastYearPlannedCount + aug
				: yearOne?.aug?.display_total_cost_with_last_year
			const sepTotal = !count
				? lastYearPlannedCount + sep
				: yearOne?.sep?.display_total_cost_with_last_year
			const octTotal = !count
				? lastYearPlannedCount + oct
				: yearOne?.oct?.display_total_cost_with_last_year
			const novTotal = !count
				? lastYearPlannedCount + nov
				: yearOne?.nov?.display_total_cost_with_last_year
			const decTotal = !count
				? lastYearPlannedCount + dec
				: yearOne?.dec?.display_total_cost_with_last_year
			const janTotal = !count
				? lastYearPlannedCount + jan
				: yearTwo?.jan?.display_total_cost_with_last_year
			const febTotal = !count
				? lastYearPlannedCount + feb
				: yearTwo?.feb?.display_total_cost_with_last_year
			const marTotal = !count
				? lastYearPlannedCount + mar
				: yearTwo?.mar?.display_total_cost_with_last_year

			const q1 = (!count ? yearOne?.amj?.total_value : yearOne?.amj?.display_total_cost) || 0
			const q2 = (!count ? yearOne?.jas?.total_value : yearOne?.jas?.display_total_cost) || 0
			const q3 = (!count ? yearOne?.ond?.total_value : yearOne?.ond?.display_total_cost) || 0
			const q4 = (!count ? yearTwo?.jfm?.total_value : yearTwo?.jfm?.display_total_cost) || 0

			const firstHalfYear =
				(!count ? yearOne?.first_half?.total_value : yearOne?.first_half?.display_total_cost) || 0

			const secondHalfYear =
				(!count ? yearOne?.second_half?.total_value : yearOne?.second_half?.display_total_cost) || 0

			const year =
				(!count ? yearTwo?.yearly?.total_value : yearTwo?.yearly?.display_total_cost) || 0

			const data = {
				key: index,
				last_year_position: lastYearPlannedCount,
				apr: [apr, aprTotal],
				may: [may, mayTotal],
				jun: [jun, junTotal],
				q1: q1,
				jul: [jul, julTotal],
				aug: [aug, augTotal],
				sep: [sep, sepTotal],
				q2: q2,
				firstHalfYear: firstHalfYear,
				oct: [oct, octTotal],
				nov: [nov, novTotal],
				dec: [dec, decTotal],
				q3: q3,
				jan: [jan, janTotal],
				feb: [feb, febTotal],
				mar: [mar, marTotal],
				q4: q4,
				secondHalfYear: secondHalfYear,
				year: year,
			}

			const returnData = {
				...mainData,
				...data,
			}
			return returnData
		})
		return tableData
	}

	const renderSeparatedData = (value) => {
		if (!value?.length) {
			return '0 | 0'
		}
		return value?.join(' | ')
	}

	const renderContents = () => {
		const renderTable = () => {
			if (showOnlyColumnsOptions.length <= 0) {
				return
			}

			return (
				<Table
					dataSource={tableDataSource()}
					pagination={{
						defaultPageSize: 12,
					}}
					bordered
					loading={tableLoading}
					scroll={{
						x: 'max-content',
					}}
					className='incremental-timeline'
					style={{
						margin: '20px 0px',
					}}>
					{workforce_timeline_data?.criteria_header_for_report &&
						workforce_timeline_data?.criteria_header_for_report.map((data, index) => (
							<Column key={index} title={data?.title} dataIndex={data?.key} fixed={true} />
						))}

					<Column
						title='Last Year Position'
						dataIndex='last_year_position'
						key='last_year_position'
						fixed={true}
					/>
					{(showOnlyColumns.includes('monthly') || showOnlyColumns.includes('quartely')) && (
						<ColumnGroup title='H1(Apr-Sep)' className='H1'>
							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q1(AMJ)'>
									<ColumnGroup title='Apr'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='apr'
											key='apr'
											render={renderSeparatedData}
											style={{ background: 'red' }}
										/>
									</ColumnGroup>
									<ColumnGroup title='May'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='may'
											key='may'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Jun'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='jun'
											key='jun'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q1' key='q1' className='colored-bg' />
							)}

							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q2(JAS)'>
									<ColumnGroup title='Jul' dataIndex='jul' key='jul'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='jul'
											key='jul'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Aug' dataIndex='aug' key='aug'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='jul'
											key='jul'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Sep' dataIndex='sep' key='sep'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='jul'
											key='jul'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q2' key='q2' className='colored-bg' />
							)}
						</ColumnGroup>
					)}
					{showOnlyColumns.includes('halfyearly') && (
						<Column
							title='H1 Yearly Total'
							dataIndex='firstHalfYear'
							key='firstHalfYear'
							className='colored-bg'
						/>
					)}
					{(showOnlyColumns.includes('monthly') || showOnlyColumns.includes('quartely')) && (
						<ColumnGroup title='H2(Oct-Mar)' className='H2'>
							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q3(OND)'>
									<ColumnGroup title='Oct'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='oct'
											key='oct'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Nov'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='nov'
											key='nov'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Dec'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='dec'
											key='dec'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q3' key='q3' className='colored-bg' />
							)}

							{showOnlyColumns.includes('monthly') && (
								<ColumnGroup title='Q4(JFM)'>
									<ColumnGroup title='Jan'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='jan'
											key='jan'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Feb'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='feb'
											key='feb'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
									<ColumnGroup title='Mar'>
										<Column
											title={
												<span>
													<Tooltip title='Delta'>Δ</Tooltip>
													&nbsp;|&nbsp;
													<Tooltip title='Total'>Σ</Tooltip>
												</span>
											}
											dataIndex='mar'
											key='mar'
											render={renderSeparatedData}
										/>
									</ColumnGroup>
								</ColumnGroup>
							)}

							{showOnlyColumns.includes('quartely') && (
								<Column title='Total' dataIndex='q4' key='q4' className='colored-bg' />
							)}
						</ColumnGroup>
					)}
					{showOnlyColumns.includes('halfyearly') && (
						<Column
							title='H2 Yearly Total'
							dataIndex='secondHalfYear'
							key='secondHalfYear'
							className='colored-bg'
						/>
					)}
					{showOnlyColumns.includes('yearly') && (
						<Column title='Year' dataIndex='year' key='year' className='colored-bg-dark' />
					)}
				</Table>
			)
		}

		return <div>{renderTable()}</div>
	}

	const handleFilterChange = ({ criteria, start_date, end_date }) => {
		setFilterValue({ criteria, start_date, end_date })
	}

	const renderPageContent = () => {
		return (
			<StyledPageContent>
				<div>
					<FilterForm
						dateRange={dateRange}
						setDateRange={setDateRange}
						type='workforce-timeline'
						onFilterChange={handleFilterChange}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginBottom: 20,
						marginTop: 20,
					}}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Period</div>
						<DatePicker.RangePicker
							value={dateRange}
							picker='month'
							format='YYYY MMM'
							onChange={setDateRange}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Count</div>
						<Switch checked={count} onChange={handleCountSwitch} />
						<div>Cost</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: 10,
						}}>
						<div>Planned</div>
						<Switch checked={valueType} onChange={handlePlannedSwitch} />
						<div>Actual</div>
					</div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-between',
							alignItems: 'center',
							gap: 20,
						}}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								alignItems: 'center',
								gap: 10,
							}}>
							<div>Show only</div>
							<div>
								<Select
									mode='multiple'
									defaultValue={showOnlyColumns}
									style={{ minWidth: 350 }}
									onChange={setShowOnlyColumns}>
									{showOnlyColumnsOptions.map((item) => (
										<Select.Option key={item} value={item}>
											{item}
										</Select.Option>
									))}
								</Select>
							</div>
						</div>
						{/* <div>
							<Button type='primary' ghost>
								Export
							</Button>
						</div> */}
					</div>
				</div>
				<div className='content'>{renderContents()}</div>
			</StyledPageContent>
		)
	}

	const accessibleFeatures = globalStore.currentUser?.accessible_features || []

	if (accessibleFeatures?.length > 0 && !accessibleFeatures.includes('workforce-report'))
		return <Redirect to='/' />

	return (
		<div className='page-content incremental-timeline'>
			<StyledBox>
				{staleWhileRevalidate({
					status: API_USERS_WORKFORCE_TIMELINE_STATUS,
					data: workforce_timeline_data?.criteria_header_for_report,
					render: renderPageContent,
				})}
			</StyledBox>
		</div>
	)
}

export default view(IncrementalChangeTimelinePage)
